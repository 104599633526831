const ApiAdmin = {
  EndPoint: process.env.REACT_APP_API_ADMIN_URL
}

const ApiAdminProd = {
  EndPoint: process.env.REACT_APP_API_ADMIN_PROD
}

const env = process.env.REACT_APP_API_ENV
//console.log(env)

const appListResponse = {
  success: true,
  data: [
    {
      tenant: 'relish',
      env: '4393aca7-6063-42e0-8f7f-ed079713a732',
      appName: 'dev',
      enabled: true,
      type: 'Test'
    }
  ]
}

const appDetailsResponse = {
  success: true,
  data: {
    data: {
      appId: '4393aca7-6063-42e0-8f7f-ed079713a732',
      appName: 'dev',
      appType: 'bvd',
      catalystMapping: {
        configurable: true,
        order: 2,
        configured: true,
        description: 'Catalyst Assesment Integration',
        enabled: true,
        title: 'Catalyst'
      },
      supplyChainMapping: {
        configurable: true,
        configured: true,
        description: "Supply Chain Mapping",
        enabled: true,
        id: "suppliyChainMapping",
        order: -1,
        title: "Supply Chain"
      },
      enabled: true,
      orbisSearchMapping: {
        configurable: true,
        order: 3,
        configured: true,
        description: 'Ariba Custom Form Integration',
        enabled: true,
        title: 'Ariba Custom From'
      },
      params: {
        ariba: {
          externalApprovalApi: {
            addressQuestionnaireKI: {
              error: false,
              errorMessage: null,
              label: "Address Questionnaire KI",
              value: ''
            },
            apiKey: {
              label: 'Application Key',
              order: 2,
              required: true,
              type: 'password',
              value: 'lo8L0u2WLetC4JV9RaK8lRQJHHte8NDG'
            },
            catalystQuestionnaireUniqueName: {
              label: 'Catalyst Questionnaire',
              value: 'BvD Catalyst Enhancement'
            },
            clientId: {
              label: 'Oauth Client ID',
              order: 3,
              required: true,
              type: 'password',
              value: 'c330b713-81f9-4516-bea0-85ad1ebb292a'
            },
            integrationUser: {
              label: 'Ariba External Approval Integration User',
              order: 5,
              required: true,
              value: 'BvD_user'
            },
            passwordAddapter: {
              label:
                'Ariba  External Approval Integration User Password Adapter',
              options: [
                {
                  label: 'Third Party User',
                  value: 'ThirdPartyUser'
                },
                {
                  label: 'Password Adapter 1',
                  value: 'PasswordAdapter1'
                }
              ],
              order: 6,
              required: true,
              value: 'PasswordAdapter1'
            },
            questionnaireUniqueName: {
              label: 'Orbis Enhancement Questionnaire Name',
              value: 'BvD Orbis Enhancement'
            },
            secret: {
              label: 'OAuth Secret',
              order: 4,
              required: true,
              type: 'password',
              value: 't0MFJcdqCdfwdgbgq6B85CV2exedcwxm'
            },
            showTestConnection: true
          },
          organizationImport: {
            url: {
              error: false,
              errorMessage: '',
              label: 'Url',
              order: 1,
              path: 'params.ariba.organizationImport.url',
              required: true,
              value:
                'https://s1.au.cloud.ariba.com/Sourcing/soap/NitorDSAPP-T/OrganizationImport'
            },
            wsdlPassword: {
              label: 'WSDL Password',
              order: 3,
              required: true,
              type: 'password',
              value: 'NitorP2019.4'
            },
            wsdlUser: {
              includeIn: 'organizationImport',
              label: 'WSDL User',
              order: 2,
              required: true,
              value: 'AribaUser'
            }
          },
          supplierDataWithPagination: {
            apiKey: {
              label: 'Application Key',
              order: 2,
              required: true,
              type: 'password',
              value: 'qlmWTzf5XYU5iYriKBSUax3HXhTvzHPK'
            },
            clientId: {
              label: 'Oauth Client ID',
              order: 2,
              required: true,
              type: 'password',
              value: '4daa68cd-1600-4977-aefd-eed319a432cf'
            },
            secret: {
              label: 'OAuth Secret',
              order: 2,
              required: true,
              type: 'password',
              value: 'qa8EwWuKu6lKSPMWIWUb6d8NxTw5fkHS'
            },
            showTestConnection: true
          }
        },
        general: {
          realm: {
            label: 'Ariba Realm',
            order: 2,
            required: true,
            value: 'NitorDSAPP-T'
          },
          region: {
            label: 'Ariba API Region',
            options: [
              {
                label: 'USA',
                value: 'US'
              },
              {
                label: 'Europe',
                value: 'EU'
              }
            ],
            order: 1,
            required: true,
            value: 'US'
          }
        },
        moodys: {
          apiToken: {
            label: 'Api Token',
            order: 1,
            required: true,
            type: 'password',
            value: '2WM49ddcd7dcd9d7ea1190b5d89d672fa480'
          },
          ssoToken: {
            label: 'Orbis SSO Token',
            order: 2,
            required: true,
            type: 'password',
            value: 'f4058e53c7e1e79a21324cb0503a97a511556210'
          }
        },
        orbisEnhancementJob: {
          enable: 'true',
          lastRun: '2018-08-01T23:28:56.782Z',
          options: {
            label: 'Job Parameters',
            multiple: true,
            options: [
              {
                label: 'New companies on ORBIS',
                value: '0'
              },
              {
                label:
                  'Change of company name (in local or internationally recognised alphabet)',
                value: '1'
              },
              {
                label:
                  'Change of address (in local or internationally recognised alphabet)',
                value: '2'
              },
              {
                label: 'Change of telephone number',
                value: '3'
              },
              {
                label: 'Change in annuals account',
                value: '4'
              },
              {
                label: 'Change in advisors',
                value: '6'
              },
              {
                label: 'Change in M&A Sections',
                value: '7'
              },
              {
                label: 'Change of status (any change)',
                value: '8'
              },
              {
                label: 'Change in industry classifications',
                value: '9'
              },
              {
                label: 'Change of identification numbers',
                value: 'A'
              },
              {
                label: 'Change of e-mail address',
                value: 'B'
              },
              {
                label: 'Change of website address',
                value: 'C'
              },
              {
                label: 'Change of status to active (default of payment)',
                value: 'E'
              },
              {
                label: 'Change of status to active (insolvency proceedings)',
                value: 'F'
              },
              {
                label: 'Change of status to active (dormant)',
                value: 'G'
              },
              {
                label: 'Change of status to bankruptcy',
                value: 'H'
              },
              {
                label: 'Change of status to dissolved',
                value: 'I'
              },
              {
                label: 'Change of status to in liquidation',
                value: 'J'
              },
              {
                label: 'Change of status to inactive',
                value: 'K'
              },
              {
                label: 'Change of national legal form',
                value: 'L'
              },
              {
                label: 'Change in trade description',
                value: 'M'
              },
              {
                label: 'Change in MORE evaluation',
                value: 'MORERA'
              },
              {
                label: 'New annual accounts',
                value: 'O'
              },
              {
                label: 'Revised last year of annual accounts',
                value: 'P'
              },
              {
                label: 'New interim accounts',
                value: 'Q'
              },
              {
                label: 'Revised last period of interim accounts',
                value: 'R'
              },
              {
                label: 'Change of category of the company',
                value: 'V'
              },
              {
                label: 'Change in VADIS predictive indicators',
                value: 'VADIS'
              },
              {
                label:
                  'New annual accounts (ignoring new accounts with limited financials when full accounts are available for the previous year)',
                value: 'X'
              },
              {
                label:
                  'Also consider a change of eventual additional addresses',
                value: 'Y'
              }
            ],
            value: [
              '0',
              '1',
              '2',
              '3',
              '4',
              '6',
              '7',
              '8',
              '9',
              'A',
              'B',
              'C',
              'E',
              'F',
              'G',
              'H',
              'I',
              'J',
              'K',
              'L',
              'M',
              'MORERA',
              'O',
              'P',
              'Q',
              'R',
              'V',
              'VADIS',
              'X',
              'Y'
            ]
          }
        }
      },
      pk: 'bvd01',
      questionnaireMapping: {
        configurable: true,
        order: 1,
        configured: true,
        description: 'Orbis Integration',
        enabled: true,
        title: 'Orbis Enhancement'
      },
      responsesApiContentType: 'application/json',
      riskMapping: {
        configurable: true,
        order: 4,
        configured: true,
        description: 'Risk Integration',
        enabled: true,
        title: ' Ariba Risk'
      },
      service: 'ariba',
      sk: '6e54ec60-9dbc-4d36-8d0e-f865f898f9b6',
      tenantId: 'bvd01',
      type: 'Test'
    }
  }
}

export { ApiAdmin, appListResponse, appDetailsResponse, ApiAdminProd, env }
