import { useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'

import FormControl from '@material-ui/core/FormControl'
//import Select from '@material-ui/core/Select'
import Select2 from 'react-select'
import countryList from 'react-select-country-list'
import LocalesList from 'locales-list'

import { makeStyles } from '@material-ui/core/styles'
import usersEditStyle from '../../assets/jss/views/UsersEdit'

// Form Validation
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { getSelectValue } from '../../utils/functions'
import { IconButton } from '@material-ui/core'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
const useStyles = makeStyles(usersEditStyle)

const defaultFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  role: '',
  tenantId: '',
  language: '',
  country: '',
  locale: ''
}

export function UserForm ({
  onSubmit,
  initialValues = defaultFormValues,
  submitText,
  emailEnabled = false,
  tenants = [],
  roles = [],
  edit,
  roleUserLogged = ''
}) {
  const [lower, setLower] = useState(false)
  const [upper, setUpper] = useState(false)
  const [special, setSpecial] = useState(false)
  const [number, setNumber] = useState(false)
  const [length, setLength] = useState(false)
  const [showPass, setShowPass] = useState(false)
  const [optionsRoles2, setOptionsRoles2] = useState([{}])
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .min(1)
      .max(16)
      .required(),
    lastName: yup
      .string()
      .min(1)
      .max(16)
      .required(),
    email: yup
      .string()
      .email()
      .required(),
    password: edit ? yup.string() : yup.string().required(),
    role: yup
      .string()
      .min(1)
      .required(),
    tenantId: edit
      ? yup.string().min(1)
      : yup
          .string()
          .min(1)
          .required(),
    language: yup
      .string()
      .min(1)
      .required(),
    country: yup
      .string()
      .min(1)
      .required(),
    locale: yup
      .string()
      .min(1)
      .required()
  })

  const classes = useStyles()
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm({
    mode: 'all',
    defaultValues: useMemo(() => initialValues, [initialValues]),
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset(initialValues)
  }, [initialValues, reset])

  useEffect(() => {
    if (roleUserLogged !== 'superAdmin') {
      setOptionsRoles2(roles.filter(role => role.roleId !== 'superAdmin').map(role => { return { value: role.roleId, label: role.name }}))
    } else {
      setOptionsRoles2(roles.map(role => { return {value: role.roleId, label: role.name}}))
    }
  }, [roleUserLogged,roles])


  const localeListOptions = useMemo(() => LocalesList.getAll(), [])
  const countryListOptions = useMemo(() => countryList().getData(), [])

  function onSubmiHook (data) {
    if (!edit && (!upper || !lower || !special || !number || !length)) {
      return
    }
    //if (!data.username) data.username = data.userId
    onSubmit(data)
  }

  function isError (field) {
    if (errors[field]) {
      return true
    }
    return false
  }
  const optionsTenants2 = tenants.map(tenant => {
    return { value: tenant.tenantId, label: tenant.tenantId }
  })

  // const optionsRoles2 = roles.filter(role => {
  //   if (roleUserLogged !== 'superAdmin' && role.roleId !== 'superAdmin') {
  //     return { value: role.roleId, label: role.name }
  //   }
  //   return { value: role.roleId, label: role.name }
  // })
  const countries = countryListOptions.map((country, index) => {
    return { value: country.value, label: country.label }
  })
  const locales = localeListOptions.map(locale => {
    return {
      value: locale.locale.iso_639_with_country,
      label: locale.locale.iso_639_with_country
    }
  })

  const languages = [
    { label: 'English', value: 'en' },
    { label: 'Spanish', value: 'es' }
  ]
  /* const optionsPermissions = permissions.map(permission => {
    return (
      <MenuItem key={permission.permission} value={permission.permission}>
        {permission.permission}
      </MenuItem>
    )
  })
  const optionsTenants = tenants.map(tenant => {
    return (
      <MenuItem key={tenant.tenantId} value={tenant.tenantId}>
        {tenant.tenantId}
      </MenuItem>
    )
  })


  const optionsRoles = roles.map(role => {
    return (
      <MenuItem key={role.roleId} value={role.roleId}>
        {role.name}
      </MenuItem>
    )
  })*/

  return (
    <form onSubmit={handleSubmit(onSubmiHook)}>
      <h3 className={classes.h3}>Users</h3>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Card className={classes.card} id={'cardId'}>
            <CardContent>
              <Grid container>
                <Grid item xs={12} sm={6} md={4}>
                  <Grid item xs={12}>
                    <Controller
                      render={({ field }) => (
                        <Grid container>
                          <Grid item xs={12}>
                            <span
                              style={{
                                color: 'red',
                                marginLeft: '10px',
                                visibility: isError('firstName') ? '' : 'hidden'
                              }}
                            >
                              {'Please Add First Name *'}
                            </span>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              className={classes.textField}
                              label="First Name"
                              variant="outlined"
                              size="small"
                              error={isError('firstName')}
                              {...field}
                            />
                          </Grid>
                        </Grid>
                      )}
                      name="firstName"
                      control={control}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Grid item xs={12}>
                    <Controller
                      render={({ field }) => (
                        <Grid container>
                          <Grid item xs={12}>
                            <span
                              style={{
                                color: 'red',
                                marginLeft: '10px',
                                visibility: isError('lastName') ? '' : 'hidden'
                              }}
                            >
                              {'Please Add Last Name *'}
                            </span>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Last Name"
                              variant="outlined"
                              size="small"
                              error={isError('lastName')}
                              className={classes.textField}
                              {...field}
                            />
                          </Grid>
                        </Grid>
                      )}
                      name="lastName"
                      control={control}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Grid item xs={12}>
                    <Controller
                      render={({ field }) => (
                        <Grid container>
                          <Grid item xs={12}>
                            <span
                              style={{
                                color: 'red',
                                marginLeft: '10px',
                                visibility: isError('email') ? '' : 'hidden'
                              }}
                            >
                              {'Please Add Email *'}
                            </span>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              InputProps={{
                                readOnly: emailEnabled
                              }}
                              label="Email"
                              variant="outlined"
                              size="small"
                              error={isError('email')}
                              className={classes.textField}
                              {...field}
                            />{' '}
                          </Grid>{' '}
                        </Grid>
                      )}
                      name="email"
                      control={control}
                    />
                  </Grid>
                </Grid>

                {!edit ? (
                  <Grid item xs={12} sm={6} md={4}>
                    <Grid item xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.selectField}
                        error={isError('tenantId')}
                      >
                        <InputLabel id="tenant-label"></InputLabel>
                        <Controller
                          render={({ field: { value, onChange } }) => (
                            <Grid container>
                              <Grid item xs={12}>
                                <span
                                  style={{
                                    color: 'red',
                                    visibility: isError('tenantId')
                                      ? ''
                                      : 'hidden'
                                  }}
                                >
                                  {'Please Select Tenant *'}
                                </span>
                              </Grid>
                              <Grid item xs={12}>
                                <Select2
                                  menuPortalTarget={document.getElementById(
                                    'cardId'
                                  )}
                                  labelId="permissions-label"
                                  label="Permissions"
                                  placeholder={'Tenant'}
                                  options={optionsTenants2}
                                  value={getSelectValue(value, optionsTenants2)}
                                  onChange={e => {
                                    let newValue
                                    if (Array.isArray(e)) {
                                      newValue = e.map(val => {
                                        return val.value
                                      })
                                    } else newValue = e.value
                                    if (newValue) onChange(newValue)
                                  }}
                                ></Select2>
                              </Grid>
                            </Grid>
                          )}
                          /*render={({ field }) => (
                            <Select
                              labelId="tenant-label"
                              label="Tenant"
                              {...field}
                            >
                              <MenuItem disabled>Select the tenant</MenuItem>
                              {optionsTenants}
                            </Select>
                          )}*/
                          name="tenantId"
                          control={control}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
                <Grid item xs={12} sm={6} md={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.selectField}
                        error={isError('role')}
                      >
                        <InputLabel id="role-label"></InputLabel>
                        <Controller
                          /* render={({ field }) => (
                            <Select
                              labelId="role-label"
                              label="Role"
                              {...field}
                            >
                              <MenuItem disabled>Select the role</MenuItem>
                              {optionsRoles}
                            </Select>
                          )}*/
                          render={({ field: { value, onChange } }) => (
                            <Grid container>
                              <Grid item xs={12}>
                                <span
                                  style={{
                                    color: 'red',
                                    visibility: isError('role') ? '' : 'hidden'
                                  }}
                                >
                                  {'Please Select Role *'}
                                </span>
                              </Grid>
                              <Grid item xs={12}>
                                <Select2
                                  menuPortalTarget={document.getElementById(
                                    'cardId'
                                  )}
                                  labelId="permissions-label"
                                  label="Permissions"
                                  placeholder={'Role'}
                                  options={optionsRoles2}
                                  value={getSelectValue(value, optionsRoles2)}
                                  onChange={e => {
                                    let newValue
                                    if (Array.isArray(e)) {
                                      newValue = e.map(val => {
                                        return val.value
                                      })
                                    } else newValue = e.value
                                    if (newValue) onChange(newValue)
                                  }}
                                ></Select2>{' '}
                              </Grid>{' '}
                            </Grid>
                          )}
                          name="role"
                          control={control}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectField}
                      error={isError('language')}
                    >
                      <InputLabel id="language-label"></InputLabel>
                      <Controller
                        /*render={({ field }) => (
                            
                            <Select
                              labelId="language-label"
                              label="Language"
                              {...field}
                            >
                              <MenuItem disabled>Select the language</MenuItem>
                              <MenuItem value={'en'}>English</MenuItem>
                              <MenuItem value={'es'}>Spanish</MenuItem>
                            </Select>
                          )}*/

                        render={({ field: { value, onChange } }) => (
                          <Grid container>
                            <Grid item xs={12}>
                              <span
                                style={{
                                  color: 'red',
                                  visibility: isError('language')
                                    ? ''
                                    : 'hidden'
                                }}
                              >
                                {'Please Select Language *'}
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              <Select2
                                menuPortalTarget={document.getElementById(
                                  'cardId'
                                )}
                                labelId="language-label"
                                label="Language"
                                placeholder={'Language*'}
                                options={languages}
                                value={getSelectValue(value, languages)}
                                onChange={e => {
                                  let newValue
                                  if (Array.isArray(e)) {
                                    newValue = e.map(val => {
                                      return val.value
                                    })
                                  } else newValue = e.value
                                  if (newValue) onChange(newValue)
                                }}
                              ></Select2>
                            </Grid>
                          </Grid>
                        )}
                        name="language"
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectField}
                      error={isError('country')}
                    >
                      <InputLabel id="country-label"></InputLabel>
                      <Controller
                        render={({ field: { value, onChange } }) => (
                          <Grid container>
                            <Grid item xs={12}>
                              <span
                                style={{
                                  color: 'red',
                                  visibility: isError('country') ? '' : 'hidden'
                                }}
                              >
                                {'Please Select Country *'}
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              <Select2
                                menuPortalTarget={document.getElementById(
                                  'cardId'
                                )}
                                labelId="permissions-label"
                                label="country"
                                placeholder={'Select Country'}
                                options={countries}
                                value={getSelectValue(value, countries)}
                                onChange={e => {
                                  let newValue
                                  if (Array.isArray(e)) {
                                    newValue = e.map(val => {
                                      return val.value
                                    })
                                  } else newValue = e.value
                                  if (newValue) onChange(newValue)
                                }}
                              ></Select2>
                            </Grid>{' '}
                          </Grid>
                        )}
                        /* 
                         
                         
                         render={({ field }) => (
                            <Select
                              labelId="country-label"
                              label="Country"
                              {...field}
                            >
                              <MenuItem disabled>Select the country</MenuItem>
                              {countryListOptions.map((country, index) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  style={{
                                    overflowX: 'auto',
                                    textOverflow: 'ellipsis'
                                  }}
                                  value={country.value}
                                  key={index}
                                >
                                  {country.label}
                                </MenuItem>
                              ))}
                            </Select>
                          )}*/
                        name="country"
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectField}
                      error={isError('locale')}
                    >
                      <InputLabel id="locale-label"></InputLabel>
                      <Controller
                        render={({ field: { value, onChange } }) => (
                          <Grid container>
                            <Grid item xs={12}>
                              <span
                                style={{
                                  color: 'red',
                                  visibility: isError('locale') ? '' : 'hidden'
                                }}
                              >
                                {'Select Locale'}
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              <Select2
                                menuPortalTarget={document.getElementById(
                                  'cardId'
                                )}
                                labelId="language-label"
                                label="locales"
                                placeholder={'Select the locale'}
                                options={locales}
                                value={getSelectValue(value, locales)}
                                onChange={e => {
                                  let newValue
                                  if (Array.isArray(e)) {
                                    newValue = e.map(val => {
                                      return val.value
                                    })
                                  } else newValue = e.value
                                  if (newValue) onChange(newValue)
                                }}
                              ></Select2>
                            </Grid>
                          </Grid>
                        )}
                        /*render={({ field }) => (
                            <Select
                              labelId="locale-label"
                              label="Locale"
                              {...field}
                            >
                              <MenuItem disabled>Select the locale</MenuItem>
                              {localeListOptions.map((locale, index) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  style={{
                                    overflowX: 'auto',
                                    textOverflow: 'ellipsis'
                                  }}
                                  value={locale.locale.iso_639_with_country}
                                  key={index}
                                >
                                  {locale.locale.iso_639_with_country}
                                </MenuItem>
                              ))}
                            </Select>
                          )}*/
                        name="locale"
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {!edit ? (
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid item xs={12}>
                      <Controller
                        render={({ field: { value, onChange } }) => (
                          <Grid container>
                            <Grid item xs={12}>
                              <span
                                style={{
                                  color: 'red',
                                  marginLeft: '10px',
                                  visibility:
                                    ((isError('password') ||
                                      !upper ||
                                      !lower ||
                                      !special ||
                                      !number ||
                                      !length) &&
                                      value) ||
                                    (!value && isError('password'))
                                      ? ''
                                      : 'hidden'
                                }}
                              >
                                {'Please Add Password *'}
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              <form
                                autoComplete="off"
                                style={{ width: '99%', margin: 'auto' }}
                              >
                                <TextField
                                  label="Password"
                                  type={showPass ? 'text' : 'password'}
                                  variant="outlined"
                                  size="small"
                                  error={isError('password')}
                                  className={classes.textField}
                                  value={value}
                                  InputProps={{
                                    endAdornment: (
                                      <IconButton
                                        onClick={() => {
                                          setShowPass(!showPass)
                                        }}
                                      >
                                        {showPass ? (
                                          <VisibilityOffIcon />
                                        ) : (
                                          <VisibilityIcon />
                                        )}
                                      </IconButton>
                                    )
                                  }}
                                  onChange={e => {
                                    onChange(e.target.value)
                                    if (
                                      e.target.value.toUpperCase() !==
                                      e.target.value
                                    ) {
                                      setLower(true)
                                    } else setLower(false)
                                    if (
                                      e.target.value.toLocaleLowerCase() !==
                                      e.target.value
                                    ) {
                                      setUpper(true)
                                    } else setUpper(false)
                                    const specialChars = `/[!@#$%^&*()_+-=[]{};':"\\|,.<>/?]+/;`

                                    const isSpecialCharsPresent = specialChars
                                      .split('')
                                      .some(char =>
                                        e.target.value.includes(char)
                                      ) // true if present and false if not

                                    if (isSpecialCharsPresent) {
                                      setSpecial(true)
                                    } else setSpecial(false)
                                    const numbers = `1234567890`

                                    const containsNumbers = numbers
                                      .split('')
                                      .some(char =>
                                        e.target.value.includes(char)
                                      ) // true if present and false if not
                                    if (containsNumbers) {
                                      setNumber(true)
                                    } else setNumber(false)
                                    if (e.target.value.length >= 8) {
                                      setLength(true)
                                    } else setLength(false)
                                  }}
                                />
                              </form>
                            </Grid>
                            <div style={{ margin: 'auto' }}>
                              <Grid container>
                                <Grid item xs={12} sm={6}>
                                  {lower ? '✓' : 'X'} Password must contain a
                                  lower case letter
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  {upper ? '✓' : 'X'} Password must contain an
                                  upper case letter
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  {special ? '✓' : 'X'} Password must contain a
                                  special character{' '}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  {number ? '✓' : 'X'} Password must contain a
                                  number
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  {length ? '✓' : 'X'} Password must contain at
                                  least 8 characters
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        )}
                        name="password"
                        control={control}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <Button
                    className={classes.defaultButton}
                    style={{ width: '22%' }}
                    type="submit"
                  >
                    {submitText}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  )
}
