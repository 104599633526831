import React from 'react'
import PropTypes from 'prop-types'

// Core
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

// Styles
import withStyles from '@material-ui/core/styles/withStyles'
import style from '../../assets/jss/material-dashboard-pro-react/views/Apps/widgetFormStyle'

// Components
import CustomInput from '../../components/CustomInput/CustomInput'

// Icons
import Info from '@material-ui/icons/Info'
import SettingsIcon from '@material-ui/icons/Settings'
import { IconButton } from '@material-ui/core'

class AppMainMenu extends React.Component {
  constructor (props) {
    super(props)

    this.state = { ...props.initialState }
  }
  sendState () {
    return this.state
  }

  onFieldChange (field, value, mapping = false) {
    let stateCopy = this.state
    if (mapping) stateCopy.mapping[field] = value
    else stateCopy[field] = value

    this.setState(stateCopy)
  }

  onSwitchFieldChange (field, value, id) {
    let stateCopy = this.state
    stateCopy.mapping.switchChildren[id][field] = value

    this.setState(stateCopy)
  }
  checkConnection () {
    this.setState({ aribaConnectedState: true })
  }
  render () {
    const { classes } = this.props
    if (!this.state.mapping) {
      return (
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            mapping prop was not sent
          </Grid>
        </Grid>
      )
    }
    return (
      <Grid container justifycontent="center">
        {this.state.mapping.documentationPath ? (
          <Grid item xs={12} sm={12} md={12}>
            <Button
              className={classes.documentation}
              href={this.state.mapping.documentationPath}
              target="_blank"
            >
              <Info className={classes.icons} />
              Download Documentation
            </Button>
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12} sm={12}>
          <h4 className={classes.infoText}>{this.state.mapping.title}</h4>
        </Grid>
        {/* <Grid item xs={12}>
          {this.state.mapping.topChildren}
        </Grid> */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControlLabel
            classes={{
              label: classes.label
            }}
            label={
              this.state.mapping && this.state.mapping.mainSwitchText
                ? this.state.mapping.mainSwitchText
                : 'Enable'
            }
            labelPlacement="start"
            control={
              <Switch
                checked={this.state.mapping.enabled}
                onChange={event => {
                  this.state.mapping.mainSwitchOnChanged(event, this.state)
                  this.onFieldChange('enabled', event.target.checked, true)
                }}
                value={this.state.mapping.enabled}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar
                }}
              />
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={
            !this.state.mapping.enabled
              ? { backgroundColor: 'rgba(0, 0, 0, 0.03)' }
              : { '': '' }
          }
        >
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginLeft: '15px' }}>
                <h4>{this.state.mapping.applicationTitle}</h4>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{ marginLeft: '25px' }}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={this.state.displayMode === 'edit' ? 10 : 12}
                    sm={this.state.displayMode === 'edit' ? 4 : 4}
                  >
                    <CustomInput
                      success={this.state.envState === 'success'}
                      error={this.state.envState === 'error'}
                      labelText={
                        <span>
                          Application Name
                          <small>
                            {this.state.envState === 'error'
                              ? '(required)'
                              : '*'}
                          </small>
                        </span>
                      }
                      id="env"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled:
                          this.state.displayMode === 'edit' ||
                          !this.state.mapping.enabled,
                        onChange: event => {
                          let value = event.target.value
                          if (value) value = value.replace(' ', '_')
                          else value = ''
                          this.onFieldChange('env', value)
                        },
                        value: this.state.appName
                          ? this.state.appName
                          : this.state.env
                      }}
                    />
                  </Grid>
                  {this.state.displayMode === 'edit' ? (
                    <Grid xs={2} sm={1}>
                      <div style={{ paddingTop: '25px' }}>
                        <IconButton
                          onClick={() => {
                            if (this.props.initialState.changeAppName)
                              this.props.initialState.changeAppName()
                          }}
                        >
                          <SettingsIcon />
                        </IconButton>{' '}
                      </div>
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid item xs={12} sm={3}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Environment Type
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select,
                          disabled: classes.disabled
                        }}
                        value={this.state.type}
                        onChange={e =>
                          this.onFieldChange('type', e.target.value)
                        }
                        inputProps={{
                          disabled: !this.state.mapping.enabled,
                          name: 'type',
                          id: 'type'
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          style={{
                            overflowX: 'auto',
                            textOverflow: 'ellipsis'
                          }}
                          value={'Test'}
                        >
                          Test
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          style={{
                            overflowX: 'auto',
                            textOverflow: 'ellipsis'
                          }}
                          value={'Production'}
                        >
                          Production
                        </MenuItem>
                        <div>
                          <div>
                            <div></div>
                          </div>
                        </div>
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* {this.state.mapping.configureService ? (
                  <Grid item xs={12} sm={3}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor='simple-select'
                        className={classes.selectLabel}
                      >
                        Service
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select,
                          disabled: classes.disabled
                        }}
                        value={this.state.service}
                        onChange={e => {
                          this.onFieldChange('service', e.target.value)
                          if (this.state.mapping.setService)
                            this.state.mapping.setService(e.target.value)
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          style={{
                            overflowX: 'auto',
                            textOverflow: 'ellipsis'
                          }}
                          value={'ariba'}
                        >
                          Ariba
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          style={{
                            overflowX: 'auto',
                            textOverflow: 'ellipsis'
                          }}
                          value={'coupa'}
                        >
                          Coupa
                        </MenuItem>
                        <div>
                          <div>
                            <div></div>
                          </div>
                        </div>
                      </Select>
                    </FormControl>
                  </Grid>
                ) : this.state.mapping.hideService ? (
                  ''
                ) : (
                  <Grid item>Service: {this.state.service} </Grid>
                )}
                {this.state.mapping.configureRealm ? (
                  <Grid item xs={12} sm={3}>
                    <Grid item xs={12}>
                      <CustomInput
                        disabled={!this.state.mapping.enabled}
                        success={this.state.realmState === 'success'}
                        error={this.state.realmState === 'error'}
                        labelText={
                          <span>
                            Realm
                            <small>
                              {this.state.realmState === 'error'
                                ? '(required)'
                                : '*'}
                            </small>
                          </span>
                        }
                        id='realm'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: event => {
                            let newState = this.state
                            newState.realm = event.target.value
                            this.setState(newState)
                          },
                          value: this.state.realm
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
                {this.state.mapping.configureRegion &&
                this.state.mapping.regionOptions &&
                this.state.mapping.regionOptions.length > 0 ? (
                  <Grid item xs={12} sm={3}>
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor='simple-select'
                          className={classes.selectLabel}
                        >
                          Region
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select,
                            disabled: classes.disabled
                          }}
                          multiple={false}
                          value={this.state.region}
                          onChange={e => {
                            /*  this.onMappingFieldChange(
                              'value',
                              e.target.value,
                              id
                            )
                            let newState = this.state
                            newState.region = e.target.value
                            this.setState(newState)
                          }}
                        >
                          {this.state.mapping.regionOptions.map(
                            (element, count) => {
                              let label
                              let value
                              if (typeof element === 'object') {
                                if (element.label !== undefined)
                                  label = element.label
                                if (element.value !== undefined)
                                  value = element.value
                              } else {
                                value = element
                                label = element
                              }
                              if (value !== undefined && label !== undefined)
                                return (
                                  <MenuItem
                                    key={count}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    style={{
                                      overflowX: 'auto',
                                      textOverflow: 'ellipsis',
                                      textAlign: 'center'
                                    }}
                                    value={value}
                                  >
                                    {label}
                                  </MenuItem>
                                )
                              else return ''
                            }
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )} */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item xs={12} sm={12} style={{ marginLeft: '15px' }}>
                <h4>{this.state.mapping.switchChildrenTitle}</h4>
              </Grid>

              <Grid item xs={12} sm={12} style={{ marginLeft: '25px' }}>
                {this.state.mapping.switchChildren ? (
                  Object.keys(this.state.mapping.switchChildren).map(
                    (id, key) => {
                      let child = this.state.mapping.switchChildren[id]
                      return (
                        <Grid container key={key} className={classes.appRow}>
                          <Grid item xs={12} sm={9}>
                            <Grid container>
                              {/* <div className={classes.block}> */}

                              <FormControlLabel
                                label={
                                  <div style={{ marginLeft: '20px' }}>
                                    <strong>{child.title}:</strong>
                                    {child.description}
                                  </div>
                                }
                                control={
                                  <Switch
                                    disabled={!this.state.mapping.enabled}
                                    checked={
                                      child.enabled
                                        ? child.enabled &&
                                          this.state.mapping.enabled
                                        : false
                                    }
                                    onChange={event => {
                                      this.onSwitchFieldChange(
                                        'enabled',
                                        event.target.checked,
                                        id
                                      )
                                      if (child.configurationOnClick)
                                        child.onSwitchFieldChange(
                                          'enabled',
                                          event.target.checked,
                                          id
                                        )
                                    }}
                                    value={'switch' + id}
                                    classes={{
                                      switchBase: classes.switchBase,
                                      checked: classes.switchChecked,
                                      thumb: classes.switchIcon,
                                      track: classes.switchBar
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                              />
                              {/* </div> */}
                            </Grid>
                          </Grid>
                          {this.state.displayMode === 'edit' &&
                          !this.state.hideConfigure ? (
                            <Grid item xs={12} sm={3}>
                              <Button
                                disabled={this.state.settingsDisabled ? this.state.settingsDisabled : false}
                                color={child.configured ? 'primary' : 'danger'}
                                className={classes.documentation}
                                onClick={() => {
                                  if (this.state.mapping.enabled)
                                    child.configurationOnClick(id, this.state)
                                }}
                              >
                                <SettingsIcon className={classes.icons} />
                              </Button>
                            </Grid>
                          ) : null}
                        </Grid>
                      )
                    }
                  )
                ) : (
                  <div></div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

AppMainMenu.propTypes = {
  classes: PropTypes.object
}

export default withStyles(style)(AppMainMenu)
