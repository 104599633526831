import React, { useEffect, useRef } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import AdminLayout from './layout/Admin/Admin'
import { setClientWidth } from './store/MainState'
import { getWidth } from './utils/functions'
import LogOut from './views/LogOut/LogOut'

// Comment to change version of tag
function App() {
  const dispatch = useDispatch()

  const isMounted = useRef(false)
  //let selectedApp = useSelector(state => state.main.selectedApp)

  useEffect(
    () => {
      /*function checkSelectedApp () {
      let storeSelectedApp = localStorage.getItem(`BvD.SelectedApp.${getTenant()}`)
        ? JSON.parse(localStorage.getItem(`BvD.SelectedApp.${getTenant()}`))
        : undefined

      console.log(selectedApp, storeSelectedApp)
      if (
        selectedApp &&
        storeSelectedApp &&
        selectedApp.value !== storeSelectedApp.value
      ) {
        window.location.reload()
      }
      setTimeout(() => {
        checkSelectedApp()
      }, 2500)
    }
*/
      if (!isMounted.current) {
        isMounted.current = true
        window.addEventListener('resize', () => {
          dispatch(setClientWidth(getWidth()))
        })
        //checkSelectedApp()
      } else {
      }
      // this function will be ran when the component is re-renderd or unmounted
      return () => {}
      //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
      //, if one property changes, the useEffect function will be ran again (once per DOM change)
      // you can send all the props and if something updated this function will be ran
    } //[selectedApp, dispatch]
  )
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to="/admin/assignBvD" />}
        />
        <Route exact path="/admin/logout" component={LogOut} />
        <Route path="/admin" component={AdminLayout} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
