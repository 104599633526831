import React, { useState, forwardRef, useEffect, useRef } from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'

import AddIcon from '../../assets/img/icons/chainTransparent.png'
import OrbisIcon from '../../assets/img/icons/orbisTransparent.png'

//import Select from '@material-ui/core/Select'

// Style
import { makeStyles } from '@material-ui/core/styles'
import suppliersCriteriaStyle from '../../assets/jss/views/SuppliersCriteria'
import { env } from '../../utils/Constants'

import ArrowDownward from '@material-ui/icons/ArrowDownward'

import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'

import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import Search from '@material-ui/icons/Search'
import NotificationsIcon from '@material-ui/icons/Notifications'
import LinkOff from '@material-ui/icons/LinkOff';

import ViewColumn from '@material-ui/icons/ViewColumn'
// import LinkIcon from '@material-ui/icons/Link'
import AribaIcon from '../../assets/img/icons/aribaTransparent.png'
import CustomTreeTable from '../../components/CustomTreeTable/CustomTreeTable'

import { Spinner } from '../../components/Loader/Spinner'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Modal from '@material-ui/core/Modal';

// Function
import apiApps from '../../services/apiApps'
import apiAppsProd from '../../services/apiAppsProd'

import { useSelector } from 'react-redux'
import AssignBvDId from '../AssignBvDIdTable/AssignBvDId'

const getConnectedBvDSuppliers =
  env === 'local'
    ? apiApps.getConnectedBvDSuppliers
    : apiAppsProd.getConnectedBvDSuppliers

const unlinkSelectedSupplier =
  env === 'local'
    ? apiApps.unlinkSelectedSupplier
    : apiAppsProd.unlinkSelectedSupplier

const useStyles = makeStyles(suppliersCriteriaStyle)

const options = [
  'Show some love to MUI',
  'Show all notification content',
  'Hide sensitive notification content',
  'Hide all notification content'
]

const optionsSubMenuAlerts = [
  { id: 'SCC', title: 'Alerts of Supply Chain' },
  { id: 'CC', title: 'Alerts of Compliance' }
]

const optionsSubMenuOpenSupplier = [
  { id: 'SCC', title: 'Open in Supply Chain' },
  { id: 'CC', title: 'Open in Compliance Catalyst' }
]

export default function SearchConnectedSuppliers(props) {
  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => { }
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  })
  const classes = useStyles()
  const [data, setData] = useState(props.data ? props.data : [])
  const selectedApp = useSelector(state => state.main.selectedApp)
  const [searchInfo, setSearchInfo] = useState(
    props.searchInfo
      ? props.searchInfo
      : {
        companyName: '',
        bvdId: ''
      }
  )
  const [infoIsLoading, setInfoIsLoading] = useState(false)
  const [realm, setRealm] = React.useState(
    props.realm ? props.realm : undefined
  )
  const [region, setRegion] = React.useState('')
  const [openUnlinkModal, setOpenUnlinkModal] = React.useState(false);

  async function getInfoSearch() {
    setInfoIsLoading(true)
    let response
    response = await getConnectedBvDSuppliers(searchInfo)
    if (response && response.success) {
      setData(response.data.data)
      props.setData(response.data.data)

      if (response.data.realm) {
        setRealm(response.data.realm)
        props.setRealm(response.data.realm)
      }

      if (response.data.region) {
        setRegion(response.data.region)
      }
    }
    setInfoIsLoading(false)
  }
  const tableIcons = {
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  }
  const columns = [
    { title: 'BvD ID', field: 'bvdId' },
    { title: 'Supplier Name', field: 'supplierName' },
    { title: 'Ariba Supplier Id', field: 'supplierId' }
  ]

  function onFieldChange(field, value, check = false) {
    if (check) {
      if (value) {
        searchInfo.Exclusions.push(field)
      } else {
        let selected = searchInfo.Exclusions.findIndex(x => x === field)
        if (selected !== -1) {
          searchInfo.Exclusions.splice(selected, 1)
        }
      }
    } else {
      searchInfo[field] = value
    }
    setSearchInfo({ ...searchInfo })
    props.setSearchInfo({ ...searchInfo })
  }

  const [selectedIndexModel, setSelectedIndexModel] = React.useState([])
  const [currentRowIndex, setCurrentRowIndex] = React.useState(0)
  const [anchorEl, setAnchorEl] = React.useState([])

  const [selectedIndexModelOpen, setSelectedIndexModelOpen] = React.useState([])
  const [currentRowIndexOpen, setCurrentRowIndexOpen] = React.useState(0)
  const [anchorElOpen, setAnchorElOpen] = React.useState([])
  const [currentRowIndexSupplier, setCurrentRowIndexSupplier] = React.useState({})
  const [unlinkSupplierFinished, setUnlinkSupplierFinished] = useState(false)
  const [relinkSupplier, setRelinkSupplier] = useState(false)
  const [supplier, setSupplier] = React.useState()

  const handleOpenUnlinkModal = (index) => {
    setOpenUnlinkModal(true);
    setCurrentRowIndexSupplier(index)
  };

  const handleCloseUnlinkModal = () => {
    setOpenUnlinkModal(false);
  };

  const handleUnlinkSupplierClick = async () => {    
    setInfoIsLoading(true)
    handleCloseUnlinkModal()
    setSupplier(currentRowIndexSupplier)
    const response = await unlinkSelectedSupplier(currentRowIndexSupplier.bvdId,currentRowIndexSupplier.supplierId, currentRowIndexSupplier.orbisQuestionnaireId, currentRowIndexSupplier.catalystQuestionnaireId, currentRowIndexSupplier.supplyChainQuestionnaireId)
    if(response && response.success){
      setInfoIsLoading(false)
      setUnlinkSupplierFinished(true)
    } else {
      setInfoIsLoading(false)
    }
  }

  const handleLinkSupplier = () => {
    setRelinkSupplier(true)
    setUnlinkSupplierFinished(false)
  }

  if(relinkSupplier){
    return (
      <Grid container>
        <Grid item xs={12}>
          <AssignBvDId
            setOnConfirmModalFunction={message => {
              props.setValidationMessage(message)
              props.setSubmitSuccess(true)
              setTimeout(() => {
                window.location.reload()
              }, 1500)
            }}
            onSuccess={(missing, message) => {
              setRelinkSupplier(false)
              props.setValidationMessage(message)
              props.setSubmitSuccess(true)
              setTimeout(() => {
                window.location.reload()
              }, 1500)
            }}
            history={props.history}
            supplier={supplier}
            backButtonOnClick={() => {
              setRelinkSupplier(false)
              setSupplier(undefined)
            }}
            {...props}
          />
        </Grid>
      </Grid>
    )
  }

  const renderMenuItems = rowIndex => {
    return optionsSubMenuAlerts.map((option, index) => (
      <MenuItem
        key={index}
        index={index}
        onClick={event => handleMenuItemClick(event, index)}
        disabled={(option.id === 'SCC' && !selectedApp.supplyChainMapping) || (option.id === 'CC' && !selectedApp.catalystMapping)}
      >
        <Grid container spacing={2}>
          <Grid item md={4}>
            {option.title}
          </Grid>
          <Grid item md={8} style={{ textAlign: 'end' }}>
            <Badge
              badgeContent={
                option.id === 'SCC' ? rowIndex.counterSCC : rowIndex.counter
              }
              color="secondary"
            >
              <NotificationsIcon color="action" />
            </Badge>
          </Grid>
        </Grid>
      </MenuItem>
    ))
  }

  const renderMenuItemsOpenSupplier = rowIndex => {
    return optionsSubMenuOpenSupplier.map((option, index) => (
      <MenuItem
        key={index}
        index={index}
        onClick={event => handleMenuItemClickOpenSupplier(event, index, option.id)}
        disabled={(option.id === 'SCC' && !selectedApp.supplyChainMapping) || (option.id === 'CC' && !selectedApp.catalystMapping)}
      >
        {option.title}
      </MenuItem>
    ))
  }

  const renderMenu = index => {
    let anchor = anchorEl.filter(x => x.rowIndex === index.supplierId)[0]
    return (
      <Menu
        id="lock-menu"
        anchorEl={anchor ? anchor.eventTarget : null}
        keepMounted
        open={Boolean(anchor ? anchor.eventTarget : null)}
        onClose={e => handleClose(index.supplierId)}
      >
        {renderMenuItems(index)}
      </Menu>
    )
  }

  const renderMenuOpenSupplier = index => {
    let anchor = anchorElOpen.filter(x => x.rowIndex === index.supplierId)[0]
    return (
      <Menu
        id="lock-menu"
        anchorEl={anchor ? anchor.eventTarget : null}
        keepMounted
        open={Boolean(anchor ? anchor.eventTarget : null)}
        onClose={e => handleCloseOpenSupplier(index.supplierId)}
      >
        {renderMenuItemsOpenSupplier(index)}
      </Menu>
    )
  }

  const renderListItem = (rowData, isUnlinkSupplierIcon = false) => {
    let selectedIndex = selectedIndexModel.filter(
      x => x.rowIndex === rowData.supplierId
    )[0]
    return (
      <ListItemIcon
        primary={options[selectedIndex ? selectedIndex.itemIndex : 0]}
      >
        {!isUnlinkSupplierIcon ? (<Badge
          badgeContent={rowData.counter + rowData.counterSCC}
          color="secondary"
        >
          <NotificationsIcon color="action" /></Badge>) : (<LinkOff />)}
      </ListItemIcon>
    )
  }

  const renderListItemOpenSupplier = rowData => {
    let selectedIndex = selectedIndexModelOpen.filter(
      x => x.rowIndex === rowData.supplierId
    )[0]
    return (
      <ListItemIcon
        primary={options[selectedIndex ? selectedIndex.itemIndex : 0]}
      >
        <img style={{ width: '25px' }} src={OrbisIcon} alt="" />
      </ListItemIcon>
    )
  }

  const handleClickListItemOpenSupplier = (event, index) => {
    let copyAnchor = [...anchorElOpen]
    let a = copyAnchor.filter(x => x.rowIndex === index)[0]
    if (!a) {
      a = { rowIndex: index, eventTarget: event.currentTarget }
      copyAnchor.push(a)
      setAnchorElOpen(copyAnchor)
    } else {
      let anchorIndex = copyAnchor.indexOf(a)
      a.eventTarget = event.currentTarget
      copyAnchor[anchorIndex] = a
      setAnchorElOpen(copyAnchor)
    }

    let copy = [...selectedIndexModelOpen]
    let m = copy.filter(x => x.rowIndex === index)[0]
    if (!m) {
      m = { rowIndex: index, itemIndex: 0 }
      copy.push(m)
      setSelectedIndexModelOpen(copy)
    }
    setCurrentRowIndexOpen(index)
  }

  const handleMenuItemClickOpenSupplier = (event, index, option) => {
    let copy = [...selectedIndexModelOpen]
    let m = copy.filter(x => x.rowIndex === currentRowIndexOpen)[0]
    let itemIndex = copy.indexOf(m)
    m.itemIndex = index.supplierId
    copy[itemIndex] = m
    setSelectedIndexModelOpen(copy)

    let copyAnchor = [...anchorElOpen]
    let a = copyAnchor.filter(x => x.rowIndex === currentRowIndexOpen)[0]
    let anchorIndex = copyAnchor.indexOf(a)
    a.eventTarget = null
    copyAnchor[anchorIndex] = a
    setAnchorElOpen(copyAnchor)

    if (option === 'CC') {
      props.history.push(
        `/admin/catalystAssesments?supplierId=${m.rowIndex}`
      )
    } else {
      props.history.push(
        `/admin/supplyChainAssessments?supplierId=${m.rowIndex}`
      )
    }
  }

  const handleCloseOpenSupplier = index => {
    let copyAnchor = [...anchorElOpen]
    let a = copyAnchor.filter(x => x.rowIndex === index)[0]
    let anchorIndex = copyAnchor.indexOf(a)
    a.eventTarget = null
    copyAnchor[anchorIndex] = a
    setAnchorElOpen(copyAnchor)
  }

  const handleClickListItem = (event, index, isUnclickSupplierAction = false) => {
    if (!isUnclickSupplierAction) {
      let copyAnchor = [...anchorEl]
      let a = copyAnchor.filter(x => x.rowIndex === index)[0]
      if (!a) {
        a = { rowIndex: index, eventTarget: event.currentTarget }
        copyAnchor.push(a)
        setAnchorEl(copyAnchor)
      } else {
        let anchorIndex = copyAnchor.indexOf(a)
        a.eventTarget = event.currentTarget
        copyAnchor[anchorIndex] = a
        setAnchorEl(copyAnchor)
      }

      let copy = [...selectedIndexModel]
      let m = copy.filter(x => x.rowIndex === index)[0]
      if (!m) {
        m = { rowIndex: index, itemIndex: 0 }
        copy.push(m)
        setSelectedIndexModel(copy)
      }
      setCurrentRowIndex(index)
    } else {
      handleOpenUnlinkModal(index)
    }
  }
  const handleMenuItemClick = (event, index) => {
    let copy = [...selectedIndexModel]
    let m = copy.filter(x => x.rowIndex === currentRowIndex)[0]
    let itemIndex = copy.indexOf(m)
    m.itemIndex = index.supplierId
    copy[itemIndex] = m
    setSelectedIndexModel(copy)

    let copyAnchor = [...anchorEl]
    let a = copyAnchor.filter(x => x.rowIndex === currentRowIndex)[0]
    let anchorIndex = copyAnchor.indexOf(a)
    a.eventTarget = null
    copyAnchor[anchorIndex] = a
    setAnchorEl(copyAnchor)
  }

  const handleClose = index => {
    let copyAnchor = [...anchorEl]
    let a = copyAnchor.filter(x => x.rowIndex === index)[0]
    let anchorIndex = copyAnchor.indexOf(a)
    a.eventTarget = null
    copyAnchor[anchorIndex] = a
    setAnchorEl(copyAnchor)
  }

  let actions = [
    rowData => ({
      icon: () => (
        <div>
          <img style={{ width: '25px' }} src={AribaIcon} alt="" />
        </div>
      ),
      tooltip: 'Go to Ariba',
      onClick: (event, rowData) => {
        if (realm && rowData.supplierId) {
          const urlOpenAriba = region === 'us' ? 's1.ariba.com' : region === 'au' ? 's1.au.cloud.ariba.com' : 's1.eu.cloud.ariba.com'
          window.open(
            `https://${urlOpenAriba}/Sourcing/Main/ad/viewSupplier360Profile/ariba.sourcing.dashboard.SMMainActionsController?realm=${realm}&smVendorId=${rowData.supplierId}`
          )
        }
      }
    }),
    rowData => ({
      icon: () => <img style={{ width: '25px' }} src={AddIcon} alt="" />,
      tooltip: 'Supplier Orbis Enhancement Details',
      onClick: (event, rowData) => {
        if (rowData.supplierId) {
          props.history.push(`/admin/supplier?id=${rowData.supplierId}`)
        }
      }
    }),
    rowData => ({
      icon: () => (
        <div style={{ width: '25px' }}>
          <List aria-label="Device settings" style={{ padding: '0px' }}>
            <ListItem
              button
              aria-haspopup="listbox"
              aria-label="when device is locked"
              onClick={event => {
                handleClickListItemOpenSupplier(event, rowData.supplierId)
              }}
              style={{ display: 'inline-table', backgroundColor: 'revert' }}
            >
              {renderListItemOpenSupplier(rowData)}
            </ListItem>
          </List>
          {renderMenuOpenSupplier(rowData)}
        </div>
      ),
      tooltip: 'Open Catalyst',
      onClick: (event, rowData) => {
        // if (rowData.supplierId) {
        //   props.history.push(
        //     `/admin/catalystAssesments?supplierId=${rowData.supplierId}`
        //   )
        // }
      }
    }),
    rowData => ({
      icon: () => (
        <div style={{ width: '25px' }}>
          <List aria-label="Device settings" style={{ padding: '0px' }}>
            <ListItem
              button
              aria-haspopup="listbox"
              aria-label="when device is locked"
              onClick={event => {
                handleClickListItem(event, rowData.supplierId)
              }}
              style={{ display: 'inline-table', backgroundColor: 'revert' }}
            >
              {renderListItem(rowData)}
            </ListItem>
          </List>
          {renderMenu(rowData)}
        </div>
      ),
      tooltip: 'Risk Assessments',
      onClick: event => { }
    }),
    rowData => ({
      icon: () => (
        <div style={{ width: '25px' }}>
          <List aria-label="Device settings" style={{ padding: '0px' }}>
            <ListItem
              button
              aria-haspopup="listbox"
              aria-label="when device is locked"
              onClick={event => {
                handleClickListItem(event, rowData, true)
              }}
              style={{ display: 'inline-table', backgroundColor: 'revert' }}
            >
              {renderListItem(rowData, true)}
            </ListItem>
          </List>
          {renderMenu(rowData)}
        </div>
      ),
      tooltip: 'Unlink Supplier',
      onClick: event => { }
    })
  ]
  if (props.actions) {
    props.actions.forEach(element => {
      actions.push(element)
    })
  }
  return (
    <div>
      {infoIsLoading ? <Spinner /> : ''}
      <h3 className={classes.h3}>
        {props.title ? props.title : 'Search Connected Suppliers'}
      </h3>
      <Grid container justifyContent="center">
        <Grid item md={12}>
          <Card style={{ width: '100%' }} className={classes.card}>
            {props.cardTitle ? props.cardTitle : ''}
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Company Name"
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                        value={searchInfo.companyName}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            getInfoSearch()
                          }
                        }}
                        onChange={e => {
                          onFieldChange('companyName', e.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="BvD Id"
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                        value={searchInfo.bvdId}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            getInfoSearch()
                          }
                        }}
                        onChange={e => {
                          onFieldChange('bvdId', e.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Divider variant="middle" />
                  <Button
                    disabled={!searchInfo.companyName && !searchInfo.bvdId}
                    className={classes.defaultButton}
                    onClick={() => getInfoSearch()}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <div>
            <h4 style={{ margin: '50px 0 20px 0', color: "#0A1264", fontFamily: 'GT Maerica Standard' }}>Your search results:</h4>
            <CustomTreeTable
              defaultOrder={'desc'}
              icons={tableIcons}
              columns={columns}
              data={data}
              actions={actions}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                showTitle: false
              }}
              mainNode={['bvdId', 'supplierName']}
              treeTableWidth={900}
            />
          </div>
        </Grid>
      </Grid>
      {openUnlinkModal && (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
        <Modal
          open={openUnlinkModal}
          onClose={handleCloseUnlinkModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}      >
          <Grid container direction='column' justifyContent='center' alignItems='center' style={{ backgroundColor: 'white', maxWidth: '30vw', border: '2px solid' }} spacing={4} gap={4}>
            <Grid item>
              Are you sure to unlink this supplier?
            </Grid>
            <Grid container direction='row' justifyContent='center' alignItems='center'>
              <Grid item>
                <Button className={classes.buttonDanger} onClick={handleUnlinkSupplierClick}>Unlink</Button>
              </Grid>
              <Grid item>
                <Button className={classes.defaultButton} style={{ marginBottom: '10px' }} onClick={handleCloseUnlinkModal}>Cancel</Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </div>)}
      {unlinkSupplierFinished && (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
        <Modal
          open={unlinkSupplierFinished}
          onClose={handleCloseUnlinkModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}      >
          <Grid container direction='column' justifyContent='center' alignItems='center' style={{ backgroundColor: 'white', maxWidth: '30vw', border: '2px solid' }} spacing={4} gap={4}>
            <Grid item>
              Do you want to match this unlinked company again?
            </Grid>
            <Grid container direction='row' justifyContent='center' alignItems='center'>
              <Grid item>
                <Button className={classes.buttonDanger} onClick={handleLinkSupplier}>Yes</Button>
              </Grid>
              <Grid item>
                <Button className={classes.defaultButton} style={{ marginBottom: '10px' }} onClick={(e) => {
                  setUnlinkSupplierFinished(false)
                  window.location.reload()
                }}>No</Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </div>)}
    </div>
  )
}
