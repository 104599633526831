/*eslint-disable*/
import React, { useEffect, useRef } from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CustomModal from '../../components/CustomModal/CustomModal'
import Select from 'react-select'
import { Typography, IconButton, Grid, Button } from '@material-ui/core'
// material ui icons

import DeleteIcon from '@material-ui/icons/Delete'
// core components

import Card from '../../components/Card/Card.js'
import CardHeader from '../../components/Card/CardHeader.js'

import CardBody from '../../components/Card/CardBody.js'
import {
  cloneObject,
  compareHierarchyNumbers,
  getKIOptions
} from '../../utils/functions'

import DragAndDrop from '../DragDropFile/DragDropFile'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../assets/jss/layout/Admin'
import { env } from '../../utils/Constants'

import { useSelector } from 'react-redux'

// Api Calls
import apiApps from '../../services/apiApps.js'
import apiAppsProd from '../../services/apiAppsProd'
const getOrbisListDetail =
  env === 'local' ? apiApps.getOrbisListDetail : apiAppsProd.getOrbisListDetail
const setTenantMappings =
  env === 'local' ? apiApps.setTenantMappings : apiAppsProd.setTenantMappings
const getListCallback =
  env === 'local' ? apiApps.getListCallback : apiAppsProd.getListCallback
const getCatalystListDetail =
  env === 'local'
    ? apiApps.getCatalystListDetail
    : apiAppsProd.getCatalystListDetail

import CustomTreeTable from '../../components/CustomTreeTable/CustomTreeTable'
import { Spinner } from '../../components/Loader/Spinner'

const useStyles = makeStyles(styles)

// const optionsTypeMappingDropDown = [
// ]
export default function MappingView(props) {
  const isMounted = useRef(false)
  const orbisSearchMappingType = 'orbisSearchMapping'
  const questionnaireMappingType = 'questionnaireMapping'
  const catalystMappingType = 'catalystMapping'
  const riskMappingType = 'riskMapping'
  const supplyChainMappingType = 'supplyChainMapping'
  let mappingTypeChanged = ''

  const classes = useStyles()
  const [KIOptions, setKIOptions] = React.useState([])
  const [saved, setSaved] = React.useState(false)
  const [options, setOptions] = React.useState()
  const [mapping, setMapping] = React.useState()
  const [tempMapping, setTempMapping] = React.useState()
  const [newOptions, setNewOptions] = React.useState()
  const [isLoading, setIsLoading] = React.useState(true)
  const [isNew, setIsNew] = React.useState(props.mappingVersion === undefined)
  const [orbisListsErrorMessage, setOrbisListsErrorMessage] = React.useState('')
  const [optionsTypeMappingDropDown, setOptionsTypeMappingDropDown] = React.useState([])

  const [typeMappingOption, setTypeMappingOption] = React.useState('')
  const [listsCompliance, setListsCompliance] = React.useState()
  const [listCompliance, setListCompliance] = React.useState()
  const [listsSupplyChain, setListsSupplyChain] = React.useState()
  const [listSupplyChain, setListSupplyChain] = React.useState()
  const selectedApp = useSelector(state => state.main.selectedApp)
  /**
   * TODO: This is a workaround to get the correct mapping type
   * will be changed in the future for a database call
   */
  const [catalystOptions, setCatalystOptions] = React.useState([
    { label: 'Catalyst Status', value: 'CF_RA_Status' },
    { label: 'Score', value: 'CF_RA_Score' },
    { label: 'Score Label', value: 'CF_RA_ScoreKey.CF_ScoreAndClasses_Label' },
    { label: 'Started Date', value: 'CF_RA_StartedOn' },
    { label: 'Modified Date', value: 'CFE_RA_MODIFICATION_DATE' },
    { label: 'Portfolio Id', value: 'CF_PORTFOLIO_ID' },
    { label: 'Modification User', value: 'CFE_RA_MODIFICATION_USER' },
    { label: 'Started By', value: 'CF_RA_StartedBy' } 
  ])

  const [supplyChainOptions, setSupplyChainOptions] = React.useState([
    { label: 'Supply Chain Status', value: 'CF_RA_Status' },
    { label: 'Score Label', value: 'CF_RA_ScoreKey.CF_ScoreAndClasses_Label' },
    { label: 'Modification Date', value: 'CFE_RA_MODIFICATION_DATE' }
  ])

  function fillMappingOptionsDropDown(mapid) {
    if(props.type === riskMappingType) {
      if (mapping[mapid].selectedMapping) {
        if(mapping[mapid].selectedMapping.value === 'supplychain') {
          mapping[mapid].options = supplyChainOptions.concat(mapping[mapid].options)
        } else if (mapping[mapid].selectedMapping.value === 'catalyst') {
          mapping[mapid].options = catalystOptions.concat(mapping[mapid].options)
        }
      }
      return mapping[mapid].options
    } else if (props.type === catalystMappingType) {
      return catalystOptions
    } else if (props.type === supplyChainMappingType) {
      return supplyChainOptions
    } else {
      return options
    }
  }

  async function getOptionListsCatalyst(mappingCatalystType) {
    const listResponse = await getListCallback(mappingCatalystType)
    if (listResponse !== null) {
      if (mappingCatalystType === catalystMappingType)
        setListsCompliance(listResponse)
      if (mappingCatalystType === supplyChainMappingType)
        setListsSupplyChain(listResponse)
      if (mappingCatalystType === orbisSearchMappingType) setLists(listResponse)

      if (
        props.userMapping &&
        props.mappingVersion &&
        props.userMapping &&
        props.userMapping[props.type] &&
        props.userMapping[props.type].mappings &&
        props.userMapping[props.type].mappings[props.mappingVersion]
      ) {
        if (
          props.userMapping[props.type].mappings[props.mappingVersion].list ||
          props.userMapping[props.type].mappings[props.mappingVersion]
            .listSupplyChain ||
          props.userMapping[props.type].mappings[props.mappingVersion]
            .listCompliance
        ) {
          if (listResponse && listResponse.length > 0) {
            let selectedList
            listResponse.forEach(element => {
              if (
                element.value ===
                  props.userMapping[props.type].mappings[props.mappingVersion]
                    .listCompliance &&
                mappingCatalystType === catalystMappingType
              ) {
                selectedList = element
              }
              if (
                element.value ===
                  props.userMapping[props.type].mappings[props.mappingVersion]
                    .listSupplyChain &&
                mappingCatalystType === supplyChainMappingType
              ) {
                selectedList = element
              }
              if (
                element.value ===
                  props.userMapping[props.type].mappings[props.mappingVersion]
                    .list &&
                mappingCatalystType === orbisSearchMappingType
              ) {
                selectedList = element
              }
            })
            if (selectedList) {await updateList(selectedList, false, mappingCatalystType)}
          }
        }
      }
    } else {
      setOrbisListsErrorMessage('Error')
    }
  }

  useEffect(() => {
    if (props.type === riskMappingType) {
      setOptionsTypeMappingDropDown([])
      if (selectedApp.orbisSearchMapping) optionsTypeMappingDropDown.push({ value: 'orbis', label: 'Orbis' })
      if (selectedApp.catalystMapping) optionsTypeMappingDropDown.push({ value: 'catalyst', label: 'Compliance Catalyst' })
      if (selectedApp.supplyChainMapping) optionsTypeMappingDropDown.push({ value: 'supplychain', label: 'Supply Chain' })
      setOptionsTypeMappingDropDown(optionsTypeMappingDropDown)
    }
    
  }, [])
  

  useEffect(async () => {
    if (!isMounted.current) {
      isMounted.current = true
      setOptions(props.options)
      setMapping(
        props.mapping.map(item => ({
          ...item,
          selectedMapping: null,
          options: []
        }))
      )
      
      if (
        (props.type === riskMappingType ||
        props.type === orbisSearchMappingType ||
        props.type === questionnaireMappingType) && selectedApp.orbisSearchMapping
      )
        await getOptionListsCatalyst(orbisSearchMappingType)
      if ((props.type === riskMappingType || props.type === catalystMappingType) && selectedApp.catalystMapping)
        await getOptionListsCatalyst(catalystMappingType)
      if (
        (props.type === riskMappingType ||
        props.type === supplyChainMappingType) && selectedApp.supplyChainMapping
      )
        await getOptionListsCatalyst(supplyChainMappingType)
      setIsLoading(false)
      if (
        props.userMapping &&
        props.mappingVersion &&
        props.userMapping &&
        props.userMapping[props.type] &&
        props.userMapping[props.type].mappings &&
        props.userMapping[props.type].mappings[props.mappingVersion]
      ) {

        setMapping(
          props.userMapping[props.type].mappings[props.mappingVersion].mapping
        )
        if (props.isQuestionnaireMapping)
          setKIOptions(
            props.userMapping[props.type].mappings[props.mappingVersion]
              .KIOptions
          )
        // else
          
        //     setOptions(
        //       props.userMapping[props.type].mappings[props.mappingVersion].options
        //     )
          
      }
    } else {
    }
    // setIsLoading(false)
    return () => {}
  }, [props])

  const [list, setList] = React.useState()
  const [lists, setLists] = React.useState()

  function setNewKIOptionsFunction(KINewOptions) {
    let removed = []
    let labelsChanged = []
    let mappingAux = cloneObject(mapping)
    if (!KINewOptions) KINewOptions = newOptions
    if (mapping) {
      let keys = Object.keys(mapping)
      let newKeys = KINewOptions.map(val => {
        return val.value
      })

      keys.forEach(key => {
        let value = KINewOptions.filter(v => {
          return v.value === key
        })
        if (value.length > 0) {
          if (mappingAux[key].label !== value[0].label) {
            labelsChanged.push({
              oldLabel: mappingAux[key].label,
              newLabel: value[0].label
            })
            mappingAux[key].label = value[0].label
          }
        } else {
          removed.push({ removed: mappingAux[key].label })
          delete mappingAux[key]
        }
      })
    }
    if (removed.length > 0 || labelsChanged.length > 0) {
      props.setOnConfirmModalFunction({
        function: () => {
          setMapping({ ...mappingAux })
          setNewOptions(KINewOptions)
          props.setOnConfirmModalFunction({
            function: () => {}
          })
        }
      })
      props.setModalMessage(
        <Grid container>
          {removed.length > 0 ? (
            <Grid item xs={12}>
              <h1>These KIs Will be Removed:</h1>
              <CustomTreeTable
                minSize={true}
                hideSearch={true}
                data={removed}
                columns={[{ title: 'KI', field: 'removed' }]}
              />
            </Grid>
          ) : (
            ''
          )}
          {labelsChanged.length > 0 ? (
            <Grid item xs={12}>
              <h1> These Labels Will Change:</h1>

              <CustomTreeTable
                hideSearch={true}
                minSize={true}
                data={labelsChanged}
                columns={[
                  { title: 'Old Label', field: 'oldLabel' },
                  { title: 'New Label', field: 'newLabel' }
                ]}
              />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      )
      props.setShowModal(true)
    } else {
      if (!isNew) {
        props.setModalMessage(<div>No Changes Found</div>)
        props.setOnConfirmModalFunction({
          function: () => {
            props.setShowModal(false)
          }
        })
        props.setShowModal(true)
      }
      setIsNew(false)
    }
    setKIOptions(KINewOptions)
  }

  function setNewOptionsFunction(newSelect, e, selectChange) {
    let mappingAux = cloneObject(mapping)
    let removed = []
    if (!newSelect) newSelect = options
    if (props.type === catalystMappingType) {
      const ids = options.map(o => o.value)
      const filtered = options.filter(
        ({ value }, index) => !ids.includes(value, index + 1)
      )
      newSelect = filtered //options.concat(catalystOptions)
    }
    if (mapping) {
      let keys = Object.keys(mapping)
      let newKeys = newSelect.map(val => {
        return val.value
      })

      keys.forEach(key => {
        mappingAux[key].mapping.forEach(mappingValue => {
          if (!newKeys.includes(mappingValue) && key !== 'nationalIdsArray') {
            removed.push(`${mappingAux[key].label} - ${mappingValue}`)
            mappingAux[key].mapping = mappingAux[key].mapping.filter(
              v => v !== mappingValue
            )
            if (mappingAux[key].mapping.length === 0) mappingAux[key].value = []
          }
        })
      })
    }
    if (removed.length > 0 && selectChange) {
      props.setOnConfirmModalFunction({
        function: () => {
          setMapping({ ...mappingAux })
          if (e) {
            if (mappingTypeChanged === catalystMappingType) setListCompliance(e)
            if (mappingTypeChanged === orbisSearchMappingType) setList(e)
            if (mappingTypeChanged === supplyChainMappingType)
              setListSupplyChain(e)
          }

          if (mappingTypeChanged === orbisSearchMappingType)
            setOptions(newSelect)
          if (mappingTypeChanged === catalystMappingType) {
            const newOptionsForCatalyst = catalystOptions.concat(newSelect)
            const ids = newOptionsForCatalyst.map(o => o.value)
            const filtered = newOptionsForCatalyst.filter(
              ({ value }, index) => !ids.includes(value, index + 1)
            )
            setCatalystOptions(filtered)
          }
          if (mappingTypeChanged === supplyChainMappingType) {
            const newOptionsForSupplyChain = supplyChainOptions.concat(
              newSelect
            )
            const ids = newOptionsForSupplyChain.map(o => o.value)
            const filtered = newOptionsForSupplyChain.filter(
              ({ value }, index) => !ids.includes(value, index + 1)
            )
            setSupplyChainOptions(filtered)
          }

          props.setOnConfirmModalFunction({
            function: () => {}
          })
        }
      })
      props.setModalMessage(
        <Grid container>
          Removed:
          {removed.map((mappingValue, id) => {
            return (
              <Grid key={id} item xs={12}>
                {mappingValue}
              </Grid>
            )
          })}
        </Grid>
      )
      props.setShowModal(true)
    } else {
      if (!isNew && selectChange) {
        props.setModalMessage(<div>No Changes Found</div>)
        props.setOnConfirmModalFunction({
          function: () => {
            props.setShowModal(false)
          }
        })
        props.setShowModal(true)
      }
      setIsNew(false)
      if (e) {
        if (mappingTypeChanged === catalystMappingType) setListCompliance(e)
        if (mappingTypeChanged === orbisSearchMappingType) setList(e)
        if (mappingTypeChanged === supplyChainMappingType) setListSupplyChain(e)
      }
      if (mappingTypeChanged === orbisSearchMappingType) setOptions(newSelect)
      if (mappingTypeChanged === catalystMappingType) {
        const newOptionsForCatalyst = catalystOptions.concat(newSelect)
        const ids = newOptionsForCatalyst.map(o => o.value)
        const filtered = newOptionsForCatalyst.filter(
          ({ value }, index) => !ids.includes(value, index + 1)
        )
        setCatalystOptions(filtered)
      }
      if (mappingTypeChanged === supplyChainMappingType) {
        const newOptionsForSupplyChain = supplyChainOptions.concat(newSelect)
        const ids = newOptionsForSupplyChain.map(o => o.value)
        const filtered = newOptionsForSupplyChain.filter(
          ({ value }, index) => !ids.includes(value, index + 1)
        )
        setSupplyChainOptions(filtered)
      }
    }
  }
  if (!mapping) return <div></div>

  let mainOptions = []
  /**
 * 
 * map( a => a.split('.').map( n => +n+100000 ).join('.') ).sort()
         .map( a => a.split('.').map( n => +n-100000 ).join('.') );
 * 
 * 
 */
  let mappingKeys = Object.keys(mapping).sort(function(a, b) {
    let aLabel = mapping[a].label ? mapping[a].label : ''
    let bLabel = mapping[b].label ? mapping[b].label : ''
    let aNumber =
      aLabel.indexOf(' ') > 0
        ? aLabel.substring(0, aLabel.indexOf(' '))
        : aLabel
    let bNumber =
      bLabel.indexOf(' ') > 0
        ? bLabel.substring(0, bLabel.indexOf(' '))
        : bLabel
    aNumber = aNumber.split('.')
    bNumber = bNumber.split('.')
    if (
      (aNumber.length > 1 || bNumber.length > 1) &&
      (!isNaN(aNumber[0]) || !isNaN(bNumber[0]))
    )
      return compareHierarchyNumbers(aNumber, bNumber)
    else if (Number(aLabel) < Number(bLabel)) return -1
    else if (aLabel < bLabel) return -1
    else return 1
  })

  if (props.isQuestionnaireMapping) {
    KIOptions.filter(val => {
      return !mappingKeys.includes(val.value)
    }).forEach(ki => {
      mainOptions.push(ki)
    })
  } else {
    props.mainOptions
      .filter(val => {
        return !mappingKeys.includes(val)
      })
      .forEach(option => {
        mainOptions.push({ label: option, value: option })
      })
  }
  function changeOptions(e, selectChange = true) {
    props.setShowModal(true)
    props.setOnConfirmModalFunction({
      function: () => {
        setNewOptionsFunction(e.translatedSelect, e, selectChange)
      }
    })
    props.setModalMessage(
      <div>
        This will remove the mapped values that are not in this List
        <div>Do you want to continue?</div>
      </div>
    )
  }
  async function updateList(e, selectChange, mappingType = '') {
    if (selectChange) setIsLoading(true)
    let detail
    if (
      mappingType === catalystMappingType ||
      mappingType === supplyChainMappingType
    )
      detail = await getCatalystListDetail(e.value, mappingType)
    if (mappingType === orbisSearchMappingType)
      detail = await getOrbisListDetail(e.value, mappingType)
    if (selectChange) setIsLoading(false)
    detail.value = e.value
    detail.label = e.label

    if (
      typeof list !== 'undefined' ||
      typeof listCompliance !== 'undefined' ||
      typeof listSupplyChain !== 'undefined'
    ) {
      changeOptions(detail, selectChange)
    } else {
      //getOrbisListDetail
      if (mappingType === orbisSearchMappingType) setList(detail)
      if (mappingType === catalystMappingType) setListCompliance(detail)
      if (mappingType === supplyChainMappingType) setListSupplyChain(detail)

      if (mappingType === catalystMappingType) {
        const newOptionsForCatalyst = catalystOptions.concat(
          detail.translatedSelect
        )
        setCatalystOptions(newOptionsForCatalyst)
      } else if (mappingType === supplyChainMappingType) {
        const newOptionsForSupplyChain = supplyChainOptions.concat(
          detail.translatedSelect
        )
        setSupplyChainOptions(newOptionsForSupplyChain)
      } else {
        setOptions(detail.translatedSelect)
      }
    }

    //**** */
  }

  return (
    <div>
      <Grid container>
        {isLoading ? <Spinner /> : ''}

        <Grid item xs={12}>
          <IconButton
            onClick={() => {
              props.backFunction(!saved)
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <div style={{ width: '95%', margin: 'auto' }} id="testId">
          <h1>{props.title}</h1>
          <Card>
            {props.mappingVersion ? (
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      paddingTop: '10px',
                      paddingButtom: '10px'
                    }}
                  >
                    {`Version: ${props.mappingVersion}`}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
            {props.isQuestionnaireMapping ? (
              <div style={{ margin: '50px' }}>
                <DragAndDrop
                  handleFile={file => {
                    let extension = file.name.split('.').pop()
                    if (extension && extension.toLocaleLowerCase)
                      extension = extension.toLocaleLowerCase()

                    if (extension !== 'xls' && extension !== 'xlsx') {
                      props.setModalMessage(
                        <div>Please Upload XLS or XLSX File</div>
                      )
                      props.setOnConfirmModalFunction({
                        function: () => {}
                      })
                      props.setShowModal(true)

                      return
                    }
                    getKIOptions(file, 'Content', KINewOptions => {
                      if (KIOptions && KIOptions.length > 0) {
                        props.setShowModal(true)
                        props.setOnConfirmModalFunction({
                          function: () => {
                            setNewKIOptionsFunction(KINewOptions)
                          }
                        })
                        props.setModalMessage(
                          <div>
                            This will remove the mapped values that are not in
                            this file
                            <div>Do you want to continue?</div>
                          </div>
                        )
                      } else {
                        setNewOptions(KINewOptions)
                        setNewKIOptionsFunction(KINewOptions)
                      }
                    })
                  }}
                />
              </div>
            ) : (
              ''
            )}
            <CardHeader>
              <Grid container>
                {props.type === riskMappingType ||
                props.type === questionnaireMappingType || props.type === orbisSearchMappingType ? (
                  <Grid item xs={props.type === riskMappingType ? 3 : 6}>
                    <div style={{ width: '80%', margin: 'auto' }}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          paddingTop: '10px',
                          paddingButtom: '10px'
                        }}
                      >
                        Custom List
                      </Typography>
                      {orbisListsErrorMessage}
                      <Select
                        isDisabled={
                          (orbisListsErrorMessage &&
                          orbisListsErrorMessage.length > 0) || !selectedApp.orbisSearchMapping
                        }
                        value={list}
                        style={{ width: '100%', overflowX: 'hidden' }}
                        multiple={false}
                        options={lists}
                        styles={{
                          // Fixes the overlapping problem of the component
                          menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                        onChange={e => {
                          mappingTypeChanged = orbisSearchMappingType
                          updateList(e, true, orbisSearchMappingType)
                        }}
                        inputProps={{
                          name: 'mainOptions',
                          id: 'mainOptions'
                        }}
                      ></Select>
                    </div>
                  </Grid>
                ) : ('')}

                {props.type === riskMappingType ||
                props.type === catalystMappingType ? (
                  <Grid
                    item
                    xs={
                      props.type === catalystMappingType ||
                      props.type === supplyChainMappingType
                        ? 6
                        : 3
                    }
                  >
                    <div style={{ width: '80%', margin: 'auto' }}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          paddingTop: '10px',
                          paddingButtom: '10px'
                        }}
                      >
                        Compliance Catalyst List View
                      </Typography>

                      <Select
                        value={listCompliance}
                        style={{ width: '100%', overflowX: 'hidden' }}
                        multiple={false}
                        isDisabled={!selectedApp.catalystMapping}
                        options={listsCompliance}
                        styles={{
                          // Fixes the overlapping problem of the component
                          menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                        onChange={e => {
                          mappingTypeChanged = catalystMappingType
                          updateList(e, true, catalystMappingType)
                        }}
                        inputProps={{
                          name: 'catalystOptions',
                          id: 'catalystOptions'
                        }}
                      ></Select>
                    </div>
                  </Grid>
                ) : null}

                {props.type === riskMappingType ||
                props.type === supplyChainMappingType ? (
                  <Grid item xs={props.type === riskMappingType ? 3 : 6}>
                    <div style={{ width: '80%', margin: 'auto' }}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          paddingTop: '10px',
                          paddingButtom: '10px'
                        }}
                      >
                        Supply Chain List View
                      </Typography>
                      <Select
                        isDisabled={!selectedApp.supplyChainMapping}
                        value={listSupplyChain}
                        style={{ width: '100%', overflowX: 'hidden' }}
                        multiple={false}
                        options={listsSupplyChain}
                        styles={{
                          // Fixes the overlapping problem of the component
                          menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                        onChange={e => {
                          mappingTypeChanged = supplyChainMappingType
                          updateList(e, true, supplyChainMappingType)
                        }}
                        inputProps={{
                          name: 'supplyChainOptions',
                          id: 'supplyChainOptions'
                        }}
                      ></Select>
                    </div>
                  </Grid>
                ) : null}

                <Grid item xs={props.type === riskMappingType ? 3 : 6}>
                  <div style={{ width: '80%', margin: 'auto' }}>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        paddingTop: '10px',
                        paddingButtom: '10px'
                      }}
                    >
                      Options
                    </Typography>

                    <Select
                      value={[]}
                      style={{ width: '100%', overflowX: 'hidden' }}
                      multiple={false}
                      options={mainOptions}
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                      }}
                      onChange={({ value, label }) =>
                        setMapping(currentMapping => ({
                          ...currentMapping,
                          [value]: { label, mapping: [] }
                        }))
                      }
                      inputProps={{
                        name: 'mainOptions',
                        id: 'mainOptions'
                      }}
                    ></Select>
                  </div>
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <Grid container>
                <Grid item xs={12}>
                  {mappingKeys.map((mapid, id) => {
                    let map = mapping[mapid]
                    if (mapid === 'nationalIdsArray') {
                      return ''
                    }
                    return (
                      <Grid container style={{ margin: 'auto' }} key={id}>
                        <Grid item xs={11} style={{ margin: 'auto 0' }}>
                          <Grid container style={{ margin: 'auto' }} key={id}>
                            <div style={{ width: '80%', margin: 'auto' }}>
                              <Grid item xs={12} style={{ margin: 'auto 0' }}>
                                <Typography
                                  style={{
                                    fontWeight: 'bold',
                                    paddingTop: '10px',
                                    paddingButtom: '10px'
                                  }}
                                >
                                  {mapping[mapid].label}
                                </Typography>
                              </Grid>
                              <Grid
                                container
                                style={{ width: '100%' }}
                                direction="row"
                                spacing={3}
                              >
                                {props.type === 'riskMapping' ? (
                                  <Grid item xs={4} sm={6}>
                                    <Select
                                      value={map.selectedMapping}
                                      style={{ width: '100%' }}
                                      multiple={true}
                                      styles={{
                                        // Fixes the overlapping problem of the component
                                        menu: provided => ({
                                          ...provided,
                                          zIndex: 9999
                                        })
                                      }}
                                      onChange={e => {
                                        const selectedOptions =
                                          e.value === 'orbis'
                                            ? options
                                            : e.value === 'supplychain'
                                            ? supplyChainOptions
                                            : catalystOptions
                                        setMapping(currentMapping => ({
                                          ...currentMapping,
                                          [mapid]: {
                                            ...currentMapping[mapid],
                                            selectedMapping: [e],
                                            options: selectedOptions
                                          }
                                        }))
                                      }}
                                      options={optionsTypeMappingDropDown}
                                    ></Select>
                                  </Grid>
                                ) : null}
                                <Grid
                                  item
                                  xs={4}
                                  sm={props.type === 'riskMapping' ? 6 : 12}
                                >
                                  <Select
                                    value={map.value}
                                    style={{ width: '100%' }}
                                    multiple={true}
                                    styles={{
                                      // Fixes the overlapping problem of the component
                                      menu: provided => ({
                                        ...provided,
                                        zIndex: 9999
                                      })
                                    }}
                                    onChange={e => {
                                      let mappingAux = mapping
                                      if (e.custom)
                                        mappingAux[mapid].custom = e.custom
                                      mappingAux[mapid].value = [e]
                                      mappingAux[mapid].mapping = [e.value]
                                      if (props.type === riskMappingType)
                                        mappingAux[
                                          mapid
                                        ].optionRisk = typeMappingOption
                                      setMapping({ ...mappingAux })
                                    }}
                                    options={
                                      fillMappingOptionsDropDown(mapid)
                                    }
                                  ></Select>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item xs={1} style={{ margin: 'auto 0' }}>
                          <div style={{ width: '100%' }}>
                            <IconButton
                              style={{ float: 'left' }}
                              onClick={() => {
                                let mappingAux = mapping
                                delete mappingAux[mapid]
                                setMapping({ ...mappingAux })
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
              {Object.keys(mapping).length > 0 ? (
                <Button
                  className={classes.defaultButton}
                  onClick={async () => {
                    let newVersion = props.mappingVersion

                    if (!props.mappingVersion) {
                      const mappingObjectKeys = Object.keys(
                        props.userMapping[props.type].mappings
                      )
                      if (mappingObjectKeys.length === 0) newVersion = 0
                      else
                        newVersion =
                          Math.max(
                            ...mappingObjectKeys.map(val => {
                              if (!isNaN(Number(val))) return Number(val)
                              return 0
                            })
                          ) + 1
                    }

                    props.userMapping[props.type].mappings[newVersion] = {}
                    let mappingsObject = props.userMapping[props.type].mappings
                    mappingsObject[newVersion].mapping = mapping
                    if (
                      (props.type === orbisSearchMappingType ||
                      props.type === riskMappingType ||
                      props.type === questionnaireMappingType) && selectedApp.orbisSearchMapping && list?.select?.length > 0 && list?.translatedSelect?.length > 0
                    ) {
                      mappingsObject[newVersion].select = {
                        SELECT: list.select,
                        translatedSelect: list.translatedSelect
                      }
                      mappingsObject[newVersion].list = list.value
                    }

                    if (
                      (props.type === catalystMappingType ||
                      props.type === riskMappingType) && selectedApp.catalystMapping && listCompliance?.select?.length > 0 && listCompliance?.translatedSelect?.length > 0
                    ) {
                      mappingsObject[newVersion].selectCompliance = {
                        SELECT: listCompliance.select,
                        translatedSelect: listCompliance.translatedSelect
                      }
                      mappingsObject[newVersion].listCompliance =
                        listCompliance.value
                    }

                    if (
                      (props.type === supplyChainMappingType ||
                      props.type === riskMappingType) && selectedApp.supplyChainMapping && listSupplyChain?.select?.length > 0 && listSupplyChain?.translatedSelect?.length > 0
                    ) {
                      mappingsObject[newVersion].selectSupplyChain = {
                        SELECT: listSupplyChain.select,
                        translatedSelect: listSupplyChain.translatedSelect
                      }
                      mappingsObject[newVersion].listSupplyChain =
                        listSupplyChain.value
                    }
                    if (props.type !== orbisSearchMappingType)
                      mappingsObject[newVersion].KIOptions = KIOptions
                    else mappingsObject[newVersion].options = options
                    mappingsObject[
                      newVersion
                    ].date = new Date().toLocaleString()

                    if (props.type === orbisSearchMappingType) {
                      /*
                             adding "nationalIdsArray" as default
                        */
                      mappingsObject[newVersion].mapping['nationalIdsArray'] = {
                        mapping: [
                          {
                            label: 'nationalIdLabel',
                            value: 'NATIONAL_ID_LABEL'
                          },
                          {
                            label: 'nationalIdType',
                            value: 'NATIONAL_ID_TYPE'
                          },
                          {
                            label: 'nationalId',
                            value: 'NATIONAL_ID'
                          }
                        ],
                        type: 'arrayMixedFields'
                      }
                    }

                    let newUserMapping = cloneObject(props.userMapping)

                    delete newUserMapping.pk
                    delete newUserMapping.sk
                    setIsLoading(true)
                    let response = await setTenantMappings(newUserMapping)
                    setIsLoading(false)
                    if (response && response.success) {
                      setSaved(true)
                      props.setValidationMessage('Success')
                      props.setSubmitSuccess(true)
                      props.setOnConfirmModalFunction({
                        function: props.backFunction
                      })

                      //props.backFunction()
                    } else {
                      setSaved(false)
                      // alert(response.message)
                      props.setValidationMessage(response.message)
                      props.setSubmitSuccess(true)
                      props.setOnConfirmModalFunction({
                        function: () => {}
                      })
                    }
                  }}
                >
                  Save
                </Button>
              ) : (
                ''
              )}
            </CardBody>
          </Card>
        </div>
      </Grid>
    </div>
  )
}
