// Views
import Users from './views/Users/Users.js'
import UsersCreate from './views/Users/UsersCreate/UsersCreate'
import UsersEdit from './views/Users/UsersEdit/UsersEdit'

import Mapping from './views/Mapping/Mapping'
import Orbis from './views/Orbis/Orbis'
import Configurations from './views/Configurations/Configurations'

import AribaSupplier from './views/AribaSupplier/AribaSupplier'
import Search from './views/Search/Search'

import Tenants from './views/Tenants/Tenants'
import TenantsCreate from './views/Tenants/TenantsCreate/TenantsCreate'
import TenantsEdit from './views/Tenants/TenantsEdit/TenantsEdit'
import TenantConfiguration from './views/Tenants/TenantConfiguration/TenantConfiguration'
import ImportSuppliers from './views/ImportSuppliers/ImportSuppliers.js'
import Roles from './views/Roles/Roles'
import RolesCreate from './views/Roles/RolesCreate/RolesCreate'
import RolesEdit from './views/Roles/RolesEdit/RolesEdit'
import AssignBvDIdTable from './views/AssignBvDIdTable/AssignBvDIdTable'
import CatalystAssesments from './views/CatalystAssesments/CatalystAssesments'
import SupplyChainAssessments from './views/SupplyChainAssessments/SupplyChainAssessments'
// Icons
/*import configurationsColorIcon from './assets/img/icons/configurationsColorIcon.png'
import tenantColorIcon from './assets/img/icons/tenantColorIcon.png'
import upColorIcon from './assets/img/icons/upColorIcon.png'
import userBlackIcon from './assets/img/icons/userBlackIcon.png'*/
import mappingIcon from './assets/img/icons/mappingIcon.png'
import orbisTransparent from './assets/img/icons/orbisTransparent.png'
import searchWhiteIcon from './assets/img/icons/searchWhiteIcon.png'
//import searchIcon from './assets/img/icons/searchIcon.png'

import tenantIcon from './assets/img/icons/tenantIcon.png'
import configurationsIcon from './assets/img/icons/configurationsIcon.png'
//import rolesColorIcon from './assets/img/icons/rolesColorIcon.png'
import editIcon from './assets/img/icons/editIconWhite.png'
import chainIcon from './assets/img/icons/chainTransparent.png'
import { primaryColor } from './assets/jss/Colors.js'
//import LogOut from './views/LogOut/LogOut.js'
var supplierPaths = {
  '1': '/admin/searchConnectedSuppliers',
  '2': '/admin/suppliers',
  '3': '/admin/supplier-search'
}
var routes = [
  /*{
    path: '/home',
    name: 'Home',
    icon: homeIcon,
    component: props => {
      return <Home {...props} />
    },
    layout: '/admin',
    permission: 'Connector.Home'
  },
  {
    path: '/logout',
    name: 'Create User',
    redirect: true,
    component: props => {
      return <LogOut {...props} />
    },
    layout: '/admin'
  },*/
  {
    path: '/users/create',
    name: 'Create User',
    redirect: true,
    component: props => {
      return <UsersCreate {...props} />
    },
    layout: '/admin',
    permission: 'Connector.Users.Create'
  },
  {
    path: '/roles/create',
    name: 'Create Role',
    redirect: true,
    component: props => {
      return <RolesCreate {...props} />
    },
    layout: '/admin',
    permission: 'Connector.Roles.Create'
  },
  {
    path: '/roles/edit/:roleId',
    name: 'Edit Role',
    redirect: true,
    component: props => {
      return <RolesEdit {...props} />
    },
    layout: '/admin',
    permission: 'Connector.Roles.Edit'
  },

  {
    name: 'Search',
    icon: searchWhiteIcon,
    layout: '/admin',
    permission: 'Connector.Search.Menu',
    childrenRoutes: [
      {
        path: '/searchConnectedSuppliers',
        name: 'Search Connected Suppliers',
        icon: false,
        component: props => {
          return <Search value={'1'} supplierPaths={supplierPaths} {...props} />
        },
        layout: '/admin',
        permission: 'Connector.Search.ConnectedSuppliers'
      },
      {
        path: '/suppliers',
        name: 'Search Suppliers by BvD ID',
        icon: false,
        component: props => {
          return <Search value={'2'} supplierPaths={supplierPaths} {...props} />
        },
        layout: '/admin',
        permission: 'Connector.Search.ByBvD'
      },
      {
        path: '/supplier-search',
        name: 'Search Suppliers by Criteria',
        icon: false,
        component: props => {
          return <Search value={'3'} supplierPaths={supplierPaths} {...props} />
        },
        layout: '/admin',
        permission: 'Connector.Search.SuppliersByCriteria'
      }
    ]
  },
  {
    name: 'Link BvD',
    childrenRoutes: [
      {
        path: '/assignBvD',
        childrenRoutes: [],
        name: 'Link BvD',
        component: props => {
          return <AssignBvDIdTable {...props} />
        },
        layout: '/admin',
        permission: 'Connector.AssignBvd'
      }
    ],
    icon: chainIcon,
    layout: '/admin',
    permission: 'Connector.AssignBvd'
  },
  {
    path: '/users/edit/:userId/:tenantId',
    name: 'Edit User',
    redirect: true,
    component: props => {
      return <UsersEdit {...props} />
    },
    layout: '/admin',
    permission: 'Connector.Users.Edit'
  },

  {
    path: '/tenants/create',
    name: 'Create Tenant',
    redirect: true,
    component: props => {
      return <TenantsCreate {...props} />
    },
    layout: '/admin',
    permission: 'Connector.Tenants.Create'
  },
  {
    path: '/tenants/edit/:id',
    name: 'Edit Tenant',
    redirect: true,
    component: props => {
      return <TenantsEdit {...props} />
    },
    layout: '/admin',
    permission: 'Connector.Tenants.Edit'
  },
  {
    redirect: true,
    path: '/tenants',
    name: 'Tenants',
    icon: tenantIcon,
    component: props => {
      return <Tenants {...props} />
    },
    layout: '/admin',
    permission: 'Connector.Tenants.List'
  },

  {
    path: '/mapping/questionnaireMapping',
    name: 'Field Mapping',
    icon: mappingIcon,
    component: props => {
      return <Mapping type="questionnaireMapping" {...props} />
    },
    layout: '/admin',
    redirect: true,
    permission: 'Connector.Mappings.Orbis'
  },

  {
    path: '/mapping/catalystMapping',
    name: 'Field Mapping',
    icon: mappingIcon,
    component: props => {
      return <Mapping type="catalystMapping" {...props} />
    },
    layout: '/admin',
    redirect: true,
    permission: 'Connector.Mappings.Catalyst'
  },
  {
    path: '/mapping/riskMapping',
    name: 'Field Mapping',
    icon: mappingIcon,
    component: props => {
      return <Mapping type="riskMapping" {...props} />
    },
    layout: '/admin',
    redirect: true,
    permission: 'Connector.Mappings.Risk'
  },
  {
    path: '/mapping/orbisSearchMapping',
    name: 'Field Mapping',
    icon: mappingIcon,
    component: props => {
      return <Mapping type="orbisSearchMapping" {...props} />
    },
    layout: '/admin',
    redirect: true,
    permission: 'Connector.Mappings.CustomForm'
  },
  {
    path: '/mapping/supplyChainMapping',
    name: 'Field Mapping',
    icon: mappingIcon,
    component: props => {
      return <Mapping type="supplyChainMapping" {...props} />
    },
    layout: '/admin',
    redirect: true,
    permission: 'Connector.Mappings.Catalyst'
  },

  {
    name: 'Configurations',
    icon: configurationsIcon,
    layout: '/admin',
    permission: 'Connector.Configurations.Menu',
    childrenRoutes: [
      {
        path: '/tenant/configuration',
        name: 'My account',
        icon: false,
        component: props => {
          return <TenantConfiguration {...props} />
        },
        layout: '/admin',
        permission: 'Connector.Configurations.TenantConfiguration.Edit'
      },
      {
        path: '/tenants',
        name: 'Tenants',
        icon: false,
        component: props => {
          return <Tenants {...props} />
        },
        layout: '/admin',
        permission: 'Connector.Configurations.TenantConfiguration.List'
      },
      {
        path: '/configurations',
        name: 'Applications',
        icon: false,
        component: props => {
          return <Configurations {...props} />
        },
        layout: '/admin',
        permission: 'Connector.Configurations.Applications'
      },

      {
        path: '/users',
        name: 'Users',
        icon: false,
        component: props => {
          return <Users {...props} />
        },
        layout: '/admin',
        permission: 'Connector.Users.List'
      },
      {
        path: '/roles',
        name: 'Roles',
        icon: false,
        component: props => {
          return <Roles {...props} />
        },
        layout: '/admin',
        permission: 'Connector.Roles.List'
      },
      {
        path: '/import',
        name: 'Import suppliers',
        icon: false,
        component: props => {
          return <ImportSuppliers {...props} />
        },
        layout: '/admin',
        permission: 'Connector.ImportSuppliers'
      }
    ]
  },
  {
    path: '/catalystAssesments',
    name: 'Catalyst Assesments',
    icon: editIcon,
    component: props => {
      return <CatalystAssesments {...props} />
    },
    layout: '/admin',
    redirect: true,
    permission: 'Connector.CatalystAssesments'
  },
  {
    path: '/supplyChainAssessments',
    name: 'Supply Chain Assessments',
    icon: editIcon,
    component: props => {
      return <SupplyChainAssessments {...props} />
    },
    layout: '/admin',
    redirect: true,
    permission: 'Connector.SupplyChainAssessments'
  },

  {
    path: '/supplier',
    name: 'Ariba Supplier',
    icon: editIcon,
    component: props => {
      return <AribaSupplier {...props} />
    },
    layout: '/admin',
    redirect: true,
    permission: 'Connector.OrbisAssesments'
  },
  {
    path: '/orbis',
    name: 'Orbis',
    style: {
      width: '45px',
      backgroundColor: 'white',
      borderRadius: '0px 10px 10px 0px',
      height: '50px'
    },
    addEmptyTop: true,
    listItemStyle: {
      paddingLeft: '0px',
      backgroundColor: primaryColor,
      border: 'none'
    },
    imageDivStyle: {
      width: '35px',
      margin: 'auto'
    },

    icon: orbisTransparent,
    component: props => {
      return <Orbis {...props} />
    },
    layout: '/admin',
    permission: 'Connector.OrbisTab'
  }
]
export default routes
