import { useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
//import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'

import Select2 from 'react-select'

import { makeStyles } from '@material-ui/core/styles'
import rolesEditStyle from '../../assets/jss/views/RolesEdit'

// Form Validation
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { getSelectValue } from '../../utils/functions'
import { Paper } from '@material-ui/core'

const useStyles = makeStyles(rolesEditStyle)

const defaultFormValues = {
  name: '',
  roleId: '',
  permissions: [],
  description: ''
}

const schema = yup.object().shape({
  name: yup
    .string()
    .min(1)
    .max(16)
    .required(),
  roleId: yup
    .string()
    .min(1)
    .required(),
  permissions: yup.array().required(),
  description: yup
    .string()
    .min(1)
    .required()
})

export function RoleForm ({
  onSubmit,
  initialValues = defaultFormValues,
  submitText,
  permissions = [],
  isSuperAdmin
}) {
  const [height, setHeight] = useState()
  const [selectorHeight, setSelectorHeight] = useState()
  const classes = useStyles()
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm({
    mode: 'all',
    defaultValues: useMemo(() => initialValues, [initialValues]),
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset(initialValues)
  }, [initialValues, reset])

  function onSubmiHook (data) {
    onSubmit(data)
  }

  function isError (field) {
    if (errors[field]) {
      return true
    }
    return false
  }

  const optionsPermissions2 = permissions.map(permission => {
    return { value: permission.permission, label: permission.permission }
  })
  /* const optionsPermissions = permissions.map(permission => {
    return (
      <MenuItem key={permission.permission} value={permission.permission}>
        {permission.permission}
      </MenuItem>
    )
  })
*/
  function getHeight () {
    const selectorHeight2 = document.getElementById('permissions')?.offsetHeight

    const cardIdHeight = document.getElementById('cardId')?.offsetHeight
    if (height && cardIdHeight && selectorHeight2) {
      setHeight(height + selectorHeight2 - selectorHeight)
      setSelectorHeight(selectorHeight2)
    } else if (cardIdHeight && selectorHeight2) {
      setHeight(cardIdHeight + selectorHeight2)

      setSelectorHeight(selectorHeight2)
    } else {
      setTimeout(() => {
        getHeight()
      }, 500)
    }
  }
  if (!height) {
    getHeight()
  }
  return (
    <form onSubmit={handleSubmit(onSubmiHook)}>
      <h3 className={classes.h3}>Roles</h3>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Paper
            className={classes.card}
            id={'cardId'}
            style={height ? { height: height } : {}}
          >
            <CardContent>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div style={{ marginLeft: '10px' }}>{'Unique Name'}</div>
                      <Controller
                        render={({ field }) => (
                          <Grid container>
                            <Grid item xs={12}>
                              <span
                                style={{
                                  color: 'red',
                                  marginLeft: '10px',
                                  visibility: isError('roleId') ? '' : 'hidden'
                                }}
                              >
                                {'Please Add Unique Name *'}
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                className={classes.textField}
                                variant="outlined"
                                size="small"
                                disabled={isSuperAdmin}
                                error={isError('roleId')}
                                {...field}
                              />
                            </Grid>
                          </Grid>
                        )}
                        name="roleId"
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div style={{ marginLeft: '10px' }}>{'Name'}</div>
                      <Controller
                        render={({ field }) => (
                          <Grid container>
                            <Grid item xs={12}>
                              <span
                                style={{
                                  color: 'red',
                                  marginLeft: '10px',
                                  visibility: isError('name') ? '' : 'hidden'
                                }}
                              >
                                {'Please Add Name *'}
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                variant="outlined"
                                size="small"
                                error={isError('name')}
                                disabled={isSuperAdmin}
                                className={classes.textField}
                                {...field}
                              />
                            </Grid>
                          </Grid>
                        )}
                        name="name"
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div style={{ marginLeft: '10px' }}>{'Description'}</div>
                      <Controller
                        render={({ field }) => (
                          <Grid container>
                            <Grid item xs={12}>
                              <span
                                style={{
                                  color: 'red',
                                  marginLeft: '10px',
                                  visibility: isError('description')
                                    ? ''
                                    : 'hidden'
                                }}
                              >
                                {'Please Add Description *'}
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                variant="outlined"
                                size="small"
                                error={isError('description')}
                                style={{ width: '99%' }}
                                disabled={isSuperAdmin}
                                className={classes.textField}
                                {...field}
                              />{' '}
                            </Grid>{' '}
                          </Grid>
                        )}
                        name="description"
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div style={{ marginLeft: '10px' }}>{'Permissions'}</div>
                      <FormControl
                        variant="outlined"
                        className={classes.selectField}
                        error={isError('permissions')}
                      >
                        <Controller
                          render={({ field: { value, onChange } }) => (
                            /*<Select
                              multiple
                              labelId="permissions-label"
                              label="Permissions"
                              {...field}
                            >
                              <MenuItem disabled>
                                Select the permissions
                              </MenuItem>
                              {optionsPermissions}
                            </Select>*/
                            <Grid container>
                              <Grid item xs={12}>
                                <span
                                  style={{
                                    color: 'red',
                                    marginLeft: '10px',
                                    visibility: isError('permissions')
                                      ? ''
                                      : 'hidden'
                                  }}
                                >
                                  {'Please Select Permissions *'}
                                </span>
                              </Grid>
                              <Grid item xs={12}>
                                <Select2
                                  onloa
                                  id="permissions"
                                  menuPortalTarget={document.getElementById(
                                    'cardId'
                                  )}
                                  isMulti
                                  labelId="permissions-label"
                                  placeholder="Permissions"
                                  isDisabled={isSuperAdmin}
                                  options={optionsPermissions2}
                                  value={getSelectValue(
                                    value,
                                    optionsPermissions2
                                  )}
                                  onChange={e => {
                                    getHeight()
                                    let newValue
                                    if (Array.isArray(e)) {
                                      newValue = e.map(val => {
                                        return val.value
                                      })
                                    } else newValue = e.value
                                    if (newValue) onChange(newValue)
                                  }}
                                ></Select2>
                              </Grid>
                            </Grid>
                          )}
                          name="permissions"
                          control={control}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={
                    selectorHeight
                      ? { paddingTop: selectorHeight, textAlign: 'center' }
                      : { textAlign: 'center' }
                  }
                >
                  { !isSuperAdmin ? (<Button
                    className={classes.defaultButton}
                    style={{ float: 'right', width: '250px' }}
                    type="submit"
                    disabled={isSuperAdmin}
                  >
                    {submitText}
                  </Button>) : null }
                </Grid>
              </Grid>
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
    </form>
  )
}
