import React, { useState, useRef, useEffect } from 'react'

// Core
import IconButton from '@material-ui/core/IconButton'
import Card from '@material-ui/core/Card'
import CustomTreeTable from '../../components/CustomTreeTable/CustomTreeTable'
import Grid from '@material-ui/core/Grid'
import { Spinner } from '../../components/Loader/Spinner'
import Dialog from '@material-ui/core/Dialog'
import Orbis from '../../views/Orbis/Orbis'
// Style
/*import { makeStyles } from '@material-ui/core/styles'
import suppliersCriteriaStyle from '../../assets/jss/views/SuppliersCriteria'
*/
// Function
import userIcon from '../../assets/img/icons/userIcon.png'
import { primaryColorHover } from '../../assets/jss/Colors'
import AribaIcon from '../../assets/img/icons/aribaTransparent.png'
import OrbisIcon from '../../assets/img/icons/orbisTransparent.png'

import { compareHierarchyNumbers } from '../../utils/functions'
//const useStyles = makeStyles(suppliersCriteriaStyle)
import { Close, OpenInBrowser } from '@material-ui/icons'

// Api Calls
import { env } from '../../utils/Constants'
import apiApps from '../../services/apiApps'
import apiAppsProd from '../../services/apiAppsProd'
const getSupplierDetailsWithCallback =
  env === 'local'
    ? apiApps.getSupplierDetailsWithCallback
    : apiAppsProd.getSupplierDetailsWithCallback

export default function SearchConnectedSuppliers (props) {
  const [supplier, setSupplier] = React.useState(undefined)
  const [realm, setRealm] = React.useState(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [region, setRegion] = React.useState('')
  const [showDialog, setShowDialog] = useState(false)
  const [bvdId, setBvdId] = useState()

  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      var url_string = window.location.href
      var url = new URL(url_string)
      var supplierId = url.searchParams.get('id')
      if (supplierId) {
        getSupplierDetailsWithCallback(supplierId, supplier => {
          if (supplier) {
            setSupplier(supplier.data)
            if (supplier.data) setBvdId(supplier.data.bvdId)
            setRealm(supplier.realm)
            setRegion(supplier.region)
          }
          setIsLoading(false)
        })
      }
      isMounted.current = true
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  })
  //const classes = useStyles()
  if (isLoading) return <Spinner />
  if (supplier) {
    let orderedOrbisEnhancement = []
    if (supplier.orbisEnhancement) {
      let orbisEnhancementKeys = Object.keys(supplier.orbisEnhancement).sort(
        function (a, b) {
          let aLabel = supplier.orbisEnhancement[a].label
            ? supplier.orbisEnhancement[a].label
            : ''
          let bLabel = supplier.orbisEnhancement[b].label
            ? supplier.orbisEnhancement[b].label
            : ''
          let aNumber =
            aLabel.indexOf(' ') > 0
              ? aLabel.substring(0, aLabel.indexOf(' '))
              : aLabel
          let bNumber =
            bLabel.indexOf(' ') > 0
              ? bLabel.substring(0, bLabel.indexOf(' '))
              : bLabel
          aNumber = aNumber.split('.')
          bNumber = bNumber.split('.')
          if (
            (aNumber.length > 1 || bNumber.length > 1) &&
            (!isNaN(aNumber[0]) || !isNaN(bNumber[0]))
          )
            return compareHierarchyNumbers(aNumber, bNumber)
          else if (Number(aLabel) < Number(bLabel)) return -1
          else if (aLabel < bLabel) return -1
          else return 1
        }
      )

      orbisEnhancementKeys.forEach(key => {
        orderedOrbisEnhancement.push(supplier.orbisEnhancement[key])
      })
    }
    return (
      <div>
        <Grid container>
          <Dialog
            open={showDialog}
            fullWidth
            maxWidth={'xl'}
            style={{ overflow: 'hidden', maxWidth: '' }}
          >
            <div>
              <IconButton style={{ float: 'right' }}>
                <Close
                  onClick={() => {
                    setShowDialog(false)
                  }}
                />
              </IconButton>
              <IconButton style={{ float: 'left' }}>
                <OpenInBrowser
                  onClick={() => {
                    if (props.history) {
                      props.history.push(`/admin/orbis?bvdId=${bvdId}`)
                    }
                  }}
                />
              </IconButton>
            </div>
            <div style={{ overflow: 'hidden' }}>
              <Orbis bvdId={bvdId} />
            </div>
          </Dialog>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Card style={{ background: '#f2f5f8' }}>
                  <Grid container>
                    <Grid item xs={3}>
                      <div style={{ paddingTop: '5px' }}>
                        <img
                          src={userIcon}
                          style={{
                            width: '50px',
                            height: '50px',
                            background: primaryColorHover
                          }}
                          alt=""
                        />
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container>
                        <Grid item xs={12}>
                          {'Supplier Id: ' + supplier.supplierId}
                        </Grid>
                        <Grid item xs={12}>
                          {'Supplier Name: ' + supplier.supplierName}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        {realm && supplier.supplierId ? (
                          <Grid item xs={4}>
                            <IconButton
                              style={{ float: 'right' }}
                              onClick={() => {
                                setShowDialog(true)
                              }}
                            >
                              <img
                                style={{ width: '25px' }}
                                src={OrbisIcon}
                                alt=""
                              />
                              <span
                                style={{
                                  fontSize: '15px',
                                  paddingTop: '2.5px'
                                }}
                              >
                                OPEN IN ORBIS
                              </span>
                            </IconButton>
                          </Grid>
                        ) : (
                          ''
                        )}
                        {realm && supplier.supplierId ? (
                          <Grid item xs={4}>
                            <IconButton
                              style={{ float: 'right' }}
                              onClick={() => {
                                if (props.history) {
                                  props.history.push(
                                    `/admin/catalystAssesments?supplierId=${supplier.supplierId}`
                                  )
                                }
                              }}
                            >
                              <img
                                style={{ width: '25px' }}
                                src={OrbisIcon}
                                alt=""
                              />
                              <span
                                style={{
                                  fontSize: '15px',
                                  paddingTop: '2.5px'
                                }}
                              >
                                Catalyst
                              </span>
                            </IconButton>
                          </Grid>
                        ) : (
                          ''
                        )}

                        <Grid item xs={4}>
                          {realm && supplier.supplierId ? (
                            <IconButton
                              style={{ float: 'right' }}
                              onClick={() => {
                                const urlOpenAriba = region === 'us' ? 's1.ariba.com' : region === 'au' ? 's1.au.cloud.ariba.com' : 's1.eu.cloud.ariba.com'
                                window.open(
                                  `https://${urlOpenAriba}/Sourcing/Main/ad/viewSupplier360Profile/ariba.sourcing.dashboard.SMMainActionsController?realm=${realm}&smVendorId=${supplier.supplierId}`
                                )
                              }}
                            >
                              <img
                                style={{ width: '25px' }}
                                src={AribaIcon}
                                alt=""
                              />
                              <span
                                style={{
                                  fontSize: '15px',
                                  paddingTop: '2.5px'
                                }}
                              >
                                OPEN IN ARIBA
                              </span>
                            </IconButton>
                          ) : (
                            ''
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {supplier.orbisEnhancement ? (
                <div>
                  <CustomTreeTable
                    tableTitle={
                      <span style={{ fontWeight: 'bold' }}>
                        Orbis Enhancement
                      </span>
                    }
                    noInitialOrder={true}
                    data={orderedOrbisEnhancement}
                    columns={[
                      { title: 'Question', field: 'label', width: '50%' },
                      { title: 'Answer', field: 'answer' }
                    ]}
                  />
                </div>
              ) : (
                <span>Orbis Enhancement hasn't run for this supplier</span>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
  return <div>Supplier Not Found</div>
}
