import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Typography from '@material-ui/core/Typography'
// @material-ui/icons
import TouchApp from '@material-ui/icons/TouchApp'
import Add from '@material-ui/icons/PlaylistAdd'

// style
import styles from '../../assets/jss/views/Configurations'

// Views
import AppMainMenu from '../CustomGenericViews/AppMainMenu.js'
import Wizard from '../../components/Wizard/Wizard.js'
// import { saveInvoiceConfiguration } from '../../services/apiApps.js'
// import { getAppDetails } from './InvoiceContainer.js'
import { cloneObject, showLoading, hideLoading } from '../../utils/functions'
//import { appListResponse } from '../../utils/Constants'
import ParametersAccordion from '../CustomGenericViews/ParametersAccordion.js'
import { env } from '../../utils/Constants'

import { Spinner } from '../../components/Loader/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedApp, setAppList } from '../../store/MainState'
import { Dialog, IconButton, TextField } from '@material-ui/core'
import { getTenant } from '../../Providers/Auth'
import { useMutation } from 'react-query'

// Api Calls
import apiApps from '../../services/apiApps'
import apiAppsProd from '../../services/apiAppsProd'
import { getIamService } from '../../apis/iam'

const getConfigurations =
  env === 'local' ? apiApps.getConfigurations : apiAppsProd.getConfigurations
const updateApp = env === 'local' ? apiApps.updateApp : apiAppsProd.updateApp
const createNewConfiguration =
  env === 'local'
    ? apiApps.createNewConfiguration
    : apiAppsProd.createNewConfiguration
const getApplicationList =
  env === 'local' ? apiApps.getApplicationList : apiAppsProd.getApplicationList
const updateAppName =
  env === 'local' ? apiApps.updateAppName : apiAppsProd.updateAppName

const useStyles = makeStyles(styles)

export default function Configurations(props) {
  let history = useHistory()
  const newAppValidations = {
    service: 'ariba',
    env: '',
    type: 'Test',
    enabled: true,
    displayMode: 'create',
    parameters: {
      region: '',
      siteUrl: ''
    }
  }
  const [service, setService] = useState('')
  const [displayMode, setDisplayMode] = useState('create')
  const [pageIsLoading, setPageIsLoading] = useState(false)
  const [formIsLoading, setFormIsLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [appName, setAppName] = useState(false)
  const [clientId, setClientId] = useState('')
  const [clientSecret, setClientSecret] = useState('')

  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')
  const [validationsForm, setValidationsForm] = useState([])

  const [authUrl, setAuthUrl] = useState('')

  const [scopes, setScopes] = useState([])
  const [userId, setUserId] = React.useState('')
  const [userData, setUserData] = React.useState({})

  const refreshTableFunction = useRef(() => {})
  const isMounted = useRef(false)
  const dispatch = useDispatch()
  const selectedApp = useSelector(state => state.main.selectedApp)
  //const mainRefresh = useSelector(state => state.main.refresh)
  const appList = useSelector(state => state.main.appList)
  function changeValidationsForm(newValidationsForm) {
    setValidationsForm(newValidationsForm)
    getIamService()
      .get(
        `/tenants/${newValidationsForm.tenantId}/appClient/${newValidationsForm.appId}`
      )
      .then(result => {
        setClientSecret(result.data.appClient.clientSecret)
        setClientId(result.data.appClient.clientId)
        const stringScopes = Array.isArray(result.data.appClient.roles)
          ? result.data.appClient.roles.join('%20')
          : ''
        setAuthUrl(result.data.appClient.oauthUrl)
        setScopes(stringScopes)
        setPageIsLoading(false)
      })
      .catch(result => {
        setClientSecret('Not Generated')
        setClientId('Not Generated')
        setPageIsLoading(false)
      })
  }
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      showLoading(setPageIsLoading)
      setPageIsLoading(true)
      loadSelectedApp(hideLoading)
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
    // Please fix this dependecy, you can read more in this link----> https://kentcdodds.com/blog/usememo-and-usecallback
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const authConfigLS = localStorage.getItem(
      `CognitoIdentity.Configure.${getTenant()}`
    )
    const authConfig = JSON.parse(authConfigLS)
    const userEmail = localStorage.getItem(
      `CognitoIdentityServiceProvider.${authConfig.Auth.userPoolWebClientId}.LastAuthUser`
    )
    const userDataPermission = localStorage.getItem(
      `CognitoIdentityServiceProvider.${authConfig.Auth.userPoolWebClientId}.${userEmail}.userData`
    )

    const dataUserLogged = JSON.parse(userDataPermission)
    dataUserLogged.UserAttributes.forEach(({ Name, Value }) => {
      if (Name === 'sub') setUserId(Value)
    })

    if (userId !== '') {
      getIamService()
        .get(`/tenants/${getTenant()}/users/${userId}`)
        .then(result => {
          if (result.data.lastApplication) {
            dispatch(setSelectedApp(result.data.lastApplication))
            setUserData(result.data)
          }
        })
    }
  }, [userId, dispatch])

  const updateUser = useMutation(values =>
    getIamService().put(`/users/${userId}`, values)
  )

  async function loadSelectedApp(callback = () => {}) {
    if (isMounted) await getAppDetails(selectedApp)
  }
  async function getAppDetails(app) {
    setFormIsLoading(true)
    setDisplayMode('edit')
    let appDetails = await getConfigurations(app.value)
    app.catalystMapping = appDetails.data.data.catalystMapping?.enabled
      ? appDetails.data.data.catalystMapping.enabled
      : false
    app.orbisSearchMapping = appDetails.data.data.questionnaireMapping?.enabled
      ? appDetails.data.data.questionnaireMapping.enabled
      : false
    app.supplyChainMapping = appDetails.data.data.supplyChainMapping?.enabled
      ? appDetails.data.data.supplyChainMapping.enabled
      : false
    dispatch(setSelectedApp(app))
    if (appDetails && appDetails.success) {
      await saveAppdetails(appDetails)
      if (userData && userData.tenantId) {
        const newData = {
          ...userData,
          lastApplication: app
        }
        updateUser.mutate(newData)
      }
    } else {
      let message = appDetails.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      setValidationMessage(message)
      setSubmitError(true)
    }
    setFormIsLoading(false)
  }

  async function saveAppdetails(appDetails, refresh = true) {
    //Save complete object
    if (appDetails.data && appDetails.data.data) {
      let validationDetails = appDetails.data.data
      validationDetails.displayMode = 'edit'

      if (isMounted) {
        changeValidationsForm(validationDetails)
        setFormIsLoading(false)
        if (refresh) setRefresh(!refresh)
      }
    }
  }

  async function onSubmit(event) {
    let response

    if (displayMode === 'create') {
      let exists = false
      appList.forEach(app => {
        if (app.label === event.validations.env) {
          exists = true
        }
      })
      if (exists) {
        setValidationMessage(
          event.validations.env + ' Name in Use Please change Application Name'
        )
        setSubmitError(true)
        setTimeout(() => {
          setSubmitError(false)
        }, 5000)
        return
      }

      let validations = event.validations
      validations.appName = validations.env
      delete validations.mapping
      setFormIsLoading(true)
      response = await createNewConfiguration(validations)
    }

    if (response && response.success && isMounted) {
      setValidationMessage(response.data.message)
      setSubmitSuccess(true)

      if (displayMode === 'create') {
        await getApplicationList(applist => {
          if (applist && applist.length > 0) {
            dispatch(setAppList(applist))
            getAppDetails(response.data.newApp)
          }
        }, true)
      } else hideLoading(setPageIsLoading)

      setTimeout(function() {
        if (isMounted) {
          setSubmitSuccess(false)
        }
      }, 5000)
    } else {
      setFormIsLoading(false)
      hideLoading(setPageIsLoading)
      // Show error message
      if (response) {
        let message = response.message
        if (!message) {
          if (response.errors && typeof response.errors === 'string') {
            message = response.errors
          } else if (
            response.errors &&
            typeof response.errors === 'object' &&
            response.errors.length > 0
          ) {
            message = ''
            response.errors.forEach(error => {
              message += ' ' + error.msg
            })
          } else message = 'Something went wrong, please try again later.'
        }
        setValidationMessage(message)
        setSubmitError(true)
      }
    }
  }

  async function appChanged(id) {
    await getAppDetails(id)
    hideLoading(setPageIsLoading)
  }

  function onNewCreateIsCalled() {
    if (displayMode !== 'create') {
      setFormIsLoading(true)
      setDisplayMode('create')
      changeValidationsForm({ ...newAppValidations })

      setTimeout(function() {
        setFormIsLoading(false)
      }, 500)
    }
  }

  async function saveToDataBase(
    configurationProps,
    rollbackFunction,
    successMessage,
    refreshPage = false,
    changeConfigured = true,
    errorMessage = 'Something went wrong, please try again later'
  ) {
    setFormIsLoading(true)
    let newConfigurationProp = cloneObject(configurationProps)

    delete newConfigurationProp.displayMode
    delete newConfigurationProp.mapping
    let keys = Object.keys(newConfigurationProp)
    keys.forEach(key => {
      if (typeof newConfigurationProp[key] === 'object') {
        if (newConfigurationProp[key].configurationOnClick)
          delete newConfigurationProp[key].configurationOnClick
        if (newConfigurationProp[key].parameters)
          delete newConfigurationProp[key].parameters
        if (newConfigurationProp[key].mappings)
          delete newConfigurationProp[key].mappings
      }
    })
    selectedApp.catalystMapping = validationsForm.catalystMapping?.enabled
      ? validationsForm.catalystMapping.enabled
      : false
    selectedApp.orbisSearchMapping = validationsForm.questionnaireMapping
      ?.enabled
      ? validationsForm.questionnaireMapping.enabled
      : false
    selectedApp.supplyChainMapping = validationsForm.supplyChainMapping?.enabled
      ? validationsForm.supplyChainMapping.enabled
      : false
    validationsForm.settingsDisabled = false
    dispatch(setSelectedApp(selectedApp))
    let response = await updateApp(validationsForm)
    if (response && response.success) {
      setValidationMessage(successMessage)
      setSubmitSuccess(true)
      setTimeout(() => {
        setSubmitSuccess(false)
      }, 5000)
      saveAppdetails(response)
    } else {
      setValidationMessage(errorMessage)
      setSubmitError(true)
      setTimeout(() => {
        setSubmitError(false)
      }, 5000)
      if (rollbackFunction) {
        rollbackFunction(() => {
          setRefresh(!refresh)
          if (refreshTableFunction.current) refreshTableFunction.current()
        })
      }
    }
    setFormIsLoading(false)
  }

  const classes = useStyles()

  function getView() {
    function scrolltoTop() {
      let element = document.getElementById('MainGrid')
      if (element) element.scrollTo(0, 0)
    }

    function getAdminSteps() {
      let validationInitialState = validationsForm
      validationInitialState.env = validationInitialState.appId
      validationInitialState.changeAppName = () => {
        setShowDialog(true)
        setAppName(validationsForm.appName)
      }

      let steps = []

      if (displayMode === 'create') {
        validationInitialState.env = ''
        validationInitialState.mapping = {
          setService: setService,
          configureService: displayMode === 'create',
          configureRealm: false,
          enabled: validationsForm.enabled,
          mainSwitchText: 'Enable App',
          mainSwitchOnChanged: (event, state) => {},
          title: '',
          topChildren: '',
          bottomChildren: <div></div>,
          applicationTitle: '',
          switchChildrenTitle: '',
          switchChildren: {},
          hideService: true
        }

        steps.push({
          stepName: 'Validations',
          stepComponent: AppMainMenu,
          stepId: 'validations',
          initialState: validationInitialState
        })
      }

      if (validationsForm && validationsForm.params) {
        let switchChildren = {}
        Object.keys(validationsForm)
          .sort(function(a, b) {
            let orderA = 50000
            if (validationsForm[a] !== undefined) {
              orderA = parseInt(validationsForm[a].order)
              if (!parseInt(validationsForm[a].order)) {
                orderA = 50000
              }
            }
            let orderB = 50000
            if (validationsForm[b] !== undefined) {
              orderB = parseInt(validationsForm[b].order)
              if (!parseInt(validationsForm[b].order)) {
                orderB = 50000
              }
            }

            return orderA - orderB
          })
          .forEach(key => {
            if (validationsForm[key] && validationsForm[key].configurable) {
              switchChildren[key] = validationsForm[key]
              switchChildren[key].id = key
              switchChildren[key].configurationOnClick = (id, state) => {
                history.push(`/admin/mapping/${id}`)
              }
              switchChildren[key].onSwitchFieldChange = () => {}
            }
          })

        let allGeneralParameters =
          Object.keys(switchChildren).length > 0 ? validationsForm.params : {}

        let generalParameters = {}
        if (allGeneralParameters.ariba) {
          generalParameters = {
            realm: allGeneralParameters.ariba.realm,
            region: allGeneralParameters.ariba.region
          }
        }
        let externalApprovalApi = allGeneralParameters.ariba
          ? allGeneralParameters.ariba.externalApprovalApi
          : {}
        let supplierDataWithPagination = allGeneralParameters.ariba
          ? allGeneralParameters.ariba.supplierDataWithPagination
          : {}
        let organizationImport = allGeneralParameters.ariba
          ? allGeneralParameters.ariba.organizationImport
          : {}

        let moodysConfigurations = allGeneralParameters.moodys
          ? allGeneralParameters.moodys
          : {}
        let riskConfigurations = allGeneralParameters.ariba
          ? allGeneralParameters.ariba.riskApi
          : {}

        let emailsJobConfiguration = allGeneralParameters.ariba
          ? allGeneralParameters.ariba.emailsJobConfiguration
          : {}

        apiAppsProd
          .getUsersByTenant(null)
          .then(results => {
            emailsJobConfiguration.emailsNotifications.options = results.data.map(
              (user, index) => {
                return {
                  value: user.email,
                  label: user.email
                }
              }
            )
          })
          .catch(err => console.log(err))
        let mapping = {
          setService: setService,
          service: service,
          configureService: displayMode === 'create',
          configureRealm: false,
          enabled: validationsForm.enabled,
          mainSwitchText: 'Enable App',
          mainSwitchOnChanged: (event, state) => {},
          title: '',
          topChildren: '',
          bottomChildren: <div></div>,
          applicationTitle: '',
          switchChildrenTitle: 'Integration',
          switchChildren: switchChildren
        }

        validationInitialState.mapping = mapping

        let connectivityParameters = []
        if (displayMode === 'edit') {
          steps.push({
            stepName: 'Connectivity',
            stepComponent: ParametersAccordion,
            stepId: 'connectivity',
            initialState: {
              parameters: connectivityParameters
            }
          })

          connectivityParameters.push(
            {
              title: 'Connector API Configurations',
              parentId: 'orbis',
              saveButton: (isValidated, expanded) => {
                return (
                  <div style={{ width: '100%' }}>
                    <Button
                      style={{ margin: '25px', float: 'right' }}
                      className={classes.defaultButton}
                      onClick={() => {
                        setPageIsLoading(true)
                        getIamService()
                          .post('/tenants/appClient', {
                            appId: validationsForm.appId,
                            tenantId: validationsForm.tenantId
                          })
                          .then(result => {
                            setPageIsLoading(false)

                            setClientSecret(result.data.appClient.clientSecret)
                            setClientId(result.data.appClient.clientId)

                            expanded()
                            props.setValidationMessage(result.data.message)
                            props.setSubmitSuccess(true)
                            setTimeout(() => {
                              expanded()
                            }, 5000)
                          })
                          .catch(result => {
                            setPageIsLoading(false)
                            //alert(result.response.data.message)
                            expanded()
                            props.setValidationMessage(
                              result.response.data.message
                            )
                            props.setSubmitError(true)
                            setTimeout(() => {
                              expanded()
                            }, 5000)
                          })
                        //    console.log(validationsForm)
                      }}
                    >
                      Generate App Credentials
                    </Button>
                  </div>
                )
              },
              conectivityMapping: {
                service: validationsForm.service,
                setService: setService,
                env: validationsForm.appId,
                app: 'bvd',
                parametersPosition: '',
                region: validationsForm.params.ariba.region,
                siteUrl: validationsForm.params.siteUrl,
                hideService: true,
                hideTestConnection: true,
                title: '',
                fieldsTitle: '',
                fields: {
                  customUrl: {
                    type: 'label',
                    label: 'Custom API URL',
                    value: `${process.env.REACT_APP_API_CUSTOM_API_URL}/${validationsForm.tenantId}/${validationsForm.appId}`
                  },
                  url: {
                    value: `${process.env.REACT_APP_API_CUSTOM_API_URL}/oauth2/token`,
                    type: 'label',
                    label: 'Oauth URL'
                  },
                  clietId: {
                    label: 'Client Id',
                    value: clientId,
                    type: 'label'
                  },
                  secret: {
                    label: 'Client Secret',
                    value: clientSecret,
                    type: 'label'
                  },
                  formLabel: {
                    label: 'Form Parameters',
                    type: 'header'
                  },
                  scopes: {
                    label: 'scopes',
                    value: scopes,
                    type: 'label'
                  },
                  authUrl: {
                    label: 'authUrl',
                    value: authUrl,
                    type: 'label'
                  }
                },
                invoiceUrl: true,
                serviceLabel: 'Custom API'
              }
            },

            {
              title: 'Orbis Configurations',
              parentId: 'orbis',
              conectivityMapping: {
                service: validationsForm.service,
                setService: setService,
                env: validationsForm.appId,
                app: 'bvd',
                parametersPosition: '',
                region: validationsForm.params.ariba.region,
                siteUrl: validationsForm.params.siteUrl,
                hideService: true,
                hideTestConnection: !validationsForm.params.moodys
                  .showTestConnection,
                title: '',
                fieldsTitle: '',
                fields: moodysConfigurations,
                invoiceUrl: true,
                serviceLabel: 'Bureau van Dijk'
              }
            }
          )
          connectivityParameters.push({
            title: 'General Configurations',
            parentId: 'GeneralConfigurations',
            conectivityMapping: {
              env: validationsForm.appId,
              app: 'bvd',
              ignoreIds: ['apiForm'],
              service: validationsForm.service,
              setService: setService,
              hideService: true,
              title: '',
              region: validationsForm.params.ariba.region,
              siteUrl: '',
              fieldsTitle: 'Integration',
              hideTestConnection: validationsForm.service === 'ariba',
              fields: generalParameters
            }
          })
          if (validationsForm.service === 'ariba') {
            connectivityParameters.push({
              title: 'External Approval API',
              parentId: 'externalApprovalApi',
              conectivityMapping: {
                service: validationsForm.service,
                setService: setService,
                env: validationsForm.appId,
                app: 'bvd',
                parametersPosition: '',
                region: validationsForm.params.ariba.region,
                siteUrl: validationsForm.params.siteUrl,
                hideService: true,
                hideTestConnection: !validationsForm.params.ariba
                  .externalApprovalApi.showTestConnection,
                title: '',
                fieldsTitle: '',
                fields: externalApprovalApi,
                invoiceUrl: true
              }
            })
            connectivityParameters.push({
              title: 'Supplier Data With Pagination',
              parentId: 'supplierDataWithPagination',
              conectivityMapping: {
                service: validationsForm.service,
                setService: setService,
                env: validationsForm.appId,
                app: 'bvd',
                parametersPosition: '',
                region: validationsForm.params.ariba.region,
                siteUrl: validationsForm.params.siteUrl,
                hideService: true,
                hideTestConnection: !validationsForm.params.ariba
                  .supplierDataWithPagination.showTestConnection,
                title: '',
                fieldsTitle: '',
                fields: supplierDataWithPagination,
                invoiceUrl: true
              }
            })
            connectivityParameters.push({
              title: 'Organization Import',
              parentId: 'organizationImport',
              conectivityMapping: {
                service: validationsForm.service,
                setService: setService,
                env: validationsForm.appId,
                app: 'bvd',
                parametersPosition: '',
                region: validationsForm.params.ariba.region,
                siteUrl: validationsForm.params.siteUrl,
                hideService: true,
                hideTestConnection: true,
                title: '',
                fieldsTitle: '',
                fields: organizationImport,
                invoiceUrl: true
              }
            })
            connectivityParameters.push({
              hide: () => {
                return !(
                  validationsForm.riskMapping &&
                  validationsForm.riskMapping.enabled
                )
              },
              title: 'Risk Configuration',
              parentId: 'riskApi',
              conectivityMapping: {
                service: validationsForm.service,
                setService: setService,
                env: validationsForm.appId,
                app: 'bvd',
                parametersPosition: '',
                region: validationsForm.params.ariba.region,
                siteUrl: validationsForm.params.siteUrl,
                hideService: true,
                hideTestConnection: !validationsForm.params.ariba.riskApi
                  .showTestConnection,
                title: '',
                fieldsTitle: '',
                fields: riskConfigurations,
                invoiceUrl: true
              }
            })
            connectivityParameters.push({
              title: 'Emails Job Configuration',
              parentId: 'emailsJobConfiguration',
              conectivityMapping: {
                service: validationsForm.service,
                setService: setService,
                env: validationsForm.appId,
                app: 'bvd',
                parametersPosition: '',
                region: validationsForm.params.ariba.region,
                siteUrl: validationsForm.params.siteUrl,
                hideService: true,
                hideTestConnection: true,
                title: '',
                fieldsTitle: '',
                fields: emailsJobConfiguration,
                invoiceUrl: true
              }
            })
          }
        }
        steps.push({
          stepName: 'Validations',
          stepComponent: AppMainMenu,
          stepId: 'validations',
          initialState: validationInitialState
        })
      }
      return steps
    }

    function returnWizardToDisplay() {
      return (
        <Wizard
          onStepChange={() => {
            // console.log(validationsForm)
            //setRefresh(!refresh)
          }}
          id="Wizard"
          color="primary"
          validate
          hideNavigation={displayMode === 'create'}
          title=""
          steps={getAdminSteps()}
          subtitle=""
          nextButtonClasses={classes.defaultButton}
          finishButtonClasses={classes.defaultButton}
          finishButtonClick={e => {
            if (displayMode === 'create') {
              if (e.validations && e.validations.env) onSubmit(e)
              else {
                setValidationMessage('Please Add Application Name')
                setSubmitError(true)
                setTimeout(() => {
                  setSubmitError(false)
                }, 5000)
              }
            } else {
              validationsForm.enabled = e.validations.mapping.enabled
              saveToDataBase(validationsForm, () => {}, 'Saved', true, true)
            }
          }}
        />
      )
    }

    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />
    }

    const handleCloseSuccess = (event, reason) => {
      if (reason === 'clickaway') {
        return
      }
      setSubmitSuccess(false)
    }

    const handleCloseError = (event, reason) => {
      if (reason === 'clickaway') {
        return
      }
      setSubmitError(false)
    }

    function onChangeSelectedApp(prop) {
      appChanged(prop)
      scrolltoTop()
    }

    return (
      <Fade in={true} timeout={0}>
        <div>
          {showDialog ? (
            <Dialog
              open={showDialog}
              fullWidth
              maxWidth={'md'}
              style={{ overflow: 'hidden', maxWidth: '' }}
            >
              <Grid container style={{ padding: '25px' }}>
                <Typography>Change App Name</Typography>
                <Grid item xs={12}>
                  <div style={{ float: 'right' }}>
                    <IconButton
                      onClick={() => {
                        setShowDialog(false)
                      }}
                    >
                      X
                    </IconButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                  >
                    <TextField
                      label="App Name"
                      variant="outlined"
                      className={classes.textField}
                      size="small"
                      value={appName}
                      onKeyPress={e => {}}
                      onChange={e => {
                        setAppName(e.target.value)
                        //onFieldChange('companyName', e.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} md={9}></Grid>
                <Grid item xs={6} md={3}>
                  <Button
                    style={{
                      marginTop: '50px',
                      marginRight: '50px',
                      float: 'right',
                      width: '80%'
                    }}
                    className={classes.defaultButton}
                    onClick={async () => {
                      setPageIsLoading(true)
                      setShowDialog(false)
                      let update = await updateAppName(appName, data => {
                        if (data.newApplist && data.newApplist.length > 0) {
                          dispatch(setAppList(data.newApplist))
                          getAppDetails(data.newSelectedApp)
                          let newValidationsForm = cloneObject(validationsForm)
                          newValidationsForm.appName = appName
                          changeValidationsForm(newValidationsForm)
                        }
                      })
                      //   console.log(update)
                      if (update && update.success) {
                        setValidationMessage(update.data.message)
                        setSubmitSuccess(true)
                        setTimeout(function() {
                          if (isMounted) {
                            setSubmitSuccess(false)
                          }
                        }, 5000)
                      } else if (update) {
                        setValidationMessage(update.data.message)
                        setSubmitError(true)
                        setTimeout(function() {
                          if (isMounted) {
                            setSubmitError(false)
                          }
                        }, 5000)
                        setShowDialog(true)
                      } else {
                        setValidationMessage(
                          'Something went wrong, please try again'
                        )
                        setSubmitError(true)
                        setTimeout(function() {
                          if (isMounted) {
                            setSubmitError(false)
                          }
                        }, 5000)
                        setShowDialog(true)
                      }
                      setPageIsLoading(false)
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Dialog>
          ) : (
            ''
          )}
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={3}
              className={classes.padding10}
            >
              <Card className={classes.appBar}>
                <CardHeader
                  subheader="Application List"
                  action={
                    <Tooltip title="Create new app">
                      <Button
                        color="primary"
                        onClick={() => {
                          onNewCreateIsCalled()
                          scrolltoTop()
                        }}
                      >
                        <Add />
                      </Button>
                    </Tooltip>
                  }
                ></CardHeader>
                <CardContent>
                  <List>
                    {appList
                      ? appList.map((prop, key) => {
                          return (
                            <ListItem
                              className={classes.listItem}
                              button
                              selected={selectedApp.value === prop.value}
                              key={key}
                              onClick={() => {
                                onChangeSelectedApp(prop)
                              }}
                            >
                              <ListItemAvatar>
                                {selectedApp.value === prop.value ? (
                                  <Avatar>
                                    <TouchApp />
                                  </Avatar>
                                ) : (
                                  <Avatar
                                    className={classes.clearAvatar}
                                  ></Avatar>
                                )}
                              </ListItemAvatar>
                              <ListItemText
                                primary={prop.label}
                                secondary={`Type: ${prop.type}`}
                              />
                            </ListItem>
                          )
                        })
                      : null}
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={9}
              className={classes.padding10}
            >
              {formIsLoading ? (
                <Spinner />
              ) : selectedApp ||
                displayMode === 'edit' ||
                displayMode === 'create' ? (
                <Grid container>
                  <Grid item xs={12}>
                    {React.cloneElement(returnWizardToDisplay())}
                  </Grid>
                </Grid>
              ) : (
                <Card>
                  <CardContent>
                    <div className={classes.typo}>
                      <div className={classes.note}>No apps created</div>
                      <h6>Click on the create button to start...</h6>
                    </div>
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Snackbar
              open={submitSuccess}
              autoHideDuration={6000}
              onClose={handleCloseSuccess}
            >
              <Alert onClose={handleCloseSuccess} severity="success">
                {validationMessage}
              </Alert>
            </Snackbar>
            <Snackbar
              open={submitError}
              autoHideDuration={6000}
              onClose={handleCloseError}
            >
              <Alert onClose={handleCloseError} severity="error">
                {validationMessage}
              </Alert>
            </Snackbar>
          </Grid>
        </div>
      </Fade>
    )
  }
  if (refresh) {
    return (
      <div id="test213">
        {pageIsLoading ? <Spinner /> : ''}
        {getView()}
      </div>
    )
  } else
    return (
      <div>
        <div>
          {pageIsLoading ? <Spinner /> : ''}
          {getView()}
        </div>
      </div>
    )
}
