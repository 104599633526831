import React, { useState, forwardRef, useMemo, useEffect, useRef } from 'react'
import countryList from 'react-select-country-list'
import Orbis from '../../views/Orbis/Orbis'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert';

// import InputLabel from '@material-ui/core/InputLabel'
// import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import FormControl from '@material-ui/core/FormControl'
//import Select from '@material-ui/core/Select'
import Select from 'react-select'

// Style
import { makeStyles } from '@material-ui/core/styles'
import suppliersCriteriaStyle from '../../assets/jss/views/SuppliersCriteria'
import { env } from '../../utils/Constants'

// Providers
import { useAuth } from '../../Providers/Auth'

import ArrowDownward from '@material-ui/icons/ArrowDownward'

import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'

import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
// import LinkIcon from '@material-ui/icons/Link'
import OrbisIcon from '../../assets/img/icons/orbisTransparent.png'
import AribaIcon from '../../assets/img/icons/aribaTransparent.png'
import AribaGrayIcon from '../../assets/img/icons/aribaGrayTransparent.png'

import { Close, OpenInBrowser } from '@material-ui/icons'

import CustomTreeTable from '../../components/CustomTreeTable/CustomTreeTable'
import { Spinner } from '../../components/Loader/Spinner'

// Function
// Api Calls
import apiApps from '../../services/apiApps'
import apiAppsProd from '../../services/apiAppsProd'

import { useSelector } from 'react-redux'

const getSupplierData =
  env === 'local' ? apiApps.getSupplierData : apiAppsProd.getSupplierData

const addOwnEntityCatalyst = 
  env === 'local' ? apiApps.addOwnEntityCatalyst : apiAppsProd.addOwnEntityCatalyst

const useStyles = makeStyles(suppliersCriteriaStyle)

export default function SuppliersCriteria(props) {
  const isMounted = useRef(false)
  const { permissions } = useAuth()
  const [isOwnEntityPermission, setIsOwnEntityPermission] = useState(false)

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      if (props.supplier) {
        onFieldChange('CompanyName', props.supplier.supplierName)
        if (props.supplier.supplierPostalCode)
          onFieldChange('PostCode', props.supplier.supplierPostalCode)
        if (props.supplier.supplierCityName)
          onFieldChange('City', props.supplier.supplierCityName)
        if (props.supplier.supplierCountryName) {
          const dataCountryDropDown = countryList().getData()
          dataCountryDropDown.forEach(({ value, label }) => {
            if (value === props.supplier.supplierCountryName) {
              onFieldChange('Country', { value, label })
            }
          })
        }

        if (props.supplier.supplierNationalId)
          onFieldChange('NationalId', props.supplier.supplierNationalId)
        //getInfoSearch()
      }
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  })

  useEffect(() => {
    for (let index = 0; index <= permissions.length; index++) {
      if (permissions[index] === 'Connector.OwnEntity') {
        setIsOwnEntityPermission(true)
        break;
      }
    }
  }, [permissions])
  
  const classes = useStyles()
  const selectedApp = useSelector(state => state.main.selectedApp)
  const [errorOwnEntity, setErrorOwnEntity] = useState('')
  const [data, setData] = useState(props.data ? props.data : [])
  const [bvdId, setBvdId] = React.useState(true)
  const [dialogOwnEntity, setDialogOwnEntity] = React.useState(false)
  const [row, setRow] = React.useState()
  const [isLoading, setIsLoading] = React.useState(false)
  const [searchInfo, setSearchInfo] = useState(
    props.searchInfo
      ? props.searchInfo
      : {
          CompanyName: '',
          Country: '',
          City: '',
          MatchPercent: 85,
          PostCode: '',
          NationalId: '',
          Exclusions: []
        }
  )
  const [ownEntityInfo, setOwnEntityInfo] = useState({
    CompanyName: '',
    StreetAdress1: '',
    StreetAdress2: '',
    StreetAdress3: '',
    StreetAdress4: '',
    PostCode: '',
    StateProvince: '',
    City: '',
    Country: '',
    TelephoneNumber: '',
    FaxNumber: '',
    ProductOption: []
  })

  const [isOwnEntityFormValid, setIsOwnEntityFormValid] = useState(false)

  useEffect(() => {

    if (ownEntityInfo.StreetAdress1 && ownEntityInfo.Country) {
      setIsOwnEntityFormValid(true)
    } else {
      setIsOwnEntityFormValid(false)
    }
    
  }, [ownEntityInfo.StreetAdress1, ownEntityInfo.Country])
  

  const [infoIsLoading, setInfoIsLoading] = useState(false)
  const countryListOptions = useMemo(() => countryList().getData(), [])

  const [realm, setRealm] = React.useState()
  const [region, setRegion] = React.useState('')
  const [showDialog, setShowDialog] = React.useState(false)
  async function getInfoSearch() {
    if (searchInfo.Country.value) {
      setInfoIsLoading(true)
      let response
      response = await getSupplierData(searchInfo)
      // debugger
      if (response && response.success) {
        setData(response.data.content)
        if (props.setPropData) props.setPropData(response.data.content)
        setRealm(response.data.realm)
        setRegion(response.data.region)
      } else {
        setData([])
      }
      setInfoIsLoading(false)
    } else {
      props.setValidationMessage('Country is Required')
      props.setSubmitError(true)
    }
  }

  async function addOwnEntity() {
    setIsLoading(true)
    let response
    const objectEntity = {
      NAME: props.supplier.supplierName,
      ADDRESS_LINE1: ownEntityInfo.StreetAdress1,
      ...(ownEntityInfo.StreetAdress2 !== '' ? {ADDRESS_LINE2: ownEntityInfo.StreetAdress2} : {}),
      ...(ownEntityInfo.StreetAdress3 !== '' ? {ADDRESS_LINE3: ownEntityInfo.StreetAdress3} : {}),
      ...(ownEntityInfo.StreetAdress4 !== '' ? {ADDRESS_LINE4: ownEntityInfo.StreetAdress4} : {}),
      ...(ownEntityInfo.PostCode !== '' ? {POSTCODE: ownEntityInfo.PostCode} : {}),
      ...(ownEntityInfo.StateProvince !== '' ? {US_STATE: ownEntityInfo.StateProvince} : {}),
      ...(ownEntityInfo.City !== '' ? {CITY: ownEntityInfo.City} : {}),
      ...(ownEntityInfo.Country.value !== '' ? {COUNTRY_ISO_CODE: ownEntityInfo.Country.value} : {}),
      ...(ownEntityInfo.TelephoneNumber !== '' ? {PHONE_NUMBER: ownEntityInfo.TelephoneNumber} : {}),
      ...(ownEntityInfo.FaxNumber !== '' ? {FAX_NUMBER: ownEntityInfo.FaxNumber} : {})
    }
    if(ownEntityInfo.ProductOption[0] === 'Add to Supply Chain Catalyst' && ownEntityInfo.ProductOption.length === 1) {
      response = await addOwnEntityCatalyst('Supply', objectEntity, props.supplier.supplierId)
    } else if(ownEntityInfo.ProductOption[0] === 'Add to Compliance Catalyst' && ownEntityInfo.ProductOption.length === 1) {
      response = await addOwnEntityCatalyst('Compliance', objectEntity, props.supplier.supplierId)
    } else {
      response = await addOwnEntityCatalyst('SupplyCompliance', objectEntity, props.supplier.supplierId)
    }

    if(response && response.data && response.data.success === true) {
      setIsLoading(false)
      cleanOwnObjectEntityInfo()
      setDialogOwnEntity(false)
      window.location.reload()
    } else {
      setIsLoading(false)
      setErrorOwnEntity('An error was occurred, please try again or check the own entity request information.')
    }
  }

  function cleanOwnObjectEntityInfo(){
    setOwnEntityInfo({
      CompanyName: '',
      StreetAdress1: '',
      StreetAdress2: '',
      StreetAdress3: '',
      StreetAdress4: '',
      PostCode: '',
      StateProvince: '',
      City: '',
      Country: '',
      TelephoneNumber: '',
      FaxNumber: '',
      ProductOption: []
    })
  }

  const tableIcons = {
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  }
  const columns = [
    { title: 'BvD ID', field: 'BvDId' },
    { title: 'Score', field: 'Score' },
    { title: 'Legal Status', field: 'Status' },
    { title: 'Name', field: 'Name' },
    { title: 'Address Type', field: 'Address_Type' },
    { title: 'Address', field: 'Address' },
    { title: 'City', field: 'City' },
    { title: 'Country*', field: 'Country' },
    { title: 'National ID label', field: 'NationalIdLabel' },
    { title: 'National ID', field: 'National_Id' }
  ]

  function onFieldChange(field, value, check = false) {
    if (check) {
      if (value) {
        searchInfo.Exclusions.push(field)
      } else {
        let selected = searchInfo.Exclusions.findIndex(x => x === field)
        if (selected !== -1) {
          searchInfo.Exclusions.splice(selected, 1)
        }
      }
    } else {
      searchInfo[field] = value
    }
    setSearchInfo({ ...searchInfo })
    if (props.setSearchInfo) props.setSearchInfo({ ...searchInfo })
  }

  function onFieldOwnEntityChange(field, value, check = false) {
    if (check) {
      if (value) {
        ownEntityInfo.ProductOption.push(field)
      } else {
        let selected = ownEntityInfo.ProductOption.findIndex(x => x === field)
        if (selected !== -1) {
          ownEntityInfo.ProductOption.splice(selected, 1)
        }
      }
    } else {
      ownEntityInfo[field] = value
    }
    setOwnEntityInfo({ ...ownEntityInfo })
    if (props.ownEntityInfo) props.setOwnEntityInfo({ ...ownEntityInfo })
  }

  let actions = [
    rowData => ({
      icon: () => <img style={{ width: '25px' }} src={OrbisIcon} alt="" />,
      tooltip: 'See in Orbis',
      onClick: (event, rowData) => {
        setShowDialog(true)
        setBvdId(rowData.BvDId)
        setRow(rowData)
      }
    }),
    rowData => ({
      icon: () => (
        <img
          style={{ width: '25px' }}
          src={rowData.SupplierId ? AribaIcon : AribaGrayIcon}
          alt=""
        />
      ),
      disabled: rowData.SupplierId === undefined,
      tooltip: 'Go to Ariba',
      onClick: (event, rowData) => {
        if (rowData.SupplierId) {
          const urlOpenAriba = region === 'us' ? 's1.ariba.com' : region === 'au' ? 's1.au.cloud.ariba.com' : 's1.eu.cloud.ariba.com'
          window.open(
            `https://${urlOpenAriba}/Sourcing/Main/ad/viewSupplier360Profile/ariba.sourcing.dashboard.SMMainActionsController?realm=${realm}&smVendorId=${rowData.SupplierId}`
          )
        }
          
      }
    })
  ]
  if (props.actions) {
    props.actions.forEach(element => {
      let newElement = element()

      if (newElement.hideDialog) {
        let onClick = newElement.onClick
        newElement.onClick = (event, rowData) => {
          setShowDialog(false)
          onClick(event, rowData)
        }
        actions.push(newElement)
      } else actions.push(element)
    })
  }

  return (
    <div>
      {infoIsLoading ? <Spinner /> : ''}
      <Dialog
        open={showDialog}
        fullWidth
        maxWidth={'xl'}
        style={{ overflow: 'hidden', maxWidth: '' }}
      >
        <div>
          <IconButton style={{ float: 'right' }}>
            <Close
              onClick={() => {
                setShowDialog(false)
              }}
            />
          </IconButton>
          <IconButton style={{ float: 'left' }}>
            <OpenInBrowser
              onClick={() => {
                if (props.history) {
                  props.history.push(`/admin/orbis?bvdId=${bvdId}`)
                }
              }}
            />
          </IconButton>
        </div>
        <div>
          <Grid container>
            {props.actions
              ? props.actions.map((actionFunction, index) => {
                  let action = actionFunction
                  if (typeof actionFunction === 'function')
                    action = actionFunction(row)
                  return (
                    <Grid key={index} item xs={12 / props.actions.length}>
                      {
                        <Tooltip title={action.tooltip}>
                          <IconButton
                            onClick={e => {
                              if (action.hideDialog) {
                                setShowDialog(false)
                              }
                              action.onClick(e, row)
                            }}
                          >
                            {action.icon()}
                          </IconButton>
                        </Tooltip>
                      }
                    </Grid>
                  )
                })
              : ''}
          </Grid>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <Orbis bvdId={bvdId} />
        </div>
      </Dialog>
      { props.supplier ? (<Dialog
        open={dialogOwnEntity}
        fullWidth
        maxWidth={'xl'}
        style={{ overflow: 'hidden', maxWidth: '' }}
      >
        {isLoading ? <Spinner /> : ''}
        <div style={{ margin: '25px 25px 20px' }}>
          <Typography variant="h4" gutterBottom>
            Add a company
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <TextField
                label="Company Name"
                variant="outlined"
                fullWidth
                disabled
                size="small"
                value={props.supplier.supplierName ? props.supplier.supplierName : ''}
                onChange={e =>
                  onFieldOwnEntityChange('CompanyName', e.target.value)
                }
              />
              <Divider variant="middle" style={{ marginTop: 30 }} />
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Street Address
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Click to add your own information"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={ownEntityInfo.StreetAdress1}
                    onChange={e =>
                      onFieldOwnEntityChange('StreetAdress1', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Click to add your own information"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={ownEntityInfo.StreetAdress2}
                    onChange={e =>
                      onFieldOwnEntityChange('StreetAdress2', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Click to add your own information"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={ownEntityInfo.StreetAdress3}
                    onChange={e =>
                      onFieldOwnEntityChange('StreetAdress3', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Click to add your own information"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={ownEntityInfo.StreetAdress4}
                    onChange={e =>
                      onFieldOwnEntityChange('StreetAdress4', e.target.value)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Postcode
              </Typography>
              <TextField
                label="Click to add your own information"
                variant="outlined"
                fullWidth
                size="small"
                value={ownEntityInfo.PostCode}
                onChange={e =>
                  onFieldOwnEntityChange('PostCode', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                State or province (in US or Canada)
              </Typography>
              <TextField
                label="Click to add your own information"
                variant="outlined"
                fullWidth
                size="small"
                value={ownEntityInfo.StateProvince}
                onChange={e => {
                  onFieldOwnEntityChange('StateProvince', e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                City
              </Typography>
              <TextField
                label="Click to add your own information"
                variant="outlined"
                fullWidth
                size="small"
                value={ownEntityInfo.City}
                onChange={e => onFieldOwnEntityChange('City', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Country
              </Typography>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <Select
                  style={{ width: '100%' }}
                  placeholder={'Country*'}
                  options={countryListOptions}
                  value={ownEntityInfo.Country}
                  onChange={e => onFieldOwnEntityChange('Country', e)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Telephone number
              </Typography>
              <TextField
                label="Click to add your phone number(s) separated by ,"
                variant="outlined"
                fullWidth
                size="small"
                value={ownEntityInfo.TelephoneNumer}
                onChange={e =>
                  onFieldOwnEntityChange('TelephoneNumber', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Fax number
              </Typography>
              <TextField
                label="Click to add your phone number(s) separated by ,"
                variant="outlined"
                fullWidth
                size="small"
                value={ownEntityInfo.FaxNumer}
                onChange={e =>
                  onFieldOwnEntityChange('FaxNumber', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item md={12}>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    onChange={e =>
                      onFieldOwnEntityChange(
                        'Add to Compliance Catalyst',
                        e.target.checked,
                        true
                      )
                    }
                    checked={
                      ownEntityInfo.ProductOption.find(
                        value => value === 'Add to Compliance Catalyst'
                      )
                        ? true
                        : false
                    }
                    label="Add to Compliance Catalyst"
                    disabled={!selectedApp.catalystMapping}
                  />
                </Grid>
                <Grid item md={12}>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    onChange={e =>
                      onFieldOwnEntityChange(
                        'Add to Supply Chain Catalyst',
                        e.target.checked,
                        true
                      )
                    }
                    checked={
                      ownEntityInfo.ProductOption.find(
                        value => value === 'Add to Supply Chain Catalyst'
                      )
                        ? true
                        : false
                    }
                    label="Add to Supply Chain Catalyst"
                    disabled={!selectedApp.supplyChainMapping}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
            {
              errorOwnEntity !== '' ? (
                <Alert severity="error">{errorOwnEntity}</Alert>
              ) : (<Alert severity="info">Please fill in the information on the form and verify that you have at least one address entered.</Alert>)
            }
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                className={classes.defaultButton}
                style={{ width: '100%' }}
                onClick={() => addOwnEntity()}
                disabled={!isOwnEntityFormValid}
              >
                Confirm
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                className={classes.buttonDanger}
                style={{ width: '100%' }}
                onClick={() => {
                  setDialogOwnEntity(false);
                  cleanOwnObjectEntityInfo();
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
          
        </div>
      </Dialog>):null}
      <h3 className={classes.h3}>
        {props.title ? props.title : 'Search Suppliers by Criteria'}
      </h3>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Card className={classes.card} id={'cardId'}>
            {props.cardTitle ? props.cardTitle : ''}
            <CardContent>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item md={12}>
                      <TextField
                        label="Company Name"
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                        value={searchInfo.CompanyName}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            getInfoSearch()
                          }
                        }}
                        onChange={e => {
                          onFieldChange('CompanyName', e.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        label="National Id"
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                        value={searchInfo.NationalId}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            getInfoSearch()
                          }
                        }}
                        onChange={e => {
                          onFieldChange('NationalId', e.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <div className={classes.checkBox}>
                        Exclusions:
                        <Grid container>
                          <Grid item md={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  onChange={e =>
                                    onFieldChange(
                                      'Exclude Branch Locations',
                                      e.target.checked,
                                      true
                                    )
                                  }
                                  checked={
                                    searchInfo.Exclusions.find(
                                      value =>
                                        value === 'Exclude Branch Locations'
                                    )
                                      ? true
                                      : false
                                  }
                                />
                              }
                              label="Exclude Branch Locations"
                            />
                          </Grid>
                          <Grid item md={12}>
                            <FormControlLabel
                              control={<Checkbox color="primary" />}
                              label="Exclude Inactive"
                              onChange={e =>
                                onFieldChange(
                                  'Exclude Inactive',
                                  e.target.checked,
                                  true
                                )
                              }
                              checked={
                                searchInfo.Exclusions.find(
                                  value => value === 'Exclude Inactive'
                                )
                                  ? true
                                  : false
                              }
                            />
                          </Grid>
                          <Grid item md={12}>
                            <FormControlLabel
                              control={<Checkbox color="primary" />}
                              label="Exclude Unlisted"
                              onChange={e =>
                                onFieldChange(
                                  'Exclude Unlisted',
                                  e.target.checked,
                                  true
                                )
                              }
                              checked={
                                searchInfo.Exclusions.find(
                                  value => value === 'Exclude Unlisted'
                                )
                                  ? true
                                  : false
                              }
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={6}>
                      <TextField
                        label="City"
                        variant="outlined"
                        size="small"
                        required={true}
                        className={classes.textField}
                        value={searchInfo.City}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            getInfoSearch()
                          }
                        }}
                        onChange={e => {
                          onFieldChange('City', e.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Postal Code"
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                        value={searchInfo.PostCode}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            getInfoSearch()
                          }
                        }}
                        onChange={e => {
                          onFieldChange('PostCode', e.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        variant="outlined"
                        className={classes.selectField}
                      >
                        <Select
                          menuPortalTarget={document.getElementById('cardId')}
                          style={{ width: '100%' }}
                          placeholder={'Country*'}
                          options={countryListOptions}
                          value={searchInfo.Country}
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 })
                          }}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              getInfoSearch()
                            }
                          }}
                          onChange={e => {
                            onFieldChange('Country', e)
                          }}
                        ></Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <Tooltip title={'Match Percentge'}>
                        <FormControl
                          variant="outlined"
                          className={classes.selectField}
                        >
                          <Select
                            menuPortalTarget={document.getElementById('cardId')}
                            style={{ width: '100%' }}
                            placeholder={'Match Percentage'}
                            options={[
                              { label: '1%', value: 1 },
                              { label: '2%', value: 2 },
                              { label: '3%', value: 3 },
                              { label: '4%', value: 4 },
                              { label: '5%', value: 5 },
                              { label: '6%', value: 6 },
                              { label: '7%', value: 7 },
                              { label: '8%', value: 8 },
                              { label: '9%', value: 9 },
                              { label: '10%', value: 10 },
                              { label: '11%', value: 11 },
                              { label: '12%', value: 12 },
                              { label: '13%', value: 13 },
                              { label: '14%', value: 14 },
                              { label: '15%', value: 15 },
                              { label: '16%', value: 16 },
                              { label: '17%', value: 17 },
                              { label: '18%', value: 18 },
                              { label: '19%', value: 19 },
                              { label: '20%', value: 20 },
                              { label: '21%', value: 21 },
                              { label: '22%', value: 22 },
                              { label: '23%', value: 23 },
                              { label: '24%', value: 24 },
                              { label: '25%', value: 25 },
                              { label: '26%', value: 26 },
                              { label: '27%', value: 27 },
                              { label: '28%', value: 28 },
                              { label: '29%', value: 29 },
                              { label: '30%', value: 30 },
                              { label: '31%', value: 31 },
                              { label: '32%', value: 32 },
                              { label: '33%', value: 33 },
                              { label: '34%', value: 34 },
                              { label: '35%', value: 35 },
                              { label: '36%', value: 36 },
                              { label: '37%', value: 37 },
                              { label: '38%', value: 38 },
                              { label: '39%', value: 39 },
                              { label: '40%', value: 40 },
                              { label: '41%', value: 41 },
                              { label: '42%', value: 42 },
                              { label: '43%', value: 43 },
                              { label: '44%', value: 44 },
                              { label: '45%', value: 45 },
                              { label: '46%', value: 46 },
                              { label: '47%', value: 47 },
                              { label: '48%', value: 48 },
                              { label: '49%', value: 49 },
                              { label: '50%', value: 50 },
                              { label: '51%', value: 51 },
                              { label: '52%', value: 52 },
                              { label: '53%', value: 53 },
                              { label: '54%', value: 54 },
                              { label: '55%', value: 55 },
                              { label: '56%', value: 56 },
                              { label: '57%', value: 57 },
                              { label: '58%', value: 58 },
                              { label: '59%', value: 59 },
                              { label: '60%', value: 60 },
                              { label: '61%', value: 61 },
                              { label: '62%', value: 62 },
                              { label: '63%', value: 63 },
                              { label: '64%', value: 64 },
                              { label: '65%', value: 65 },
                              { label: '66%', value: 66 },
                              { label: '67%', value: 67 },
                              { label: '68%', value: 68 },
                              { label: '69%', value: 69 },
                              { label: '70%', value: 70 },
                              { label: '71%', value: 71 },
                              { label: '72%', value: 72 },
                              { label: '73%', value: 73 },
                              { label: '74%', value: 74 },
                              { label: '75%', value: 75 },
                              { label: '76%', value: 76 },
                              { label: '77%', value: 77 },
                              { label: '78%', value: 78 },
                              { label: '79%', value: 79 },
                              { label: '80%', value: 80 },
                              { label: '81%', value: 81 },
                              { label: '82%', value: 82 },
                              { label: '83%', value: 83 },
                              { label: '84%', value: 84 },
                              { label: '85%', value: 85 },
                              { label: '86%', value: 86 },
                              { label: '87%', value: 87 },
                              { label: '88%', value: 88 },
                              { label: '89%', value: 89 },
                              { label: '90%', value: 90 },
                              { label: '91%', value: 91 },
                              { label: '92%', value: 92 },
                              { label: '93%', value: 93 },
                              { label: '94%', value: 94 },
                              { label: '95%', value: 95 },
                              { label: '96%', value: 96 },
                              { label: '97%', value: 97 },
                              { label: '98%', value: 98 },
                              { label: '99%', value: 99 },
                              { label: '100%', value: 100 }
                            ]}
                            value={{
                              label: searchInfo.MatchPercent + '%',
                              value: searchInfo.MatchPercent
                            }}
                            styles={{
                              // Fixes the overlapping problem of the component
                              menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                            onChange={e => {
                              onFieldChange('MatchPercent', e.value)
                            }}
                          ></Select>
                        </FormControl>
                      </Tooltip>
                      {/*<TextField
                        type="number"
                        label="Match Percentage"
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                        value={searchInfo.MatchPercent}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            getInfoSearch()
                          }
                        }}
                        onChange={e => {
                          onFieldChange('MatchPercent', e.target.value)
                        }} 
                        />
                      */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Divider variant="middle" />
                  <Button
                    disabled={!searchInfo.CompanyName}
                    className={classes.defaultButton}
                    onClick={() => getInfoSearch()}
                  >
                    Search
                  </Button>
                  {props.addOwnEntity && isOwnEntityPermission ? (
                    <Button
                      disabled={!searchInfo.CompanyName}
                      className={classes.defaultButton}
                      onClick={() => setDialogOwnEntity(true)}
                    >
                      Add own entity
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <div>
            <h4 style={{ margin: '50px 0 20px 0', color: "#0A1264", fontFamily: 'GT Maerica Standard' }}>Your search results:</h4>

            <CustomTreeTable
              defaultOrderBy={'Score'}
              defaultOrder={'desc'}
              icons={tableIcons}
              columns={columns}
              data={data}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                showTitle: false
              }}
              mainNode={['BvDId', 'Score', 'Name']}
              treeTableWidth={1410}
              actions={actions}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
