import { useQuery, useQueryClient, useMutation } from 'react-query'
import { getIamService } from '../apis/iam'

export function useGetTenants () {
  const queryClient = useQueryClient()

  return useQuery('tenants', () =>
    getIamService()
      .get('/tenants')
      .then(result => {
        return result.data.map(tenant => {
          const newData = {
            ...tenant,
            status: tenant.enabled ? 'Enabled' : 'Disabled'
          }
          queryClient.setQueryData(['tenants', newData.tenantId], newData)
          return newData
        })
      })
  )
}

export function useUpdateTenant (tenantId) {
  const queryClient = useQueryClient()
  return useMutation(
    values => getIamService().put(`/tenants/${tenantId}`, values),
    {
      onMutate: async newTenant => {
        await queryClient.cancelQueries(['tenants', newTenant.tenantId])

        // Snapshot the previous value
        const previousTenant = queryClient.getQueryData([
          'tenants',
          newTenant.tenantId
        ])

        // Optimistically update to the new value
        queryClient.setQueryData(['tenants', newTenant.tenantId], newTenant)

        const previousTenants = queryClient.getQueryData('tenants')

        if (previousTenants) {
          queryClient.setQueryData(['tenants'], oldTenants => {
            return oldTenants.map(tenant => {
              if (tenant.tenantId === newTenant.tenantId) {
                return newTenant
              }
              return tenant
            })
          })
        }

        // Return a rollback function
        return () => {
          queryClient.setQueryData(
            ['tenants', newTenant.tenantId],
            previousTenant
          )
          queryClient.setQueryData(['tenants'], previousTenants)
        }
      }
    }
  )
}

export function useGetTenantById (tenantId) {
  return useQuery(['tenants', tenantId], () =>
    getIamService()
      .get(`/tenants/${tenantId}`)
      .then(result => {
        const newData = {
          ...result.data,
          status: result.enabled ? 'Enabled' : 'Disabled'
        }
        return newData
      })
  )
}

export function useGetTenantConfigure ({ tenantId, hasAwsConfigure }) {
  return useQuery(
    ['infoTenant', tenantId],
    () =>
      getIamService()
        .get(`/tenants/auth/${tenantId}`)
        .then(result => {
          return { ...result.data, status: result.status }
        })
        .catch(e => {}),
    {
      enabled: !hasAwsConfigure
    }
  )
}

export function useCreateTenant () {
  return useMutation(values => getIamService().post('/tenants', values))
}
