import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import cx from 'classnames'
import { Auth } from 'aws-amplify'

// Providers
import { useAuth, getTenant } from '../../Providers/Auth'
import { getIamService } from '../../apis/iam'
import { useMutation } from 'react-query'

// Core
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import { Fade, Snackbar } from '@material-ui/core'

// Components
import CustomListItemIcon from '../../components/CustomListItemIcon'
import Select from 'react-select'
// Logos
//import logoOrbis from '../../assets/img/icons/orbisTransparent.png'
import logoOrbis from '../../assets/img/moodysLogo.png'

// Routes
import initialRoutes from '../../routes.js'

// Style
import { makeStyles } from '@material-ui/core/styles'
import adminStyle from '../../assets/jss/layout/Admin'

// View
import { Spinner } from '../../components/Loader/Spinner'
//redux
import { setSelectedApp, setRefresh } from '../../store/MainState'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '@material-ui/lab/Alert'

let currentWindow
const useStyles = makeStyles(adminStyle)
function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}
function checkUser(openWindow) {
  if (window.localStorage.getItem('externalLogin'))
    setTimeout(async () => {
      checkUser(openWindow)
    }, 500)
  else {
    if (openWindow) openWindow.close()
    window.location.href = window.location.origin
  }
}

async function signOut() {
  if (inIframe()) {
    if (currentWindow) currentWindow.close()
    currentWindow = window.open(
      window.location.origin + '/admin/logout',
      '_blank',
      'location=yes,height=570,width=520,scrollbars=yes,status=yes'
    )

    checkUser(currentWindow)
  } else {
    await Auth.signOut()
    localStorage.clear()
    localStorage.setItem('logginOut', true)
    if (window.location.href !== window.location.origin + '/auth/login-page')
      window.location.href = window.location.origin + '/auth/login-page'
  }
}
const getRoutes = (routes, permissions, props) => {
  let returnRoutes = []
  routes.forEach((prop, key) => {
    if (prop.collapse) {
      returnRoutes.concat(getRoutes(prop.views, permissions, props))
    }
    if (prop.layout === '/admin') {
      if (checkPermissions(permissions, prop.permission)) {
        if (prop.childrenRoutes && prop.childrenRoutes.length > 0) {
          prop.childrenRoutes.forEach(element => {
            let childReturnRoutes = getRoutes([element], permissions, props)
            if (childReturnRoutes)
              childReturnRoutes.forEach(newRoute => {
                returnRoutes.push(newRoute)
              })
          })
        } else {
          returnRoutes.push(
            <Route path={prop.layout + prop.path} key={key}>
              {prop.component(props)}
            </Route>
          )
        }
      } else {
        return null
      }
    } else {
      return null
    }
  })
  return returnRoutes
}

function checkPermissions(permissions, route) {
  if (!route) return true
  let arrayRoute = route.split('.')
  for (let item in permissions) {
    let permission = permissions[item]
    if (permission === route) return true
    for (let i = 0; i < arrayRoute.length; i++) {
      if (permission[i] === arrayRoute[i]) {
        if (arrayRoute.length === i + 1) return true
      } else if (permission[i] === '*') return true
      else i = arrayRoute.length
    }
  }
  return false
}

const activeRoute = routeName => {
  return window.location.href.indexOf(routeName) > -1 ? 'active' : ''
}

function AdminLayout(props) {
  const classes = useStyles()
  const { permissions } = useAuth()

  const hideSideBarLocations = []

  const [hideSideBar, setHideSideBar] = React.useState(
    hideSideBarLocations.includes(props.location.pathname)
  )

  const [userId, setUserId] = React.useState('')
  const [submitSuccess, setSubmitSuccess] = React.useState(false)

  const [submitError, setSubmitError] = React.useState(false)
  const [validationMessage, setValidationMessage] = React.useState('')
  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSubmitSuccess(false)
  }

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSubmitError(false)
  }
  const dispatch = useDispatch()
  const selectedApp = useSelector(state => state.main.selectedApp)
  const appList = useSelector(state => state.main.appList)
  const mainRefresh = useSelector(state => state.main.refresh)

  const [userData, setUserData] = React.useState({})

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black'
    })
  }

  useEffect(() => {
    const authConfigLS = localStorage.getItem(
      `CognitoIdentity.Configure.${getTenant()}`
    )
    const authConfig = JSON.parse(authConfigLS)
    const userEmail = localStorage.getItem(
      `CognitoIdentityServiceProvider.${authConfig.Auth.userPoolWebClientId}.LastAuthUser`
    )
    const userDataPermission = localStorage.getItem(
      `CognitoIdentityServiceProvider.${authConfig.Auth.userPoolWebClientId}.${userEmail}.userData`
    )

    const dataUserLogged = JSON.parse(userDataPermission)
    dataUserLogged.UserAttributes.forEach(({ Name, Value }) => {
      if (Name === 'sub') setUserId(Value)
    })

    if (userId !== '') {
      getIamService()
        .get(`/tenants/${getTenant()}/users/${userId}`)
        .then(result => {
          setUserData(result.data)
        })
    }
  }, [userId, dispatch]);

  const updateUser = useMutation(values =>
    {
      getIamService().put(`/users/${userId}`, values)
    }
  )

  function onChangeSelectedApp(e) {
    dispatch(setSelectedApp(e))
    dispatch(setRefresh(true))
    setTimeout(() => {
      dispatch(setRefresh(false))
    }, 500)

    const newData = {
      ...userData,
      lastApplication: e
    }
    updateUser.mutate(newData)
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar} style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor: 'white'}}>
          <img
            alt="Orbis | Company information across the globe | BvD"
            src={logoOrbis}
            style={{ height: '60px', width: '235px' }}
          />
          {/* <span className={classes.titleBar}>
            bureau van dijkd
            <span
              className={classes.titleBar}
              style={{ color: 'GrayText', margin: '0' }}
            >
              {' connector'}
            </span>
            <span className={classes.titleBar} style={{ margin: '0' }}>
              {' for SAP Ariba'}
            </span>
          </span> */}
          <form autoComplete="off" style={{ width: '40%' }}>
            <div style={{ width: '90%' }}>
              <Select
                styles={customStyles}
                value={selectedApp}
                options={appList}
                onChange={e => {
                  onChangeSelectedApp(e)
                }}
              />
            </div>
          </form>

          <Snackbar
            open={submitSuccess}
            autoHideDuration={6000}
            onClose={handleCloseSuccess}
          >
            <Alert onClose={handleCloseSuccess} severity="success">
              {validationMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            open={submitError}
            autoHideDuration={6000}
            onClose={handleCloseError}
          >
            <Alert onClose={handleCloseError} severity="error">
              {validationMessage}
            </Alert>
          </Snackbar>

          <Button className={classes.defaultButton} onClick={() => signOut()}>
            Sign out
          </Button>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={hideSideBar ? '' : classes.drawer}
        PaperProps={{
          onMouseEnter: () => {
            setHideSideBar(false)
          },
          onMouseLeave: () => {
            if (hideSideBarLocations.includes(props.location.pathname))
              setHideSideBar(true)
            else setHideSideBar(false)
          },
          style: {
            overflow: 'visible',
            backgroundColor: hideSideBar ? 'transparent' : '#0A1264',
            border: 'none',
            width: hideSideBar ? '' : '55px'
          }
        }}
      >
        <div className={classes.toolbar}></div>
        {!hideSideBar ? (
          <Fade in={true} timeout={350}>
            <List>
              {initialRoutes.map((route, index) => {
                if (route.redirect) {
                  return null
                }
                if (checkPermissions(permissions, route.permission)) {
                  return (
                    <CustomListItemIcon
                      key={index}
                      route={route}
                      classes={classes}
                      className={
                        classes.linkItem +
                        ' ' +
                        cx({
                          [classes.linkItemActive]: activeRoute(
                            route.layout + route.path
                          )
                        })
                      }
                      permissions={permissions}
                    />
                  )
                } else {
                  return null
                }
              })}
            </List>
          </Fade>
        ) : (
          ''
        )}
      </Drawer>
      {mainRefresh ? (
        <Spinner />
      ) : (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            {getRoutes(initialRoutes, permissions, {
              setValidationMessage: setValidationMessage,
              setSubmitError: setSubmitError,
              setSubmitSuccess: setSubmitSuccess,
              ...props
            })}
            <Redirect from="/admin" to="/admin/assignBvD" />
          </Switch>
        </main>
      )}
    </div>
  )
}

const AuthStateApp = props => {
  useEffect(() => {})
  const { user } = useAuth()
  if (user) {
    //alert()
  }
  return user ? <AdminLayout {...props} /> : <Spinner />
}

export default AuthStateApp
export { signOut, checkPermissions }
