import React from 'react'
import { Link } from 'react-router-dom'
import ListItemIcon from '@material-ui/core/ListItemIcon'

// Core

import ListItem from '@material-ui/core/ListItem'
import { Fade, Grid, Typography } from '@material-ui/core'
import { MenuItem } from '@material-ui/core'
import CustomPopover from './CustomPopover'

import { checkPermissions} from '../../src/layout/Admin/Admin'

export default function CustomListItemIcon (props) {
  let route = props.route
  let childrenRoutes
  if (route) childrenRoutes = route.childrenRoutes

  return (
    <div
      style={{
        width: '100%',
        zIndex: '9999',
        transitionDuration: '0.5s'
      }}
    >
      {route.addEmptyTop ? (
        <div
          style={{
            width: '100%',
            zIndex: '9999',
            transitionDuration: '0.5s'
          }}
        >
          <div style={{ width: '100%', zIndex: '9999' }} key={route.path + '2'}>
            <ListItem button>
              <ListItemIcon style={{ width: '100%' }}>
                <Grid
                  container
                  style={{ ...route.style, backgroundColor: 'transparent' }}
                >
                  <Grid item xs={12} style={{ margin: 'auto' }}>
                    <div></div>
                  </Grid>
                </Grid>
              </ListItemIcon>
            </ListItem>
          </div>
        </div>
      ) : (
        ''
      )}
      {!childrenRoutes ? (
        <div
          /* onMouseEnter={e => {
            e.currentTarget.style.width = '500%'
            e.currentTarget.firstElementChild.firstElementChild.firstElementChild.firstElementChild.lastElementChild.style.display =
              'block'
            e.currentTarget.firstElementChild.firstElementChild.firstElementChild.firstElementChild.lastChild.firstElementChild.style.display =
              'block'
          }}*/
          style={{
            width: '100%',
            zIndex: '9999',
            transitionDuration: '0.5s'
          }}
          /* onMouseLeave={e => {
            e.currentTarget.style.width = '100%'
            e.currentTarget.firstElementChild.firstElementChild.firstElementChild.firstElementChild.lastElementChild.style.display =
              'none'
            e.currentTarget.firstElementChild.firstElementChild.firstElementChild.firstElementChild.lastChild.firstElementChild.style.display =
              'none'
          }}*/
        >
          <Link
            style={{ width: '100%', zIndex: '9999', textDecoration: 'none' }}
            onMouseEnter={e => {
              // props.setExpandItem(true)
            }}
            onMouseLeave={e => {
              //  props.setExpandItem(false)
            }}
            key={route.path}
            to={route.layout + route.path}
          >
            <ListItem
              button
              className={props.className}
              style={route.listItemStyle}
            >
              <ListItemIcon style={{ width: '100%' }}>
                <Grid container style={route.style}>
                  <Grid item xs={12} style={{ margin: 'auto' }}>
                    <div style={route.imageDivStyle}>
                      <img
                        alt="home"
                        src={route.icon}
                        width="30px"
                        height="30px"
                      />
                    </div>
                  </Grid>
                  {/*  <Fade
                    in={true}
                    timeout={500}
                    style={{
                      display: 'none'
                    }}
                  >
                    <Grid item xs={8}>
                      <Typography
                        style={{
                          color: 'white',
                          textAlign: 'left'
                        }}
                      >
                        {route.name}
                      </Typography>
                     
                    </Grid>
                  </Fade> */}
                </Grid>
              </ListItemIcon>
            </ListItem>
          </Link>
        </div>
      ) : (
        <div
          style={{ width: '100%', zIndex: '9999', transitionDuration: '5s' }}
        >
          <CustomPopover
            className={props.className}
            iconClickId={route.name + 'icon'}
            image={
              <Grid container>
                <img alt="home" src={route.icon} width="34px" height="34px" />
              </Grid>
            }
            subMenu={
              <div style={{ width: '250px' }}>
                <div
                  style={{
                    backgroundColor: '#030f59',
                    height: '31px',
                    padding: '10px',
                    color: 'white'
                  }}
                >
                  {route.name}
                </div>
                {childrenRoutes.map((childRoute, index) => {
                  if (checkPermissions(props.permissions, childRoute.permission)) {
                    return (
                      <MenuItem
                        key={index}
                        className={props.classes.adminMenuItem}
                      >
                        <Link
                          style={{
                            width: '100%',
                            zIndex: '9999',
                            textDecoration: 'none'
                          }}
                          onMouseEnter={e => {
                            // props.setExpandItem(true)
                          }}
                          onMouseLeave={e => {
                            //  props.setExpandItem(false)
                          }}
                          key={childRoute.path}
                          to={childRoute.layout + childRoute.path}
                        >
                          <Grid container>
                            <Grid item xs={childRoute.icon ? 2 : 1}>
                              {childRoute.icon ? (
                                <img
                                  alt="home"
                                  src={childRoute.icon}
                                  width="24px"
                                  height="24px"
                                />
                              ) : (
                                ''
                              )}
                            </Grid>
  
                            <Fade in={true} timeout={100}>
                              <Grid item xs={childRoute.icon ? 10 : 11}>
                                <Typography
                                  style={{
                                    color: 'black',
                                    textAlign: 'left',
                                    textDecoration: 'none'
                                  }}
                                >
                                  {childRoute.name}
                                </Typography>
                              </Grid>
                            </Fade>
                          </Grid>
                        </Link>
                      </MenuItem>
                    )
                  } else {
                    return null
                  }
                  
                })}
              </div>
            }
          ></CustomPopover>
        </div>
      )}
    </div>
  )
}
