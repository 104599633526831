import React from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { RoleForm } from '../../../components/Forms/RoleForm'
import { getIamService } from '../../../apis/iam'
import { Notifications } from '../../../components/Notification/Notification'
import { Spinner } from '../../../components/Loader/Spinner'

export default function RolesEdit () {
  const history = useHistory()
  const { roleId } = useParams()

  const queryClient = useQueryClient()
  const getRole = useQuery(
    ['roles', roleId],
    () =>
      getIamService()
        .get(`/roles/${roleId}`)
        .then(result => {
          return result.data
        }),
    {
      staleTime: Infinity
    }
  )

  const updatedRoles = useMutation(
    values => getIamService().put(`/roles/${roleId}`, values),
    {
      onMutate: async newRole => {
        await queryClient.cancelQueries(['roles', newRole.roleId])

        // Snapshot the previous value
        const previousRole = queryClient.getQueryData(['roles', newRole.roleId])

        // Optimistically update to the new value
        queryClient.setQueryData(['roles', newRole.roleId], newRole)

        const previousRoles = queryClient.getQueryData('roles')

        if (previousRoles) {
          queryClient.setQueryData(['roles'], oldRoles => {
            return oldRoles.map(role => {
              if (role.roleId === newRole.roleId) {
                return newRole
              }
              return role
            })
          })
        }

        // Return a rollback function
        return () => {
          queryClient.setQueryData(['roles', newRole.roleId], previousRole)
          queryClient.setQueryData(['roles'], previousRoles)
        }
      }
    }
  )

  function saveRole (roleData) {
    updatedRoles.mutate(roleData, {
      onSuccess: () => history.push('/admin/roles'),
      onSettled: () => {
        queryClient.invalidateQueries('roles')
        queryClient.invalidateQueries(['roles', roleData.roleId])
      }
    })
  }

  return (
    <>
      {updatedRoles.isLoading || getRole.isLoading ? <Spinner /> : null}
      <Notifications
        isOpenProp={getRole.isError || updatedRoles.isError}
        message={
          updatedRoles.isError
            ? updatedRoles.error.response?.data?.message ||
              updatedRoles.error.message
            : getRole.isError
            ? getRole.error.response?.data?.message || getRole.error.message
            : ''
        }
        color="danger"
        timeToClose={6000}
        position="tr"
      />
      <RoleForm
        initialValues={getRole.data}
        onSubmit={saveRole}
        submitText={
          updatedRoles.isLoading
            ? 'Saving...'
            : updatedRoles.isError
            ? 'Error!'
            : 'Save Role'
        }
        isEdit={true}
        permissions={[
          { permission: 'Connector.Users.Create' },
          { permission: 'Connector.Search.Menu' },
          { permission: 'Connector.Search.SuppliersByCriteria' },
          { permission: 'Connector.Search.ConnectedSuppliers' },
          { permission: 'Connector.Search.ByBvD' },
          { permission: 'Connector.AssignBvd' },
          { permission: 'Connector.Users.Edit' },
          { permission: 'Connector.Tenants.Create' },
          { permission: 'Connector.Tenants.Edit' },
          { permission: 'Connector.Tenants.List' },
          { permission: 'Connector.Mappings.Orbis' },
          { permission: 'Connector.Mappings.Catalyst' },
          { permission: 'Connector.Mappings.Risk' },
          { permission: 'Connector.Mappings.CustomForm' },
          { permission: 'Connector.Configurations.Menu' },
          { permission: 'Connector.Configurations.TenantConfiguration.Edit' },
          { permission: 'Connector.Configurations.TenantConfiguration.List' },
          { permission: 'Connector.Configurations.Applications' },
          { permission: 'Connector.Users.List' },
          { permission: 'Connector.ImportSuppliers' },
          { permission: 'Connector.CatalystAssesments' },
          { permission: 'Connector.SupplyChainAssessments' },
          { permission: 'Connector.OrbisAssesments' },
          { permission: 'Connector.OrbisTab' },
          { permission: 'Connector.OwnEntity' }
        ]}
        isSuperAdmin={roleId === 'superAdmin' ? true : false}
      />
    </>
  )
}
