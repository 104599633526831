import React, { useEffect, useRef, useState } from 'react'

import loadingGif from '../../assets/img/ajax-loader.gif'
import { env } from '../../utils/Constants'

// Api Calls
import apiApps from '../../services/apiApps'
import apiAppsProd from '../../services/apiAppsProd'
const getOrbisContext =
  env === 'local' ? apiApps.getOrbisContext : apiAppsProd.getOrbisContext

export default function Catalyst(props) {
  const [context, setContext] = useState('')
  const [Guid, setGuid] = React.useState('')

  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      setGuid(props.Guid ? props.Guid : '')
      getOrbisContext(setContext)
    } else {
    }
    return () => {}
  }, [props])
  return (
    <div>
      {context ? (
        <iframe
          id="iframeCatalyst"
          title="Orbis"
          width="100%"
          height="800px"
          src={`https://supplychaincatalyst.bvdinfo.com/version-2021422/SupplyChainCatalyst/1/CompaniesPortfolio/RiskAssessments/guid?Guid=${Guid}&context=${context}`}
          frameBorder="0"
          style={{
            backgroundImage: 'url(' + loadingGif + ')',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
          allowFullScreen={true}
        ></iframe>
      ) : (
        <iframe
          id="iframeOrbis"
          title="Orbis"
          width="100%"
          height="800px"
          frameBorder="0"
          style={{
            backgroundImage: 'url(' + loadingGif + ')',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
          allowFullScreen={true}
        ></iframe>
      )}
      {/* )} */}
    </div>
  )
}
