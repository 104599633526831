import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { getIamService } from '../../../apis/iam'
import { UserForm } from '../../../components/Forms/UserForm'
import { Notifications } from '../../../components/Notification/Notification'
import { Spinner } from '../../../components/Loader/Spinner'
import { useGetTenants } from '../../../hooks/useTenants'
import { useGetRoles } from '../../../hooks/useGetRoles'
import { useAuth, getTenant } from '../../../Providers/Auth'

export default function UsersCreate () {
  const history = useHistory()
  let msgError = ''
  const queryClient = useQueryClient()
  const { roles, tenant } = useAuth()
  const getTenants = useGetTenants()
  const getRoles = useGetRoles()
  const [roleUserLogged, setRoleUserLogged] = useState('')

  const { mutate, isLoading, isError: userError, error } = useMutation(
    values => getIamService().post('/users', values),
    {
      onMutate: async newUser => {
        await queryClient.cancelQueries('users')
        const userFormatted = {
          ...newUser,
          role: newUser.roles[0],
          password: ''
        }
        delete userFormatted['roles']

        // Snapshot the previous value
        const previousUsers = queryClient.getQueryData('users')

        // Optimistically update to the new value
        queryClient.setQueryData('users', old => {
          if (old) {
            return [...old, userFormatted]
          }
          return [userFormatted]
        })

        // Return a function with the snapshotted value
        return () => queryClient.setQueryData(['users'], previousUsers)
      }
    }
  )

  useEffect(() => {
    const authConfigLS = localStorage.getItem(
      `CognitoIdentity.Configure.${getTenant()}`
    )
    const authConfig = JSON.parse(authConfigLS)
    const userEmail = localStorage.getItem(
      `CognitoIdentityServiceProvider.${authConfig.Auth.userPoolWebClientId}.LastAuthUser`
    )
    const userDataPermission = localStorage.getItem(
      `CognitoIdentityServiceProvider.${authConfig.Auth.userPoolWebClientId}.${userEmail}.userData`
    )

    const dataUserLogged = JSON.parse(userDataPermission)
    dataUserLogged.UserAttributes.forEach(({Name, Value}) => {
      if (Name === 'custom:roles') setRoleUserLogged(Value)
    })
    
    return () => {
    }
  }, [roleUserLogged, getRoles])

  function saveUser (userData) {
    const data = {
      ...userData,
      roles: [userData.role],
      enabled: true
    }
    delete data['role']
    mutate(data, {
      onSuccess: () => history.push('/admin/users'),
      onSettled: () => {
        queryClient.invalidateQueries('users')
      }
    })
  }

  if (userError) {
    msgError = error.response?.data?.message || error.message
  } else if (getTenants.isError) {
    msgError =
      getTenants.error.response?.data?.message || getTenants.error.message
  }

  const isError = userError || getTenants.isError

  return (
    <>
      {isLoading || getTenants.isLoading ? <Spinner /> : null}

      <Notifications
        isOpenProp={isError}
        message={msgError}
        color="danger"
        timeToClose={6000}
        position="tr"
      />
      <UserForm
        onSubmit={saveUser}
        submitText={isLoading ? 'Saving...' : isError ? 'Error!' : 'Save User'}
        tenants={
          roles
            ? roles === 'superAdmin'
              ? getTenants.data
              : [{ tenantId: tenant }]
            : ''
        }
        roles={
          getRoles.data
            ? getRoles.data
            : [
                { roleId: 'superAdmin', name: 'Super Admin' },
                { roleId: 'tenantAdmin', name: 'Tenant Admin' },
                { roleId: 'userBvd', name: 'User Bvd' }
              ]
        }
        roleUserLogged={roleUserLogged}
      />
    </>
  )
}
