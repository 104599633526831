import React, { useEffect, useRef } from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import styles from '../../assets/jss/layout/Admin'
//Icons
import AribaIcon from '../../assets/img/icons/aribaTransparent.png'
import { Grid, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { Spinner } from '../../components/Loader/Spinner'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Badge from '@material-ui/core/Badge';

import CardHeader from '@material-ui/core/CardHeader'
import OrbisIcon from '../../assets/img/icons/orbisTransparent.png'
import Close from '@material-ui/icons/Close'
import NotificationsIcon from '@material-ui/icons/Notifications';
import SupplyChain from './SupplyChain'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { env } from '../../utils/Constants'
import { countHighRiskAssessmentBySupplier } from '../../utils/functions'

// Api Calls
import apiApps from '../../services/apiApps'
import apiAppsProd from '../../services/apiAppsProd'

const useStyles = makeStyles(styles)

const getSupplyChainAssessmentsBySupplierIdWithCallback =
  env === 'local'
    ? apiApps.getSupplyChainAssessmentsBySupplierIdWithCallback
    : apiAppsProd.getSupplyChainAssessmentsBySupplierIdWithCallback

const getSupplyChainAssessmentsWithCallback =
    env === 'local'
      ? apiApps.getSupplyChainAssessmentsWithCallback
      : apiAppsProd.getSupplyChainAssessmentsWithCallback

export default function SupplyChainAssessments (props) {
  const isMounted = useRef(false)

  // const [mainAssesmentLabel, setMainAssesmentLabel] = React.useState(
  //   'Selected Assesment'
  // )
  const [openSupplyChain, setOpenSupplyChain] = React.useState(false)
  const [selectedCatalyst, setSelectedCatalyst] = React.useState(undefined)
  const [supplyChainList, setSupplyChainList] = React.useState(undefined)
  const [isLoading, setIsLoading] = React.useState(true)

  const [highAssessmentCounter, setHighAssessmentCounter] = React.useState(0)
  const classes = useStyles()

  useEffect(() => {
    if (!isMounted.current) {
      let url_string = window.location.href
      let url = new URL(url_string)
      let supplierId = url.searchParams.get('supplierId')
      let newId = url.searchParams.get('newId')
      let portfolioId = url.searchParams.get('portfolioId')
      if (newId && supplierId) {
        getSupplyChainAssessmentsBySupplierIdWithCallback(
          supplierId,
          (list, modelList) => {
            getAssesmentCallback(list, modelList)
          },
          newId
        )
      } else if (supplierId) {
        getSupplyChainAssessmentsBySupplierIdWithCallback(
          supplierId,
          getAssesmentCallback
        )
      } else {
        if (portfolioId) {
          getSupplyChainAssessmentsWithCallback(portfolioId, (list, modelList) => {
            getAssesmentCallback(list, modelList)
          })
        }
      }

      isMounted.current = true
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  }, [])
  function getAssesmentCallback (list, modelList) {
    setSupplyChainList(list)
    setHighAssessmentCounter(countHighRiskAssessmentBySupplier(list))
    setIsLoading(false)
  }

  function getCatalystSize (length) {
    switch (length) {
      case 1:
        return 12
      case 2:
        return 6
      case 3:
        return 6
      default:
        return 6
    }
  }
  
  function CatalystCard (catalyst) {
    if (catalyst) {
      return (
        <Card>
          <Grid container>
            <Grid item xs={6}>
              <CardHeader subheader="Supply Chain Catalyst Assesment"></CardHeader>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <IconButton
                    style={{ float: 'right' }}
                    onClick={() => {
                      setOpenSupplyChain(true)
                      setSelectedCatalyst(catalyst)
                    }}
                  >
                    <img
                      alt="OrbisIcon"
                      style={{ width: '25px' }}
                      src={OrbisIcon}
                    />
                    <span style={{ fontSize: '15px', paddingTop: '2.5px' }}>
                      OPEN IN SUPPLY CHAIN
                    </span>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <div style={{ paddingBottom: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>Score: </span>
                  <span
                    style={{
                      borderRadius: '5px',
                      padding: '5px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      color: 'white',
                      background:
                        catalyst.scoreLabel === 'Low'
                          ? '#a1c83b'
                          : catalyst.scoreLabel === 'Medium'
                          ? '#f88802'
                          : catalyst.scoreLabel === 'High'
                          ? '#da0018'
                          : '#001489'
                    }}
                  >
                    {(catalyst.scoreLabel ? catalyst.scoreLabel + ' | ' : '') +
                      (catalyst.score !== undefined ? catalyst.score : '...')}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ paddingBottom: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>Status: </span>
                  {catalyst.status}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ paddingBottom: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>Model: </span>
                  {catalyst.model}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ paddingBottom: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>Started By: </span>
                  {catalyst.startedUser}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ paddingBottom: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>Started Date: </span>
                  {new Date(
                    catalyst.startedDate.toString()
                  ).toLocaleDateString()}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ paddingBottom: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>Modified By: </span>
                  {catalyst.modificationUser}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ paddingBottom: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>Modified Date: </span>
                  {new Date(
                    catalyst.modifiedDate.toString()
                  ).toLocaleDateString()}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )
    }
    return
  }

  return (
    <div>
      {isLoading ? <Spinner /> : ''}
      {supplyChainList ? (
        <div>
          {props.backFunction ? (
            <IconButton
              onClick={() => {
                props.backFunction()
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          ) : (
            ''
          )}
          <Grid container>
            <Grid item xs={7}>
              <Typography>
                <span
                  style={{ fontSize: '23px' }}
                >{`Supplier: ${supplyChainList.name} BvD ID ${supplyChainList.bvdId}`}</span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {supplyChainList.realm && supplyChainList.aribaSupplierId ? (
                <IconButton
                  style={{ float: 'right' }}
                >
                  <Badge badgeContent={highAssessmentCounter} color="secondary">
                    <NotificationsIcon color="action" />
                  </Badge>
                  <span style={{ fontSize: '15px', paddingTop: '2.5px', paddingLeft: '5.5px' }}>
                    Alerts
                  </span>
                </IconButton>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={3}>
              {supplyChainList.realm && supplyChainList.aribaSupplierId ? (
                <IconButton
                  style={{ float: 'right' }}
                  onClick={() => {
                    const urlOpenAriba = supplyChainList.region === 'us' ? 's1.ariba.com' : supplyChainList.region === 'au' ? 's1.au.cloud.ariba.com' : 's1.eu.cloud.ariba.com'
                    window.open(
                      `https://${urlOpenAriba}/Sourcing/Main/ad/viewSupplier360Profile/ariba.sourcing.dashboard.SMMainActionsController?realm=${supplyChainList.realm}&smVendorId=${supplyChainList.aribaSupplierId}`
                    )
                  }}
                >
                  <img style={{ width: '25px' }} src={AribaIcon} alt="" />
                  <span style={{ fontSize: '15px', paddingTop: '2.5px' }}>
                    OPEN IN ARIBA
                  </span>
                </IconButton>
              ) : (
                ''
              )}
            </Grid>
          </Grid>

          <Dialog
            open={openSupplyChain}
            maxWidth="xl"
            fullWidth
            style={{ overflow: 'hidden', maxWidth: '' }}
          >
            <div>
              <IconButton
                style={{ float: 'right' }}
                onClick={() => {
                  setOpenSupplyChain(false)
                }}
              >
                <Close />
              </IconButton>
            </div>
            <div style={{ overflow: 'hidden' }}>
              <SupplyChain Guid={selectedCatalyst ? selectedCatalyst.id : ''} />
            </div>
          </Dialog>

          <Grid container>
            <Grid container spacing={3}>
              {supplyChainList.main ? (
                <Grid item xs={12} style={{ margin: '10px' }}>
                  <span
                    className={classes.titleBar}
                    style={{ fontSize: '18px' }}
                  >
                    {/* {mainAssesmentLabel} */}
                  </span>
                  {CatalystCard(supplyChainList.main)}
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={12} style={{ margin: '10px' }}>
                <Grid container spacing={3}>
                  {supplyChainList.list && supplyChainList.list.length > 0 ? (
                    <Grid item xs={12}>
                      <span
                        className={classes.titleBar}
                        style={{ fontSize: '18px' }}
                      >{`Assesments`}</span>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {supplyChainList.list && supplyChainList.list.length > 0
                    ? supplyChainList.list.map((c, index) => {
                        return (
                          <Grid
                            key={index}
                            item
                            xs={getCatalystSize(supplyChainList.list.length)}
                          >
                            {CatalystCard(c)}
                          </Grid>
                        )
                      })
                    : ''}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}