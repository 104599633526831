import React, { useEffect, useMemo, useState } from 'react'

// Core
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'

import FormControl from '@material-ui/core/FormControl'
//import Select from '@material-ui/core/Select'
import Select2 from 'react-select'
import { getSelectValue } from '../../utils/functions'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
// Style
import { makeStyles } from '@material-ui/core/styles'
import tenantsEditStyle from '../../assets/jss/views/TenantsEdit'

// Form Validation
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const useStyles = makeStyles(tenantsEditStyle)

const defaultFormValues = {
  tenantId: '',
  name: '',
  address: '',
  city: '',
  language: '',
  email: '',
  webSiteUrl: '',
  enabled: true,
  ssoEnabled: false,
  ssoMetadataUrl: '',
  appClientId: '',
  appClientSecret: '',
  ssoMapping: {}
}
const languages = [
  { label: 'English', value: 'en' },
  { label: 'Spanish', value: 'es' }
]
const schema = yup.object().shape({
  tenantId: yup
    .string()
    .min(1)
    .max(16)
    .required(),
  name: yup
    .string()
    .min(1)
    .max(16)
    .required(),
  address: yup
    .string()
    .min(1)
    .max(50)
    .required(),
  city: yup
    .string()
    .min(1)
    .max(16)
    .required(),
  language: yup
    .string()
    .min(1)
    .required(),
  email: yup
    .string()
    .email()
    .required(),
  webSiteUrl: yup
    .string()
    .url()
    .required(),
  enabled: yup.boolean().required(),
  ssoEnabled: yup.boolean().required(),
  ssoMetadataUrl: yup
    .string()
    .url()
    .optional()
    .nullable(),
  appClientId: yup.string().optional(),
  appClientSecret: yup.string().optional(),
  ssoMapping: yup.object().optional()
})
function cleanTenantName (val) {
  if (typeof val === 'string') {
    const specialChars = `/[!@#$%^&*()_+-=[]{};':"\\|,.<>/?]+/;`

    specialChars.split('').forEach(char => {
      val = val.replaceAll(char, '')
    }) // true if present and false if not

    val = val
      .split(' ')
      .join('')
      .toLocaleLowerCase()
  }
  return val
}

export function TenantForm ({
  onSubmit,
  initialValues = defaultFormValues,
  submitText,
  isEdit = false,
  title = 'Tenants'
}) {
  const [showSecret, setShowSecret] = useState(false)

  const classes = useStyles()

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    getValues
  } = useForm({
    mode: 'all',
    defaultValues: useMemo(() => initialValues, [initialValues]),
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset(initialValues)
  }, [initialValues, reset])

  function isError (field) {
    if (errors[field]) {
      return true
    }
    return false
  }

  function onSubmitHook (data) {
    onSubmit(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHook)}>
      <h3 className={classes.h3}>{title}</h3>
      <Grid container justifycontent="center">
        <Grid item xs={12}>
          <Card className={classes.card} id={'cardId'}>
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <span style={{ color: 'red' }}>
                    *Upper case letters, spaces and special characters are not
                    allowed on tenant name and unique name
                  </span>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Grid item xs={12}>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <Grid container>
                          <Grid item xs={12}>
                            <span
                              style={{
                                color: 'red',
                                marginLeft: '10px',
                                visibility: isError('name') ? '' : 'hidden'
                              }}
                            >
                              {'Please Add Tenant Name*'}
                            </span>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              disabled={isEdit}
                              label="Tenant Name*"
                              variant="outlined"
                              value={value}
                              size="small"
                              className={classes.textField}
                              error={isError('name')}
                              onChange={e => {
                                onChange(cleanTenantName(e.target.value))
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                      name="name"
                      control={control}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Grid item xs={12}>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <Grid container>
                          <Grid item xs={12}>
                            <span
                              style={{
                                color: 'red',
                                marginLeft: '10px',
                                visibility: isError('tenantId') ? '' : 'hidden'
                              }}
                            >
                              {'Please Add Tenant Unique Name*'}
                            </span>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              disabled={isEdit}
                              label="Tenant Unique Name*"
                              variant="outlined"
                              size="small"
                              className={classes.textField}
                              error={isError('tenantId')}
                              value={value}
                              onChange={e => {
                                onChange(cleanTenantName(e.target.value))
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                      name="tenantId"
                      control={control}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Grid item xs={12}>
                    <Controller
                      render={({ field }) => (
                        <Grid container>
                          <Grid item xs={12}>
                            <span
                              style={{
                                color: 'red',
                                marginLeft: '10px',
                                visibility: isError('city') ? '' : 'hidden'
                              }}
                            >
                              {'Please Add City*'}
                            </span>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="City*"
                              variant="outlined"
                              size="small"
                              className={classes.textField}
                              error={isError('city')}
                              {...field}
                            />
                          </Grid>
                        </Grid>
                      )}
                      name="city"
                      control={control}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Controller
                      render={({ field }) => (
                        <Grid container>
                          <Grid item xs={12}>
                            <span
                              style={{
                                color: 'red',
                                marginLeft: '10px',
                                visibility: isError('address') ? '' : 'hidden'
                              }}
                            >
                              {'Please Add Address*'}
                            </span>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Full Address*"
                              variant="outlined"
                              size="small"
                              className={classes.textField}
                              style={{ width: '96.5%' }}
                              error={isError('address')}
                              {...field}
                            />
                          </Grid>
                        </Grid>
                      )}
                      name="address"
                      control={control}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Grid item xs={12}>
                    <Controller
                      render={({ field }) => (
                        <Grid container>
                          <Grid item xs={12}>
                            <span
                              style={{
                                color: 'red',
                                marginLeft: '10px',
                                visibility: isError('email') ? '' : 'hidden'
                              }}
                            >
                              {'Please Add Email*'}
                            </span>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              type={'email'}
                              label="Tenant Email*"
                              variant="outlined"
                              size="small"
                              className={classes.textField}
                              error={isError('email')}
                              {...field}
                            />
                          </Grid>
                        </Grid>
                      )}
                      name="email"
                      control={control}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Grid item xs={12}>
                    <Controller
                      render={({ field }) => (
                        <Grid container>
                          <Grid item xs={12}>
                            <span
                              style={{
                                color: 'red',
                                marginLeft: '10px',
                                visibility: isError('webSiteUrl')
                                  ? ''
                                  : 'hidden'
                              }}
                            >
                              {'Please Add Web Site Url*'}
                            </span>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              type={'url'}
                              label="Tenant Web Address*"
                              variant="outlined"
                              size="small"
                              className={classes.textField}
                              error={isError('webSiteUrl')}
                              {...field}
                            />
                          </Grid>
                        </Grid>
                      )}
                      name="webSiteUrl"
                      control={control}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectField}
                      error={isError('language')}
                    >
                      <InputLabel id="language-label"></InputLabel>
                      <Controller
                        /* render={({ field }) => (
                          <Select
                         
                            {...field}
                          >
                            <MenuItem value={'en'}>English</MenuItem>
                            <MenuItem value={'es'}>Spanish</MenuItem>
                          </Select>
                        )}*/
                        render={({ field: { value, onChange } }) => (
                          <Grid container>
                            <Grid item xs={12}>
                              <span
                                style={{
                                  color: 'red',
                                  marginLeft: '10px',
                                  visibility: isError('language')
                                    ? ''
                                    : 'hidden'
                                }}
                              >
                                {'Please Select Language*'}
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              <Select2
                                menuPortalTarget={document.getElementById(
                                  'cardId'
                                )}
                                labelId="language-label"
                                label="Language"
                                placeholder={'Language*'}
                                options={languages}
                                value={getSelectValue(value, languages)}
                                onChange={e => {
                                  let newValue
                                  if (Array.isArray(e)) {
                                    newValue = e.map(val => {
                                      return val.value
                                    })
                                  } else newValue = e.value
                                  if (newValue) onChange(newValue)
                                }}
                              ></Select2>
                            </Grid>
                          </Grid>
                        )}
                        name="language"
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {isEdit ? (
                  <>
                    <Grid item xs={6} md={4}>
                      <Grid item xs={12}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              disabled={isEdit}
                              label="App Client Id"
                              variant="outlined"
                              size="small"
                              className={classes.textField}
                              error={isError('appClientId')}
                              {...field}
                            />
                          )}
                          name="appClientId"
                          control={control}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Grid item xs={12}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              disabled={isEdit}
                              type={showSecret ? '' : 'password'}
                              label="App Client Secret"
                              variant="outlined"
                              size="small"
                              className={classes.textField}
                              error={isError('appClientSecret')}
                              {...field}
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    onClick={() => {
                                      setShowSecret(!showSecret)
                                    }}
                                  >
                                    {showSecret ? (
                                      <VisibilityOffIcon />
                                    ) : (
                                      <VisibilityIcon />
                                    )}
                                  </IconButton>
                                )
                              }}
                            ></TextField>
                          )}
                          name="appClientSecret"
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={6} md={4}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      style={{ margin: '20px 0px' }}
                      control={
                        <Controller
                          name="enabled"
                          control={control}
                          render={({ field }) => (
                            <Switch
                              onChange={e => field.onChange(e.target.checked)}
                              checked={field.value}
                              color="primary"
                            />
                          )}
                        />
                      }
                      label="Enabled"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider variant="middle" />
                </Grid>
                <Grid item xs={6} md={4}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      style={{ margin: '20px 0px' }}
                      control={
                        <Controller
                          name="ssoEnabled"
                          control={control}
                          render={({ field }) => (
                            <Switch
                              onChange={e => field.onChange(e.target.checked)}
                              checked={field.value}
                              color="primary"
                            />
                          )}
                        />
                      }
                      label="SSO Enabled"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid item xs={12}>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          type={'url'}
                          disabled={!getValues('ssoEnabled')}
                          label="SSO Metadata Url"
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                          error={isError('ssoMetadataUrl')}
                          {...field}
                        />
                      )}
                      name="ssoMetadataUrl"
                      control={control}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid item xs={12} style={{ paddingLeft: '15px' }}>
                    <span>SSO Email Mapping</span>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      render={({ field }) => {
                        return (
                          <TextField
                            disabled={!getValues('ssoEnabled')}
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            {...field}
                            value={field.value ? field.value['Email'] : ''}
                            onChange={e => {
                              const email = {}
                              email['Email'] = e.target.value

                              field.onChange(email)
                            }}
                          />
                        )
                      }}
                      name="ssoMapping"
                      control={control}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <Button className={classes.defaultButton} type="submit">
                    {submitText}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  )
}
