import React, { useEffect, useRef } from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// import { default as CustomTable } from '../../components/Table/Table.js'
import Card from '../../components/Card/Card.js'
import { cloneObject } from '../../utils/functions'
// Icons
import AddCircleIcon from '@material-ui/icons/AddCircle'
// import CancelIcon from '@material-ui/icons/Cancel'
import styles from '../../assets/jss/layout/Admin'
import MappingComponent from '../MappingComponent/MappingComponentView'

import CheckIcon from '@material-ui/icons/Check'
// import a from '../MappingComponent/types'
import { Button, Grid, IconButton } from '@material-ui/core'
import { env } from '../../utils/Constants'

import CustomModal from '../../components/CustomModal/CustomModal.js'
import CustomTreeTable from '../../components/CustomTreeTable/CustomTreeTable.js'
import ParametersAccordion from '../CustomGenericViews/ParametersAccordion.js'
import { Spinner } from '../../components/Loader/Spinner.js'
import { ArrowBack } from '@material-ui/icons'

// Api Calls
import apiApps from '../../services/apiApps.js'
import apiAppsProd from '../../services/apiAppsProd'
const getMappingsAndLists =
  env === 'local'
    ? apiApps.getMappingsAndLists
    : apiAppsProd.getMappingsAndLists
const setTenantMappings =
  env === 'local' ? apiApps.setTenantMappings : apiAppsProd.setTenantMappings
const updateCatalystJob =
  env === 'local' ? apiApps.updateCatalystJob : apiAppsProd.updateCatalystJob
const updateOrbisJob =
  env === 'local' ? apiApps.updateOrbisJob : apiAppsProd.updateOrbisJob
const getCatalystModelsWithCallBack =
  env === 'local'
    ? apiApps.getCatalystModelsWithCallBack
    : apiAppsProd.getCatalystModelsWithCallBack
const getUsersEmailOrbisWithCallBack =
  env === 'local'
    ? apiApps.getUsersEmailOrbisWithCallBack
    : apiAppsProd.getUsersEmailOrbisWithCallBack
const updateSupplyChainJob = 
  env === 'local' ? apiApps.updateSupplyChainJob : apiAppsProd.updateSupplyChainJob


const useStyles = makeStyles(styles)
const orbisSearchMappingType = 'orbisSearchMapping'
const questionnaireMappingType = 'questionnaireMapping'
const catalystMappingType = 'catalystMapping'
const riskMappingType = 'riskMapping'
const supplyChainMappingType = 'supplyChainMapping'

export default function Mapping (props) {
  const [isLoading, setIsLoading] = React.useState(true)
  //  const [lists, setLists] = React.useState([])

  const [jobSettings, setJobSettings] = React.useState(undefined)
  const [userMapping, setUserMapping] = React.useState([])
  //const [orbisListsErrorMessage, setOrbisListsErrorMessage] = React.useState('')
  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      getMappingsAndLists(props.type, (dataMapping, job) => {
        //setOrbisListsErrorMessage(listError)
        setUserMapping(dataMapping)
        if (props.type === catalystMappingType) {
          getCatalystModelsWithCallBack(job, models => {
            if (Array.isArray(models) && models.length === 0)
              job.catalystModel.options = [{}]
            else job.catalystModel.options = models
            setJobSettings(job)
            setIsLoading(false)
          }, props.type)
        } else if(props.type === questionnaireMappingType){
          getUsersEmailOrbisWithCallBack(job, models => {
            if (Array.isArray(models) && models.length === 0)
              job.orbisJobEmails.options = [{}]
            else job.orbisJobEmails.options = models

            setJobSettings(job)
            setIsLoading(false)
          })
        } else if (props.type === supplyChainMappingType) {
          getCatalystModelsWithCallBack(job, models => {
            if (Array.isArray(models) && models.length === 0)
              job.supplyChainModel.options = [{}]
            else job.supplyChainModel.options = models
            setJobSettings(job)
            setIsLoading(false)
          }, props.type)
        } else {
          setJobSettings(job)
          setIsLoading(false)
        }
      })
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  })

  const [
    showQuestionnaireMapping,
    setShowQuestionnaireMapping
  ] = React.useState(null)

  const [showOrbisSearchMapping, setShowOrbisSearchMapping] = React.useState(
    null
  )
  const [showCatlystMapping, setShowCatlystMapping] = React.useState(null)
  const [showRiskMapping, setShowRiskMapping] = React.useState(null)
  const [showSupplyChainMapping, setShowSupplyChainMapping] = React.useState(null) 

  const [mappingVersion, setMappingVersion] = React.useState(undefined)
  const [, setVersionSelect] = React.useState(undefined)
  const [showModal, setShowModal] = React.useState(false)
  const [userBackupMapping, setUserBackupMapping] = React.useState([])
  const [onConfirmModalFunction, setOnConfirmModalFunction] = React.useState({
    function: () => {}
  })
  const [modalMessage, setModalMessage] = React.useState('')

  const [questionnaireMapping, setQuestionnaireMapping] = React.useState([])

  const [
    mainQuestionnaireOptions,
    setMainQuestionnaireOptions
  ] = React.useState([
    'addressLine1',
    'addressLine2',
    'bvdId',
    'city',
    'countryIsoCode',
    'state',
    'email',
    'entityType',
    'incorporationDate',
    'nace2MainSection',
    'nace2PrimaryCode',
    'nace2PrimaryLabel',
    'name',
    'nationalId',
    'nationalIdLabel',
    'nationalIdType',
    'originalCurrency',
    'postcode',
    'standardisedLegalForm',
    'status',
    'tin',
    'tradeDescriptionEn',
    'tradeDescriptionLanguage',
    'custom1',
    'custom2',
    'custom3',
    'custom4',
    'custom5',
    'custom6',
    'custom7',
    'custom8',
    'custom9',
    'custom10'
  ])

  const [catalystOptions] = React.useState([
    { label: 'Catalyst Status', value: 'CF_RA_Status' },
    { label: 'Score', value: 'CF_RA_Score' },
    { label: 'Score Label', value: 'CF_RA_ScoreKey.CF_ScoreAndClasses_Label' },
    { label: 'Started Date', value: 'CF_RA_StartedOn' },
    { label: 'Modified Date', value: 'CFE_RA_MODIFICATION_DATE' },
    {
      label: 'Connector URL',
      value: 'connectorUrl',
      custom: true
    },
    {
      label: 'Connector Status',
      value: 'bvdStatus',
      custom: true
    }
  ])
  const [mainOrbisMappingOptions, setMainOrbisMappingOptions] = React.useState(
    props.type === riskMappingType
      ? [
        'FIELD_01',
        'FIELD_02',
        'FIELD_03',
        'FIELD_04',
        'FIELD_05',
        'FIELD_06',
        'FIELD_07',
        'FIELD_08',
        'FIELD_09',
        'FIELD_10',
        'FIELD_11',
        'FIELD_12',
        'FIELD_13',
        'FIELD_14',
        'FIELD_15',
        'FIELD_16',
        'FIELD_17',
        'FIELD_18',
        'FIELD_19',
        'FIELD_20',
        'FIELD_21',
        'FIELD_22',
        'FIELD_23',
        'FIELD_24',
        'FIELD_25',
        'FIELD_26',
        'FIELD_27',
        'FIELD_28',
        'FIELD_29',
        'FIELD_30',
        'FIELD_31',
        'FIELD_32',
        'FIELD_33',
        'FIELD_34',
        'FIELD_35',
        'FIELD_36',
        'FIELD_37',
        'FIELD_38',
        'FIELD_39',
        'FIELD_40',
        'FIELD_41',
        'FIELD_42',
        'FIELD_43',
        'FIELD_44',
        'FIELD_45',
        'FIELD_46',
        'FIELD_47',
        'FIELD_48',
        'FIELD_49',
        'FIELD_50',
        'FIELD_51',
        'FIELD_52',
        'FIELD_53',
        'FIELD_54',
        'FIELD_55',
        'FIELD_56',
        'FIELD_57',
        'FIELD_58',
        'FIELD_59',
        'FIELD_60',
        'FIELD_61',
        'FIELD_62',
        'FIELD_63',
        'FIELD_64',
        'FIELD_65',
        'FIELD_66',
        'FIELD_67',
        'FIELD_68',
        'FIELD_69',
        'FIELD_70',
        'FIELD_71',
        'FIELD_72',
        'FIELD_73',
        'FIELD_74',
        'FIELD_75',
        'FIELD_76',
        'FIELD_77',
        'FIELD_78',
        'FIELD_79',
        'FIELD_80',
        'FIELD_81',
        'FIELD_82',
        'FIELD_83',
        'FIELD_84',
        'FIELD_85',
        'FIELD_86',
        'FIELD_87',
        'FIELD_88',
        'FIELD_89',
        'FIELD_90',
        'FIELD_91',
        'FIELD_92',
        'FIELD_93',
        'FIELD_94',
        'FIELD_95',
        'FIELD_96',
        'FIELD_97',
        'FIELD_98',
        'FIELD_99',
        'FIELD_100',
        'FIELD_101',
        'FIELD_102',
        'FIELD_103',
        'FIELD_104',
        'FIELD_105',
        'FIELD_106',
        'FIELD_107',
        'FIELD_108',
        'FIELD_109',
        'FIELD_110',
        'FIELD_111',
        'FIELD_112',
        'FIELD_113',
        'FIELD_114',
        'FIELD_115',
        'FIELD_116',
        'FIELD_117',
        'FIELD_118',
        'FIELD_119',
        'FIELD_120',
        'FIELD_121',
        'FIELD_122',
        'FIELD_123',
        'FIELD_124',
        'FIELD_125',
        'FIELD_126',
        'FIELD_127',
        'FIELD_128',
        'FIELD_129',
        'FIELD_130',
        'FIELD_131',
        'FIELD_132',
        'FIELD_133',
        'FIELD_134',
        'FIELD_135',
        'FIELD_136',
        'FIELD_137',
        'FIELD_138',
        'FIELD_139',
        'FIELD_140',
        'FIELD_141',
        'FIELD_142',
        'FIELD_143',
        'FIELD_144',
        'FIELD_145',
        'FIELD_146',
        'FIELD_147',
        'FIELD_148',
        'FIELD_149',
        'FIELD_150',
        'FIELD_151',
        'FIELD_152',
        'FIELD_153',
        'FIELD_154',
        'FIELD_155',
        'FIELD_156',
        'FIELD_157',
        'FIELD_158',
        'FIELD_159',
        'FIELD_160',
        'FIELD_161',
        'FIELD_162',
        'FIELD_163',
        'FIELD_164',
        'FIELD_165',
        'FIELD_166',
        'FIELD_167',
        'FIELD_168',
        'FIELD_169',
        'FIELD_170',
        'FIELD_171',
        'FIELD_172',
        'FIELD_173',
        'FIELD_174',
        'FIELD_175',
        'FIELD_176',
        'FIELD_177',
        'FIELD_178',
        'FIELD_179',
        'FIELD_180',
        'FIELD_181',
        'FIELD_182',
        'FIELD_183',
        'FIELD_184',
        'FIELD_185',
        'FIELD_186',
        'FIELD_187',
        'FIELD_188',
        'FIELD_189',
        'FIELD_190',
        'FIELD_191',
        'FIELD_192',
        'FIELD_193',
        'FIELD_194',
        'FIELD_195',
        'FIELD_196',
        'FIELD_197',
        'FIELD_198',
        'FIELD_199',
        'FIELD_200',
        'FIELD_201',
        'FIELD_202',
        'FIELD_203',
        'FIELD_204',
        'FIELD_205',
        'FIELD_206',
        'FIELD_207',
        'FIELD_208',
        'FIELD_209',
        'FIELD_210',
        'FIELD_211',
        'FIELD_212',
        'FIELD_213',
        'FIELD_214',
        'FIELD_215',
        'FIELD_216',
        'FIELD_217',
        'FIELD_218',
        'FIELD_219',
        'FIELD_220',
        'FIELD_221',
        'FIELD_222',
        'FIELD_223',
        'FIELD_224',
        'FIELD_225',
        'FIELD_226',
        'FIELD_227',
        'FIELD_228',
        'FIELD_229',
        'FIELD_230',
        'FIELD_231',
        'FIELD_232',
        'FIELD_233',
        'FIELD_234',
        'FIELD_235',
        'FIELD_236',
        'FIELD_237',
        'FIELD_238',
        'FIELD_239',
        'FIELD_240',
        'FIELD_241',
        'FIELD_242',
        'FIELD_243',
        'FIELD_244',
        'FIELD_245',
        'FIELD_246',
        'FIELD_247',
        'FIELD_248',
        'FIELD_249',
        'FIELD_250',
        'FIELD_251',
        'FIELD_252',
        'FIELD_253',
        'FIELD_254',
        'FIELD_255',
        'FIELD_256',
        'FIELD_257',
        'FIELD_258',
        'FIELD_259',
        'FIELD_260',
        'FIELD_261',
        'FIELD_262',
        'FIELD_263',
        'FIELD_264',
        'FIELD_265',
        'FIELD_266',
        'FIELD_267',
        'FIELD_268',
        'FIELD_269',
        'FIELD_270',
        'FIELD_271',
        'FIELD_272',
        'FIELD_273',
        'FIELD_274',
        'FIELD_275',
        'FIELD_276',
        'FIELD_277',
        'FIELD_278',
        'FIELD_279',
        'FIELD_280',
        'FIELD_281',
        'FIELD_282',
        'FIELD_283',
        'FIELD_284',
        'FIELD_285',
        'FIELD_286',
        'FIELD_287',
        'FIELD_288',
        'FIELD_289',
        'FIELD_290',
        'FIELD_291',
        'FIELD_292',
        'FIELD_293',
        'FIELD_294',
        'FIELD_295',
        'FIELD_296',
        'FIELD_297',
        'FIELD_298',
        'FIELD_299',
        'FIELD_300',
        ]
      : [
          'supplierName',
          'supplierAddress',
          'city',
          'custom1',
          'custom2',
          'custom3',
          'custom4',
          'custom5',
          'custom6',
          'custom7'
        ]
  )

  const classes = useStyles()
  async function saveJobSettings (type) {
    setIsLoading(true)

    if (type === questionnaireMappingType) {
      let newSettings = await updateOrbisJob({ ...jobSettings })
      if (newSettings.success) {
        props.setValidationMessage('Success')
        props.setSubmitSuccess(true)
      }
    }
    if (type === catalystMappingType) {
      jobSettings.catalystModel.value = { id: jobSettings.catalystModel.value.id, Name: jobSettings.catalystModel.value.Name}
      let newSettings = await updateCatalystJob({ ...jobSettings })
      if (newSettings.success) {
        props.setValidationMessage('Success')
        props.setSubmitSuccess(true)
      }
    }

    if (type === supplyChainMappingType) {
      jobSettings.supplyChainModel.value = { id: jobSettings.supplyChainModel.value.id, Name: jobSettings.supplyChainModel.value.Name}
      let newSettings = await updateSupplyChainJob({ ...jobSettings })
      if (newSettings.success) {
        props.setValidationMessage('Success')
        props.setSubmitSuccess(true)
      }
    }
    setIsLoading(false)
  }

  function getMappingVersions (type) {
    let tableData = []
    let mapping = userMapping[type]
    if (mapping && mapping.mappings) {
      const keys = Object.keys(mapping.mappings)
      if (keys && keys.length > 0) {
        keys.forEach(key => {
          let element = mapping.mappings[key]
          tableData.push({
            date: element.date,
            version: key,

            edit: (
              <Button
                style={{ float: 'none', width: '50%', textAlign: 'center' }}
                className={classes.defaultButton}
                onClick={() => {
                  setUserBackupMapping(cloneObject(userMapping))
                  setShowCatlystMapping(type === catalystMappingType)
                  setShowQuestionnaireMapping(type === questionnaireMappingType)
                  setShowRiskMapping(type === riskMappingType)
                  setShowOrbisSearchMapping(type === orbisSearchMappingType)
                  setShowSupplyChainMapping(type === supplyChainMappingType)
                  setMappingVersion(key)
                  if(type === catalystMappingType) setVersionSelect(element.selectCompliance.SELECT);
                  if(type === orbisSearchMappingType || type === questionnaireMappingType) setVersionSelect(element.select.SELECT);
                  if(type === supplyChainMappingType) setVersionSelect(element.selectSupplyChain.SELECT)
                }}
              >
                Edit
              </Button>
            ),
            active:
              key === mapping.active ? (
                <CheckIcon></CheckIcon>
              ) : (
                <Button
                  style={{ float: 'none', width: '50%', textAlign: 'center' }}
                  className={classes.defaultButton}
                  onClick={async () => {
                    let newMapping = cloneObject(userMapping)
                    newMapping[type].active = key
                    setUserMapping(newMapping)
                    setIsLoading(true)
                    let response = await setTenantMappings(newMapping)
                    setIsLoading(false)
                    if (response && response.success) {
                      props.setValidationMessage('Success')
                      props.setSubmitSuccess(true)
                      setOnConfirmModalFunction({ function: () => {} })

                      //props.backFunction()
                    } else {
                      // alert(response.message)
                      props.setValidationMessage(response.message)
                      props.setSubmitSuccess(true)

                      setOnConfirmModalFunction({ function: () => {} })
                    }
                  }}
                >
                  ACTIVATE
                </Button>
              )
          })
        })
      }
    }
    //console.log(jobSettings)
    return (
      <Card>
        <Grid container>
          <Grid item xs={12}>
            <IconButton
              onClick={() => {
                props.history.push(`/admin/configurations`)
              }}
            >
              <ArrowBack />
            </IconButton>
            <Grid container>
              <Grid item xs={6} style={{ paddingLeft: '15px' }}>
                <span style={{ fontSize: '1.5vmax', fontWeight: 'bold' }}>
                  {type === questionnaireMappingType
                    ? 'Orbis Integration Mapping'
                    : type === catalystMappingType
                    ? 'Compliance Catalyst Assesment Integration Mapping'
                    : type === riskMappingType
                    ? 'Risk Integration Mapping'
                    : type === orbisSearchMappingType
                    ? 'Ariba Custom Form Integration Mapping'
                    : type === supplyChainMappingType
                    ? 'Supply Chain Catalyst Mapping'
                    : ''}
                </span>
              </Grid>
              <Grid item xs={6}>
                <IconButton
                  style={{ float: 'right', height: '1vmax' }}
                  onClick={() => {
                    setShowCatlystMapping(type === catalystMappingType)
                    setShowRiskMapping(type === riskMappingType)
                    setShowQuestionnaireMapping(
                      type === questionnaireMappingType
                    )
                    setShowOrbisSearchMapping(type === orbisSearchMappingType)
                    setShowSupplyChainMapping(type === supplyChainMappingType)
                    setMappingVersion(undefined)
                  }}
                >
                  <div>
                    <AddCircleIcon />
                    <span
                      style={{
                        fontSize: '1vmax',
                        fontWeight: 'bold',
                        float: 'right'
                      }}
                    >
                      {'Add New Mapping'}
                    </span>
                  </div>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CustomTreeTable
              data={tableData}
              columns={[
                { title: 'Date', field: 'date' },
                { title: 'Version', field: 'version' },
                { title: 'Edit', field: 'edit' },
                { title: 'Active', field: 'active' }
              ]}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                showTitle: false
              }}
            />
          </Grid>
          {jobSettings ? (
            <Grid item xs={12}>
              <ParametersAccordion
                initialState={{
                  forceAccordion: true,
                  parameters: [
                    {
                      initialOpen: true,
                      title: jobSettings.title,
                      saveButton: isValidated => {
                        return (
                          <div style={{ width: '100%' }}>
                            <div style={{ float: 'right' }}>
                              <Grid style={{ float: 'right', margin: '10px' }}>
                                <Button
                                  style={{ width: '200px' }}
                                  className={classes.defaultButton}
                                  onClick={() => {
                                    if (typeof isValidated === 'function') {
                                      if (isValidated()) saveJobSettings(type)
                                    } else saveJobSettings(type)
                                  }}
                                >
                                  SAVE
                                </Button>
                              </Grid>
                              {type === catalystMappingType || type === supplyChainMappingType ? (
                                <Grid
                                  style={{ float: 'right', margin: '10px' }}
                                >
                                  <Button
                                    style={{ width: '200px' }}
                                    className={classes.defaultButton}
                                    onClick={() => {
                                      setIsLoading(true)
                                      getCatalystModelsWithCallBack(
                                        jobSettings,
                                        models => {
                                          let job = jobSettings
                                          if (
                                            Array.isArray(models) &&
                                            models.length === 0
                                          )
                                            job.catalystModel.options = [{}]
                                          else
                                            job.catalystModel.options = models
                                          setJobSettings(job)
                                          setIsLoading(false)
                                        }
                                      )
                                    }}
                                  >
                                    Refresh Models
                                  </Button>
                                </Grid>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        )
                      },
                      conectivityMapping: {
                        env: '',
                        app: 'slp',
                        service: 'ariba',
                        hideServiceName: true,
                        hideTestConnection: true,
                        fields: jobSettings
                      }
                    }
                  ]
                }}
              ></ParametersAccordion>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </Card>
    )
  }

  function getMappingComponent (
    type,
    setMapping,
    setMainOptions,
    mappingMainOptions,
    options = []
  ) {
    //let options = translateSelectFields(teeest, select)

    // let mainOptions = [
    //   'supplierName',
    //   'supplierAddress',
    //   'city',
    //   'custom1',
    //   'custom2',
    //   'custom3',
    //   'custom4',
    //   'custom5',
    //   'custom6',
    //   'custom7'
    // ]

    // let mapping = {
    //   supplierName: { mapping: [{ value: 'NAME', label: 'NAME' }] },
    //   supplierAddress: {
    //     mapping: [{ value: 'ADDRESS_LINE1', label: 'ADDRESS_LINE1' }]
    //   },
    //   city: { mapping: [{ value: 'CITY', label: 'CITY' }] }
    // }

    return (
      <Grid container justifyContent="center">
        <Card style={{ padding: '100px', paddingTop: '0px' }}>
          <Grid item xs={12}>
            <MappingComponent
              //orbisListsErrorMessage={orbisListsErrorMessage}
              setShowModal={setShowModal}
              setOnConfirmModalFunction={setOnConfirmModalFunction}
              setModalMessage={setModalMessage}
              userMapping={userMapping}
              mappingVersion={mappingVersion}
              type={type}
              isQuestionnaireMapping={
                type === questionnaireMappingType ||
                type === catalystMappingType ||
                type === supplyChainMappingType
              }
              setMapping={setMapping}
              setMainOptions={setMainOptions}
              mainOptions={mappingMainOptions}
              options={type === catalystMappingType ? catalystOptions : options}
              backFunction={refresh => {
                if (refresh) setUserMapping(cloneObject(userBackupMapping))
                setShowQuestionnaireMapping(false)
                setShowRiskMapping(false)
                setShowCatlystMapping(false)
                setShowOrbisSearchMapping(false)
                setShowSupplyChainMapping(false)
                setMappingVersion(undefined)
                setVersionSelect([])
              }}
              //lists={lists}
              title={
                type === questionnaireMappingType
                  ? 'Questionnaire Mapping'
                  : type === orbisSearchMappingType
                  ? 'Orbis Search Mapping'
                  : type === catalystMappingType
                  ? 'Compliance Catalyst Ariba Mapping'
                  : type === riskMappingType
                  ? 'Ariba Risk Mapping'
                  : type === supplyChainMappingType
                  ? 'Supply Chain Catalyst Mapping'
                  : ''
              }
              mapping={questionnaireMapping}
              {...props}
            ></MappingComponent>
          </Grid>
        </Card>
      </Grid>
    )
  }

  return (
    <div>
      <div>
        <Grid container>
          {showModal ? (
            <CustomModal
              message={modalMessage}
              //title={'Alert'}
              confirmBtnCssClass={classes.defaultButton}
              cancelBtnCssClass={classes.buttonDanger}
              onCancel={() => {
                setShowModal(false)
                setModalMessage('')
                setOnConfirmModalFunction({ function: () => {} })
              }}
              onConfirm={() => {
                setShowModal(false)
                setModalMessage('')
                onConfirmModalFunction.function()
              }}
            ></CustomModal>
          ) : (
            ''
          )}
          {isLoading ? <Spinner /> : ''}
          {showOrbisSearchMapping
            ? getMappingComponent(
                orbisSearchMappingType,
                showOrbisSearchMapping,
                setMainOrbisMappingOptions,
                mainQuestionnaireOptions
              )
            : showQuestionnaireMapping
            ? getMappingComponent(
                questionnaireMappingType,
                setQuestionnaireMapping,
                setMainQuestionnaireOptions,
                mainOrbisMappingOptions
              )
            : showCatlystMapping
            ? getMappingComponent(
                catalystMappingType,
                setQuestionnaireMapping,
                setMainQuestionnaireOptions,
                mainOrbisMappingOptions
              )
            : showRiskMapping
            ? getMappingComponent(
                riskMappingType,
                setQuestionnaireMapping,
                setMainQuestionnaireOptions,
                mainOrbisMappingOptions
              )
            : showSupplyChainMapping
            ? getMappingComponent(
              supplyChainMappingType,
              setQuestionnaireMapping,
              setMainQuestionnaireOptions,
              mainOrbisMappingOptions
            )
            : getMappingVersions(props.type)}
        </Grid>
      </div>
    </div>
  )
}
