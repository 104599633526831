import XLSX from 'xlsx'
import * as deepcopy from 'deepcopy'
// import { getTenantMappings } from '../services/apiApps'

function convertData (data) {
  let data_json = []
  for (let index = 1; index < data.length; index++) {
    const element = data[index]
    let jsonObject = {}
    for (let i = 0; i < element.length; i++) {
      let headerLabel = data[0][i]
      if (headerLabel) {
        headerLabel = headerLabel
          .split('*')
          .join('')
          .trim()
        jsonObject[headerLabel] = element[i]
      }
    }
    jsonObject['position'] = index
    if (jsonObject.Type === 'Question' && jsonObject['KPI Id']) {
      data_json.push({
        label: `${jsonObject.Number} ${jsonObject['Name (without html)']} (${jsonObject['KPI Id']})`,
        value: jsonObject['KPI Id'],
        type: jsonObject['Answer Type']
      })
    }
  }
  return data_json
}
function convertDataToJson (
  data,
  headers = undefined,
  requiredHeader = undefined,
  requiredMissingValue = undefined
) {
  let data_json = []
  for (let index = 1; index < data.length; index++) {
    const element = data[index]
    let jsonObject = {}
    for (let i = 0; i < element.length; i++) {
      let headerLabel = data[0][i]
      if (
        (headerLabel && headers && headers.includes(headerLabel)) ||
        headerLabel === 'active' ||
        !headers
      ) {
        headerLabel = headerLabel
          .split('*')
          .join('')
          .trim()
        jsonObject[headerLabel] = element[i] ? element[i] : ''
      }
    }
    if (
      (jsonObject.active &&
        typeof jsonObject.active === 'string' &&
        jsonObject.active.toLowerCase() === 'true') ||
      (jsonObject.active && typeof jsonObject.active === 'boolean')
    ) {
      if (requiredHeader && !jsonObject[requiredHeader])
        jsonObject[requiredHeader] = requiredMissingValue
      if (
        !requiredHeader ||
        (requiredHeader && Object.keys(jsonObject).includes(requiredHeader))
      )
        data_json.push(jsonObject)
      jsonObject['position'] = index
    }
  }
  return data_json
}
function getKIOptions (file, sheetName = 'Content', setOptions = () => {}) {
  const reader = new FileReader()
  const rABS = !!reader.readAsBinaryString
  let data = []
  reader.onload = e => {
    /* Parse data */
    const bstr = e.target.result
    const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' })

    let wsname = sheetName ? sheetName : wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    data = XLSX.utils.sheet_to_json(ws, { header: 1 })
    setOptions(convertData(data))
  }
  if (rABS) reader.readAsBinaryString(file)
  else reader.readAsArrayBuffer(file)
}

function getSuppliers (
  file,
  headers,
  requiredHeader = undefined,
  requiredMissingValue = undefined,
  setOptions = () => {}
) {
  const reader = new FileReader()
  const rABS = !!reader.readAsBinaryString
  let data = []
  reader.onload = e => {
    /* Parse data */
    const bstr = e.target.result
    const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' })

    let wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    data = XLSX.utils.sheet_to_json(ws, { header: 1 })
    setOptions(
      convertDataToJson(data, headers, requiredHeader, requiredMissingValue)
    )
  }
  if (rABS) reader.readAsBinaryString(file)
  else reader.readAsArrayBuffer(file)
}

function cloneObject (obj, deep = false) {
  return deepcopy(obj)
}

function showLoading (callback) {
  changeLoadingDiv(true)
  sessionStorage.setItem('isLoading', true)
  if (callback) {
    callback(true)
  }
}
function hideLoading (callback) {
  changeLoadingDiv(false)
  sessionStorage.removeItem('isLoading')
  if (callback) {
    callback(false)
  }
}

function changeLoadingDiv (show) {
  let loadingDiv = document.getElementById('loadingDiv')
  if (loadingDiv) {
    if (show) {
      loadingDiv.style.display = 'block'
    } else {
      loadingDiv.style.display = 'none'
    }
  }
}
function compareHierarchyNumbers (aNumber, bNumber, position = 0) {
  let a = 0
  let b = 0
  if (!isNaN(Number(aNumber[position]))) a = Number(aNumber[position])
  if (!isNaN(Number(bNumber[position]))) b = Number(bNumber[position])

  if (a < b || position > 1) {
    return -1
  } else if (a === b) {
    return compareHierarchyNumbers(aNumber, bNumber, position + 1, 1)
  }
  return 1
}

function getWidthString (width) {
  if (!width) width = getWidth()
  if (width > 1920) return 'xl'
  else if (width > 1280) return 'lg'
  else if (width > 960) return 'md'
  else if (width > 600) return 'sm'
  else if (width <= 600) return 'xs'
}
function getWidth () {
  return document.documentElement.clientWidth
}
function getSelectValue (fieldValue, options) {
  // debugger
  if(typeof fieldValue === 'object' && fieldValue.id) fieldValue = fieldValue.id
  let value = fieldValue
  let returnValue = ''
  if (value && options && Array.isArray(options)) {
    if (typeof value !== 'object' && !Array.isArray(value)) {
      value = [value]
    }
    if (Array.isArray(value)) {
      returnValue = []
      value.forEach(element => {
        let filteredOption = options.filter(option => {
          if (typeof option === 'object') {
            if(typeof option.value === 'object') {
              return option.value.id === element 
            } else {
              return option.value === element
            }
            
          } else return option === element
        })
        if (filteredOption && filteredOption.length > 0) {
          let option = filteredOption[0]
          if (typeof option !== 'object')
            option = { label: option, value: option }
          returnValue.push(option)
        }
      })
    }
  }
  return returnValue
}

function countHighRiskAssessmentBySupplier(list) {

  let counter = 0
  if(list.list && list.list.length > 0) {
    list.list.forEach(item => {
      counter = counter + item.alertCount
    })
  }
  
  return counter
}

export {
  getKIOptions,
  cloneObject,
  showLoading,
  hideLoading,
  getWidthString,
  getWidth,
  compareHierarchyNumbers,
  getSelectValue,
  getSuppliers,
  countHighRiskAssessmentBySupplier
}
