import React, { useEffect, useRef, forwardRef } from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import IconButton from '@material-ui/core/IconButton'
import styles from '../../assets/jss/layout/Admin'
import AddIcon from '../../assets/img/icons/chainColor.png'
import CustomModal from '../../components/CustomModal/CustomModal.js'

//Icons
import userIcon from '../../assets/img/icons/userIcon.png'
import { Grid } from '@material-ui/core'
import SuppliersCriteria from '../SuppliersCriteria/SuppliersCriteria'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'

import { primaryColorHover } from '../../assets/jss/Colors'
import { Spinner } from '../../components/Loader/Spinner'
import { env } from '../../utils/Constants'

import CustomTreeTable from '../../components/CustomTreeTable/CustomTreeTable'

// Api Calls
import apiApps from '../../services/apiApps'
import apiAppsProd from '../../services/apiAppsProd'

const setSupplierBvdId =
  env === 'local' ? apiApps.setSupplierBvdId : apiAppsProd.setSupplierBvdId

const useStyles = makeStyles(styles)

export default function AssignBvDId (props) {
  const [isLoading, setIsLoading] = React.useState(true)
  const [showModal, setShowModal] = React.useState(false)
  const [modalMaxWidth, setModalMaxWidth] = React.useState('xl')

  const [modalMessage, setModalMessage] = React.useState('')
  const [onConfirmModalFunction, setOnConfirmModalFunction] = React.useState({
    function: () => {}
  })

  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      setIsLoading(false)
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  }, [])

  const classes = useStyles()

  
  function setBvDModal (rowData) {
    setModalMessage(
      <div>
        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
          {'Do you want to add BVD ' + rowData.BvDId + '?'}
        </span>
        <Grid item xs={12} style={{ paddingTop: '25px' }}>
          <Card style={{ background: primaryColorHover }}>
            <Grid container>
              <Grid item xs={6}>
                <div>
                  <img
                    alt="user"
                    src={userIcon}
                    style={{ width: '50px', float: 'left' }}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Grid container style={{ color: 'white' }}>
                  <Grid item xs={12}>
                    {'Supplier Id: ' + props.supplier.supplierId}
                  </Grid>
                  <Grid item xs={12}>
                    {'Supplier Name: ' + props.supplier.supplierName}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <div style={{ paddingTop: '25px' }}>
          <CustomTreeTable
            hideFooter={true}
            hideSearch={true}
            defaultOrderBy={'Score'}
            defaultOrder={'desc'}
            mainNode={['BvDId', 'Score', 'Name']}
            columns={[
              { title: 'BvD ID', field: 'BvDId' },
              { title: 'Score', field: 'Score' },
              { title: 'Legal Status', field: 'Status' },
              { title: 'Name', field: 'Name' },
              {
                title: 'Address Type',
                field: 'Address_Type',
                width: '100%'
              },
              { title: 'Address', field: 'Address' },
              { title: 'City', field: 'City' },
              { title: 'Country', field: 'Country' },
              {
                title: 'National ID label',
                field: 'NationalIdLabel'
              },
              { title: 'National ID', field: 'National_Id' }
            ]}
            icons={{
              Filter: forwardRef((props, ref) => (
                <FilterList {...props} ref={ref} />
              )),
              FirstPage: forwardRef((props, ref) => (
                <FirstPage {...props} ref={ref} />
              )),
              LastPage: forwardRef((props, ref) => (
                <LastPage {...props} ref={ref} />
              )),
              NextPage: forwardRef((props, ref) => (
                <ChevronRight {...props} ref={ref} />
              )),
              PreviousPage: forwardRef((props, ref) => (
                <ChevronLeft {...props} ref={ref} />
              )),
              ResetSearch: forwardRef((props, ref) => (
                <Clear {...props} ref={ref} />
              )),
              Search: forwardRef((props, ref) => (
                <Search {...props} ref={ref} />
              )),
              SortArrow: forwardRef((props, ref) => (
                <ArrowDownward {...props} ref={ref} />
              )),
              ThirdStateCheck: forwardRef((props, ref) => (
                <Remove {...props} ref={ref} />
              )),
              ViewColumn: forwardRef((props, ref) => (
                <ViewColumn {...props} ref={ref} />
              ))
            }}
            data={[rowData]}
            options={{
              paging: false,
              actionsColumnIndex: -1,
              sorting: true,
              showTitle: false
            }}
            treeTableWidth={1410}
          />
        </div>
      </div>
    )
    setOnConfirmModalFunction({
      function: async () => {
        let setSupplierResult = await setSupplierBvdId(
          props.supplier.supplierId,
          rowData.BvDId
        )

        props.onSuccess(
          setSupplierResult.data,
          rowData.BvDId + ' Added Successfully'
        )
        setIsLoading(false)
      }
    })
  }
  return (
    <Grid container>
      <IconButton
        onClick={() => {
          props.backButtonOnClick()
        }}
      >
        <KeyboardBackspaceIcon />
      </IconButton>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            {isLoading ? <Spinner /> : ''}
            {showModal ? (
              <CustomModal
                dialogProps={{
                  maxWidth: modalMaxWidth,
                  fullWidth: true
                }}
                message={modalMessage}
                //title={'Alert'}
                confirmBtnCssClass={classes.defaultButton}
                cancelBtnCssClass={classes.buttonDanger}
                onCancel={() => {
                  setShowModal(false)
                  setIsLoading(false)
                  setModalMessage('')
                  setOnConfirmModalFunction({ function: () => {} })
                }}
                onConfirm={() => {
                  setShowModal(false)

                  setModalMessage('')
                  onConfirmModalFunction.function()
                }}
              ></CustomModal>
            ) : (
              ''
            )}
            <SuppliersCriteria
              title={
                <Grid container>
                  <Grid item xs={12}>
                    <Card style={{ background: primaryColorHover }}>
                      <Grid container>
                        <Grid item xs={6}>
                          <div style={{ paddingTop: '5px' }}>
                            <img
                              src={userIcon}
                              style={{ width: '50px' }}
                              alt=""
                            />
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container style={{ color: 'white' }}>
                            <Grid item xs={12}>
                              {'Supplier Id: ' + props.supplier.supplierId}
                            </Grid>
                            <Grid item xs={12}>
                              {'Supplier Name: ' + props.supplier.supplierName}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              }
              cardTitle={'Search Supplier'}
              supplier={props.supplier}
              addOwnEntity={true}
              history={props.history}
              actions={[
                rowData => ({
                  hideDialog: true,
                  icon: () => (
                    <img style={{ width: '30px' }} src={AddIcon} alt="" />
                  ),
                  tooltip: 'Add BvdId',
                  onClick: (event, rowData) => {
                    setIsLoading(true)
                    setShowModal(true)
                    if (rowData.SupplierId) {
                      setModalMaxWidth('sm')
                      setModalMessage(
                        <div>
                          <span
                            style={{ fontSize: '20px', fontWeight: 'bold' }}
                          >
                            {
                              <div>
                                <div>
                                  This BvD Id is already assigned to a Supplier{' '}
                                </div>
                                <div>Are you Sure you want to continue?</div>
                              </div>
                            }
                          </span>
                        </div>
                      )
                      setOnConfirmModalFunction({
                        function: () => {
                          setIsLoading(true)
                          setShowModal(false)
                          setTimeout(() => {
                            setModalMaxWidth('xl')
                            setShowModal(true)
                            setBvDModal(rowData)
                          }, 500)
                        }
                      })
                    } else {
                      setModalMaxWidth('xl')
                      setBvDModal(rowData)
                    }
                  }
                })
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
